import THEME from "@/constants/theme";
import { ICCheckDot, ICCloseCircle, ICLoadingRing } from "@/assets/icons";

export const STATUS_ICON_MAP = {
  loading: () => (
    <ICLoadingRing className="shrink-0 w-5 h-5 text-iris-100 animate-spin" />
  ),
  success: () => <ICCheckDot className="shrink-0 w-5 h-5 text-green-80" />,
  error: () => <ICCloseCircle className="shrink-0 w-5 h-5 text-pink-100" />,
};

export const STATUS_COLOR_MAP = {
  loading: THEME.colors?.iris?.[100],
  success: THEME.colors?.green?.[80],
  error: "#F24DB0",
  info: THEME.colors?.white?.[100],
};

export const TOAST_TIMEOUT = 5000;
