import { ICFrame, ICWallet } from "@/assets/icons";
import {
  agAmountInAtom,
  agDebugSimulate,
  agSlippageAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import TextAmt from "@/components/TextAmt";
import { Checkbox } from "@/components/UI/Checkbox";
import { BIG_ZERO, MINIMUM_SUI_AMT } from "@/constants/amount";
import useAccountBalanceList from "@/hooks/accounts/useAccountBalanceList";
import useAgSor from "@/hooks/aggregator/useAgSor";
import useAggregateMutation from "@/mutations/aggregator/useAggregateMutation";
import { estimateGasFee } from "@/utils/aggregator";
import { formatBalance, formatRawBalance } from "@/utils/number";
import { checkIsSui } from "@/utils/token";
import tw from "@/utils/twmerge";
import { formatAmount } from "@bicarus/utils";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import BigNumber from "bignumber.js";
import { getDefaultStore, useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import OrderInfo from "./OrderInfo";
import RefreshButton from "./RefreshButton";
import SlippageDropdown from "../../common/SlippageDropdown";
import NumericalInput from "@/components/Input/NumericalInput";
import { accountBalanceMapAtom } from "@/atoms/account.atom";
import ReactGa from "@/utils/ga";
import useTokenPrices from "@/hooks/prices/useTokenPrices";
import useTradingHistory from "@/hooks/accounts/useTradingHistory";
import { useSearchParams } from "react-router-dom";
import TradeTabs from "../../common/TradeTabs";
import SelectTokenModal from "../../common/SelectTokenModal";

function SwapForm() {
  const setSearchParams = useSearchParams()[1];

  const isSimulate = useAtomValue(agDebugSimulate);

  const [tokenIn, setTokenIn] = useAtom(agTokenInAtom);
  const [tokenOut, setTokenOut] = useAtom(agTokenOutAtom);
  const [amountIn, setAmountIn] = useAtom(agAmountInAtom);

  const tokenInId = useMemo(() => tokenIn?.type, [tokenIn]);
  const tokenOutId = useMemo(() => tokenOut?.type, [tokenOut]);

  const [networkFee, setNetworkFee] = useState<BigNumber>(BIG_ZERO);

  const [agQueryParams] = useDebounce(
    useMemo(() => {
      const enabled =
        !!tokenInId &&
        !!tokenOutId &&
        tokenInId !== tokenOutId &&
        !!amountIn &&
        new BigNumber(amountIn).gt(0);
      return {
        tokenInId: tokenInId,
        tokenOutId: tokenOutId,
        amountIn: amountIn
          ? formatRawBalance(amountIn, tokenIn?.decimals).toString()
          : "",
        enabled,
        refetchInterval: 6_000,
      };
    }, [tokenInId, tokenOutId, amountIn, tokenIn?.decimals]),
    200,
  );
  const enabledAgSor = useMemo(
    () => agQueryParams.enabled,
    [agQueryParams.enabled],
  );

  const {
    data: agSorData,
    refetch: refetchAgSor,
    isFetching: isRefreshingAgSor,
  } = useAgSor(agQueryParams);

  const accountBalanceMap = useAtomValue(accountBalanceMapAtom);
  const { refetch: refetchAccountBalances } = useAccountBalanceList();

  const { refetch: refetchTradingHistory } = useTradingHistory();

  const tokenInBalance = useMemo(() => {
    if (accountBalanceMap?.[tokenInId]) {
      return new BigNumber(
        formatBalance(accountBalanceMap[tokenInId], tokenIn.decimals),
      );
    }
    return BIG_ZERO;
  }, [accountBalanceMap, tokenInId, tokenIn.decimals]);

  const tokenOutBalance = useMemo(() => {
    if (accountBalanceMap?.[tokenOutId]) {
      return new BigNumber(
        formatBalance(accountBalanceMap[tokenOutId], tokenOut.decimals),
      );
    }
    return BIG_ZERO;
  }, [accountBalanceMap, tokenOutId, tokenOut.decimals]);

  const amountOut = useMemo(() => {
    return agSorData?.returnAmount || "0";
  }, [agSorData]);

  const { data: prices, isLoading: isLoadingPrices } = useTokenPrices([
    tokenInId,
    tokenOutId,
  ]);
  const tokenInPrice = useMemo(() => {
    return prices?.[tokenInId] ?? 0;
  }, [prices, tokenInId]);
  const tokenOutPrice = useMemo(() => {
    return prices?.[tokenOutId] ?? 0;
  }, [prices, tokenOutId]);

  const amountInUsdValue = useMemo(() => {
    if (!amountIn) {
      return BIG_ZERO;
    }
    return new BigNumber(amountIn).multipliedBy(tokenInPrice);
  }, [amountIn, tokenInPrice]);
  const amountOutUsdValue = useMemo(() => {
    if (!agSorData?.returnAmount) {
      return BIG_ZERO;
    }
    return new BigNumber(agSorData.returnAmount).multipliedBy(tokenOutPrice);
  }, [agSorData, tokenOutPrice]);

  const handleClickBalance = useCallback(() => {
    if (checkIsSui(tokenInId)) {
      setAmountIn(tokenInBalance.minus(MINIMUM_SUI_AMT).toString());
      return;
    }
    setAmountIn(tokenInBalance.toString());
  }, [tokenInBalance, setAmountIn, tokenInId]);

  const handleRevertTokens = useCallback(() => {
    const store = getDefaultStore();
    const tokenIn = store.get(agTokenOutAtom);
    const tokenOut = store.get(agTokenInAtom);
    store.set(agAmountInAtom, agSorData?.returnAmount || "");
    store.set(agTokenInAtom, tokenIn);
    store.set(agTokenOutAtom, tokenOut);
    setNetworkFee(BIG_ZERO);
    setSearchParams((prev) => {
      prev.set("from", tokenIn.type);
      prev.set("to", tokenOut.type);
      return prev;
    });
  }, [agSorData?.returnAmount, setSearchParams]);

  const currentAccount = useCurrentAccount();
  const isInsufficientBalance = useMemo(() => {
    if (!amountIn || isSimulate) {
      return false;
    }
    return tokenInBalance.isLessThan(amountIn);
  }, [amountIn, tokenInBalance, isSimulate]);
  const isPriceImpactTooHigh = useMemo(() => {
    return (agSorData?.priceImpact || 0) * 100 > 30;
  }, [agSorData]);

  const [isConfirmSwapAnyway, setIsConfirmSwapAnyway] = useState(false);
  useEffect(() => {
    if (isPriceImpactTooHigh) setIsConfirmSwapAnyway(false);
  }, [isPriceImpactTooHigh]);

  const { mutate: aggregate, isPending } = useAggregateMutation();

  const canSwap = useMemo(() => {
    const validTokens =
      tokenIn.type && tokenOut.type && tokenIn.type !== tokenOut.type;
    const validAmounts =
      new BigNumber(amountIn).gt(0) && new BigNumber(amountOut).gt(0);
    const validSwaps = Number(agSorData?.swaps?.length) > 0;
    return (
      !!validTokens &&
      validAmounts &&
      validSwaps &&
      !!currentAccount &&
      !isInsufficientBalance &&
      !isPending &&
      !isRefreshingAgSor
    );
  }, [
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    agSorData,
    currentAccount,
    isInsufficientBalance,
    isPending,
    isRefreshingAgSor,
  ]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSwap = useCallback(() => {
    if (!canSwap || !agSorData) return;

    inputRef.current?.blur();

    const amount_in = formatAmount(amountIn);
    const token_in = tokenIn.symbol;
    const amount_out = formatAmount(amountOut);
    const token_out = tokenOut.symbol;
    const slippage = getDefaultStore().get(agSlippageAtom);
    const minimum_received_raw = new BigNumber(1)
      .minus(slippage.toBigNumber())
      .multipliedBy(amountOut);
    const minimum_received = formatAmount(minimum_received_raw);

    ReactGa.event("swap", {
      amount_in,
      token_in,
      amount_out,
      token_out,
    });

    aggregate(
      {
        sorResponse: agSorData,
        txTitle: `Swap ${amount_in} ${token_in} to minimum ${minimum_received} ${token_out}`,
        extra: { tokenIn, tokenOut },
      },
      {
        onSuccess: () => {
          setAmountIn("");
          setNetworkFee(BIG_ZERO);
          setTimeout(() => {
            refetchAccountBalances();
          }, 1_000);
          setTimeout(() => {
            refetchTradingHistory();
          }, 6_000);
        },
      },
    );
  }, [
    canSwap,
    agSorData,
    aggregate,
    setAmountIn,
    refetchAccountBalances,
    refetchTradingHistory,
    amountIn,
    tokenIn,
    amountOut,
    tokenOut,
  ]);

  // network fee
  useEffect(() => {
    const estimate = async () => {
      if (!canSwap || !agSorData) return;
      try {
        const fee = await estimateGasFee(
          agSorData,
          currentAccount?.address ?? "",
        );
        setNetworkFee(fee);
      } catch (error) {
        setNetworkFee(BIG_ZERO);
      }
    };

    estimate();
  }, [canSwap, agSorData, currentAccount?.address]);

  const actionButton = useMemo(() => {
    if (!currentAccount) {
      return (
        <ConnectModal
          trigger={
            <button
              className="flex items-center justify-center gap-2 p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white hover:bg-[#404862] active:bg-[#31384F]"
              onClick={() => {
                ReactGa.event("connect_wallet");
              }}
            >
              <ICWallet className="w-4 aspect-square" />
              <span className="text-lg/none">Connect Wallet</span>
            </button>
          }
        />
      );
    }

    if (!amountIn || !tokenIn) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">Enter an amount</span>
        </button>
      );
    }

    if (isInsufficientBalance) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">
            Insufficient {tokenIn.symbol} balance
          </span>
        </button>
      );
    }

    if (isPriceImpactTooHigh) {
      const confirmElement = (
        <div className="flex items-center gap-2.5">
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={isConfirmSwapAnyway}
              onCheckedChange={(checked) =>
                setIsConfirmSwapAnyway(checked as boolean)
              }
              className="border-white text-white"
              boxClassName="border-white group-hover:border-white group-data-[state=checked]:bg-transparent group-data-[state=checked]:border-white"
            />
          </div>
          <span className="text-lg/none">Yes, I still want to swap</span>
        </div>
      );

      if (!isConfirmSwapAnyway) {
        return (
          <div className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white">
            {confirmElement}
          </div>
        );
      }

      return (
        <a
          className={tw(
            "transition-all flex items-center justify-center p-4 rounded-2xl bg-pink-100 h-[4.25rem] text-white cursor-pointer hover:bg-[#FD65C0] hover:shadow-soft-3 hover:shadow-pink-100",
            !canSwap && "cursor-not-allowed opacity-60",
          )}
          type="button"
          onClick={handleSwap}
        >
          {confirmElement}
        </a>
      );
    }

    return (
      <button
        className="transition-all duration-200 relative overflow-hidden flex items-center justify-center p-4 rounded-2xl bg-iris-100 text-white font-cyberwayRiders text-[2rem]/none shadow-soft-3 shadow-[rgba(102,103,238,0.50)] hover:bg-[#6667EE] hover:shadow-[#6667EE] active:bg-[#3E40E3] active:shadow-none disabled:cursor-not-allowed disabled:opacity-60"
        onClick={handleSwap}
        disabled={!canSwap}
      >
        <span className="z-10">Swap</span>
      </button>
    );
  }, [
    currentAccount,
    amountIn,
    tokenIn,
    isInsufficientBalance,
    isPriceImpactTooHigh,
    isConfirmSwapAnyway,
    canSwap,
    handleSwap,
  ]);

  const isInvalidAmountOut = useMemo(() => {
    return new BigNumber(amountOut).lte(0);
  }, [amountOut]);

  return (
    <div className="flex flex-col gap-2 p-2 rounded-3xl bg-black-60">
      <div className="flex items-center justify-between gap-2">
        <TradeTabs />
        <div className="flex items-center gap-2">
          <RefreshButton
            onClick={() => refetchAgSor()}
            disabled={!enabledAgSor || isRefreshingAgSor}
          />
          <SlippageDropdown />
        </div>
      </div>

      <div className="flex flex-col gap-0.5">
        <div className="flex flex-col gap-6 p-1 rounded-2xl bg-black-80">
          <div className="flex items-center gap-1">
            <NumericalInput
              className="flex-1 p-2 outline-none bg-transparent text-lg sm:text-2xl overflow-hidden grow"
              placeholder="0"
              value={amountIn}
              onUserInput={setAmountIn}
              precision={tokenIn?.decimals}
              onBlur={() => {
                setSearchParams((prev) => {
                  prev.set("amountIn", Number(amountIn) > 0 ? amountIn : "0");
                  return prev;
                });
              }}
              ref={inputRef}
            />
            <SelectTokenModal
              token={tokenIn}
              setToken={(token) => {
                if (token.type === tokenIn.type) return;
                setAmountIn("");
                setTokenIn(token);
                setNetworkFee(BIG_ZERO);
              }}
              pivotTokenId={tokenOutId}
              accountBalanceMap={accountBalanceMap}
              type="from"
            />
          </div>
          <div className="flex items-center justify-between gap-2.5 p-2 rounded-xl">
            <TextAmt
              number={amountInUsdValue}
              className={tw(
                "text-gray-100 text-xs font-light",
                (!amountIn || isLoadingPrices) && "invisible",
              )}
              prefix="~ $"
            />
            {tokenInBalance.isGreaterThan(0) ? (
              <button className="font-normal" onClick={handleClickBalance}>
                <span className="text-gray-100">Balance: </span>
                <TextAmt number={tokenInBalance} className="text-green-80" />
              </button>
            ) : (
              <span className="font-normal">
                <span className="text-gray-100">Balance: </span>
                <span className="text-gray-100">0</span>
              </span>
            )}
          </div>
        </div>

        <button
          className="flex items-center justify-center m-auto p-1.5 bg-[#252734] text-gray-100 rounded-[0.625rem] border-2 border-black-80 my-[-1.0625rem] z-[2]"
          disabled={isRefreshingAgSor}
          onClick={handleRevertTokens}
        >
          <ICFrame className="w-4 aspect-square" />
        </button>

        <div
          className={tw(
            "flex flex-col gap-6 p-1 rounded-2xl border",
            !isInvalidAmountOut ? "border-black-80" : "border-transparent",
          )}
        >
          <div className="flex items-center gap-1">
            <NumericalInput
              className={`transition-all duration-200 flex-1 p-2 outline-none bg-transparent text-lg sm:text-2xl overflow-hidden grow disabled:text-gray-100 ${isRefreshingAgSor && "opacity-50"}`}
              placeholder="0"
              disabled
              value={amountOut}
              precision={tokenOut?.decimals}
            />
            <SelectTokenModal
              token={tokenOut}
              setToken={setTokenOut}
              pivotTokenId={tokenInId}
              accountBalanceMap={accountBalanceMap}
              type="to"
            />
          </div>
          <div className="flex items-center justify-between gap-2.5 p-2 rounded-xl">
            <TextAmt
              number={amountOutUsdValue}
              className={tw(
                "text-gray-100 text-xs font-light",
                (isLoadingPrices || isInvalidAmountOut) && "invisible",
              )}
              prefix="~ $"
            />
            <span className="font-normal">
              <span className="text-gray-100">Balance: </span>
              <TextAmt number={tokenOutBalance} className="text-gray-100" />
            </span>
          </div>
        </div>
      </div>

      {actionButton}

      <OrderInfo
        tokenIn={tokenIn}
        tokenOut={tokenOut}
        agSorData={agSorData}
        networkFee={networkFee}
      />
    </div>
  );
}

export default SwapForm;
