import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import tw from "@/utils/twmerge";

const betaEnabled = true;

export const TRADE_TABS = [
  {
    key: "swap",
    label: "Swap",
    isBeta: false,
  },
  {
    key: "limit",
    label: "Limit",
    isBeta: betaEnabled,
  },
  {
    key: "dca",
    label: "DCA",
    isBeta: betaEnabled,
  },
] as const;

function TradeTabs() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <div className="flex items-center gap-1 p-1 bg-black-80 rounded-xl h-[2.125rem]">
      {TRADE_TABS.map(({ key, label, isBeta }) => {
        const isActive = pathname.includes(key);
        return (
          <Fragment key={key}>
            <Link
              to={`/trade/${key}?${searchParams.toString()}`}
              className={tw(
                "flex items-center justify-center gap-1 transition-all rounded-lg text-xs/none hover:text-white h-full",
                betaEnabled ? "px-2" : "px-3",
                isActive
                  ? "bg-[#6265FF] text-white"
                  : "bg-transparent text-gray-100",
              )}
            >
              {label}
              {isBeta && (
                <span
                  className={tw(
                    "flex px-1.5 py-1 rounded-full text-3xs/none text-white",
                    isActive ? "bg-black-60" : "bg-[#6265FF]",
                  )}
                >
                  Beta
                </span>
              )}
            </Link>
            {key === "swap" && (
              <div
                className={tw(
                  "h-3 w-px bg-darkblue-100",
                  pathname.includes("dca") ? "visible" : "invisible",
                )}
              />
            )}
            {key === "limit" && (
              <div
                className={tw(
                  "h-3 w-px bg-darkblue-100",
                  pathname.includes("swap") ? "visible" : "invisible",
                )}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export default TradeTabs;
