import { atom } from "jotai";
import {
  TS_1_DAY,
  TS_1_HOUR,
  TS_1_MINUTE,
  TS_30_DAYS,
  TS_7_DAYS,
} from "@/constants/time";
import { agAmountInAtom } from "./aggregator.atom";
import BigNumber from "bignumber.js";
import { DcaOrder } from "@/types/limitDca";

export const dcaIntervalValueAtom = atom("1");

export type DcaIntervalUnit = "minute" | "hour" | "day" | "week" | "month";

export const DCA_INTERVAL_UNIT_MAP: Record<DcaIntervalUnit, number> = {
  minute: TS_1_MINUTE,
  hour: TS_1_HOUR,
  day: TS_1_DAY,
  week: TS_7_DAYS,
  month: TS_30_DAYS,
};

export const dcaIntervalUnitAtom = atom<DcaIntervalUnit>("day");

export const dcaIntervalTsAtom = atom((get) => {
  const intervalValue = get(dcaIntervalValueAtom) || "0";
  const intervalUnit = get(dcaIntervalUnitAtom);
  return Math.floor(
    Number(intervalValue) * DCA_INTERVAL_UNIT_MAP[intervalUnit],
  );
});

export const dcaOrdersNumAtom = atom("1");

export const dcaMinPriceAtom = atom("");

export const dcaMaxPriceAtom = atom("");

export const dcaPayPerOrderAtom = atom((get) => {
  const amountIn = get(agAmountInAtom);
  const ordersNum = get(dcaOrdersNumAtom);
  return new BigNumber(amountIn).dividedBy(ordersNum);
});

export const dcaOpenOrdersAtom = atom<DcaOrder[]>([]);

export const dcaHistoryTabAtom = atom<"dca" | "history">("dca");

export const dcaIsRefetchingOpenOrdersAtom = atom(false);
