import { DEFAULT_SWAP_TOKENS } from "@/constants/tokens";
import { SorSwapResponse } from "@/types/swapInfo";
import { Percent } from "@bicarus/utils";
import { atom } from "jotai";

export const agTokenInAtom = atom(DEFAULT_SWAP_TOKENS.IN);

export const agTokenOutAtom = atom(DEFAULT_SWAP_TOKENS.OUT);

export const agAmountInAtom = atom("1");

export const agSlippageAtom = atom(new Percent(1_000, 100_000)); // 1%

export type InfoTab = "price-chart" | "routes";
export const agInfoTabAtom = atom<InfoTab>("routes");

export const agDebugSimulate = atom(false);

export const priceChartLoadedAtom = atom(false);

export const agTokenPairAtom = atom((get) => {
  const tokenIn = get(agTokenInAtom);
  const tokenOut = get(agTokenOutAtom);
  return `${tokenIn.type}-${tokenOut.type}`;
});

export const agSorDataAtom = atom<SorSwapResponse | undefined>(undefined);

export const agIsRefetchingHistoryAtom = atom(false);
