import { fullTokenMapAtom } from "@/atoms/token.atom";
import { LoDcaOrderExecution } from "@/types/limitDca";
import { TokenDetails } from "@/types/token";
import { formatBalance } from "@/utils/number";
import BigNumber from "bignumber.js";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

export interface DcaExecutionItem {
  id: number;
  orderId: string;
  tokenIn: TokenDetails;
  tokenOut: TokenDetails;
  amountIn: BigNumber;
  amountOut: BigNumber;
  price: BigNumber;
  priceInverted: BigNumber;
  executedTs: number;
  digest: string;
}

const useDcaExecutionItems = (orders: LoDcaOrderExecution[]) => {
  const fullTokenMap = useAtomValue(fullTokenMapAtom);

  const items = useMemo(() => {
    return orders.map((order) => {
      const tokenIn = fullTokenMap[order.payCoinName];
      const tokenOut = fullTokenMap[order.targetCoinName];
      const amountIn = formatBalance(order.payAmount, tokenIn?.decimals ?? 0);
      const amountOut = formatBalance(
        order.obtainedAmount,
        tokenOut?.decimals ?? 0,
      );
      const price = amountIn.isZero()
        ? new BigNumber(0)
        : new BigNumber(amountOut).dividedBy(amountIn);
      const priceInverted = price.isZero()
        ? new BigNumber(0)
        : new BigNumber(1).dividedBy(price);

      return {
        id: order.id,
        orderId: order.orderId,
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        price,
        priceInverted,
        executedTs: order.executedTs,
        digest: order.digest ?? "",
      };
    });
  }, [orders, fullTokenMap]);

  const totalPayAmount = useMemo(() => {
    return orders.reduce((acc, order) => {
      return acc.plus(order.payAmount);
    }, new BigNumber(0));
  }, [orders]);

  const totalObtainedAmount = useMemo(() => {
    return orders.reduce((acc, order) => {
      return acc.plus(order.obtainedAmount);
    }, new BigNumber(0));
  }, [orders]);

  const { avgPrice, avgPriceInverted } = useMemo(() => {
    if (orders.length === 0) {
      return {
        avgPrice: new BigNumber(0),
        avgPriceInverted: new BigNumber(0),
      };
    }
    const tokenIn = fullTokenMap[orders[0].payCoinName];
    const tokenOut = fullTokenMap[orders[0].targetCoinName];
    const totalAmountIn = formatBalance(totalPayAmount, tokenIn?.decimals ?? 0);
    const totalAmountOut = formatBalance(
      totalObtainedAmount,
      tokenOut?.decimals ?? 0,
    );
    const price = totalAmountIn.isZero()
      ? new BigNumber(0)
      : totalAmountOut.dividedBy(totalAmountIn);
    const priceInverted = price.isZero()
      ? new BigNumber(0)
      : new BigNumber(1).dividedBy(price);

    return {
      avgPrice: price,
      avgPriceInverted: priceInverted,
    };
  }, [totalObtainedAmount, totalPayAmount, fullTokenMap, orders]);

  const totalSpent = useMemo(() => {
    if (orders.length === 0) {
      return new BigNumber(0);
    }
    return formatBalance(
      totalPayAmount,
      fullTokenMap[orders[0].payCoinName]?.decimals ?? 0,
    );
  }, [totalPayAmount, fullTokenMap, orders]);

  return {
    items,
    avgPrice,
    avgPriceInverted,
    totalSpent,
  };
};

export default useDcaExecutionItems;
