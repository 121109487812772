import { useAtomValue } from "jotai";
import { isMobileAtom } from "./atoms/layout.atom";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import GlobalLayout from "./pages/common/GlobalLayout";
import { lazy, Suspense } from "react";
import TradeLayout from "./pages/trade/common/TradeLayout";

import SwapPage from "./pages/trade/swap";
import LimitOrderPage from "./pages/trade/limitOrder";
import DcaPage from "./pages/trade/dca";

const LeaderBoardPage = lazy(() => import("./pages/leaderboard"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <GlobalLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/trade/swap" replace />,
      },
      {
        path: "trade",
        element: <TradeLayout />,
        children: [
          {
            path: "swap",
            index: true,
            element: (
              <Suspense fallback={null}>
                <SwapPage />
              </Suspense>
            ),
          },
          {
            path: "limit",
            element: (
              <Suspense fallback={null}>
                <LimitOrderPage />
              </Suspense>
            ),
          },
          {
            path: "dca",
            element: (
              <Suspense fallback={null}>
                <DcaPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "leaderboard",
        element: (
          <Suspense fallback={null}>
            <LeaderBoardPage />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  const isMobile = useAtomValue(isMobileAtom);
  return isMobile !== undefined ? <RouterProvider router={router} /> : null;
}

export default App;
