import tw from "@/utils/twmerge";

type ThProps = JSX.IntrinsicElements["th"];
function Th({ className, children }: ThProps) {
  return (
    <th
      className={tw(
        "w-full h-9 px-2 rounded-xl font-samsung font-medium text-2xs/none text-gray-100 bg-black-70",
        className,
      )}
    >
      {children}
    </th>
  );
}

function DcaExecutionHead() {
  return (
    <thead className="relative">
      <tr className="grid grid-cols-10 p-1 gap-1">
        <Th className="col-span-2 flex items-center justify-start">Time</Th>
        <Th className="col-span-2 flex items-center justify-end">Amount</Th>
        <Th className="col-span-2 flex items-center justify-end">Buy Price</Th>
        <Th className="col-span-2 flex items-center justify-end">Spent</Th>
        <Th className="col-span-2 flex items-center justify-end">Digest</Th>
      </tr>
    </thead>
  );
}

export default DcaExecutionHead;
