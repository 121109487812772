import { useMemo, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { AnimatePresence, m } from "framer-motion";
import { ICChevronDown } from "@/assets/icons";
import tw from "@/utils/twmerge";
import { useAtom } from "jotai";
import {
  DcaIntervalUnit,
  dcaIntervalUnitAtom,
  dcaIntervalValueAtom,
  dcaOrdersNumAtom,
} from "@/atoms/dca.atom";
import InputCurrency from "@/components/Input/InputCurrency";

const INTERVAL_UNIT_OPTIONS: Array<{ label: string; value: DcaIntervalUnit }> =
  [
    { value: "minute", label: "Minute" },
    { value: "hour", label: "Hour" },
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];

function SelectIntervalOrders() {
  const [intervalValue, setIntervalValue] = useAtom(dcaIntervalValueAtom);
  const [intervalUnit, setIntervalUnit] = useAtom(dcaIntervalUnitAtom);
  const [ordersNum, setOrdersNum] = useAtom(dcaOrdersNumAtom);

  const [openPopover, setOpenPopover] = useState(false);

  const intervalUnitLabel = useMemo(
    () =>
      INTERVAL_UNIT_OPTIONS.find((option) => option.value === intervalUnit)
        ?.label ?? intervalUnit,
    [intervalUnit],
  );

  return (
    <div className="grid grid-cols-2 gap-0.5">
      <div className="col-span-1">
        <div className="flex flex-col p-1 bg-black-80 border border-black-80 rounded-2xl h-full">
          <div className="flex items-center justify-between px-2 h-6 gap-2.5">
            <div className="flex items-center gap-0.5">
              <span className="text-2xs/none font-light text-gray-100">
                Every
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between gap-1">
            <InputCurrency
              className="outline-none bg-transparent text-sm/none overflow-hidden px-2 py-3 w-1/2"
              placeholder="0"
              value={intervalValue}
              onChange={(e) => {
                setIntervalValue(e.target.value);
              }}
              decimals={0}
            />
            <Popover open={openPopover} onOpenChange={setOpenPopover}>
              <PopoverTrigger asChild>
                <button className="flex-1 flex items-center justify-end gap-1 px-2 py-3 rounded-xl bg-transparent">
                  <span
                    className="text-sm/none truncate"
                    title={intervalUnitLabel}
                  >
                    {intervalUnitLabel}
                  </span>
                  <ICChevronDown className="w-4 aspect-square text-gray-100" />
                </button>
              </PopoverTrigger>
              <AnimatePresence>
                <PopoverPortal>
                  <PopoverContent
                    asChild
                    align="start"
                    sideOffset={10}
                    className="relative overflow-hidden min-w-[8rem] max-w-screen bg-black-80 rounded-lg shadow-sm shadow-skin-alt dark:shadow-skin-alt/10 z-[100]"
                  >
                    <m.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                    >
                      <span className="absolute inset-0 blur-2xl bg-black-80"></span>
                      <ul className="relative flex flex-col">
                        {INTERVAL_UNIT_OPTIONS.map((option) => {
                          const isSelected = option.value === intervalUnit;
                          return (
                            <li key={option.value}>
                              <button
                                className={tw(
                                  "flex items-center gap-2 px-3 py-1.5 w-full text-xs/normal text-left hover:bg-gray-20",
                                  isSelected && "bg-gray-20",
                                )}
                                onClick={() => {
                                  setIntervalUnit(option.value);
                                  setOpenPopover(false);
                                }}
                              >
                                {option.label}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </m.div>
                  </PopoverContent>
                </PopoverPortal>
              </AnimatePresence>
            </Popover>
          </div>
        </div>
      </div>

      <div className="col-span-1">
        <div className="flex flex-col p-1 bg-black-80 border border-black-80 rounded-2xl h-full">
          <div className="flex items-center justify-between px-2 h-6 gap-2.5">
            <div className="flex items-center gap-0.5">
              <span className="text-2xs/none font-light text-gray-100">
                Over
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between gap-1">
            <InputCurrency
              className="outline-none bg-transparent text-sm/none overflow-hidden px-2 py-3 w-1/2"
              placeholder="0"
              value={ordersNum}
              onChange={(e) => {
                setOrdersNum(e.target.value);
              }}
              decimals={0}
            />
            <span className="text-sm/none px-2">Orders</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectIntervalOrders;
