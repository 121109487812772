import { useScreenSize } from "@/hooks/dom/useScreenSize";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const screenSizeAtom = atom<ReturnType<typeof useScreenSize>>({
  msm: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  xl2: undefined,
  isMobile: undefined,
});
export const isMobileAtom = atom((get) => get(screenSizeAtom).isMobile);
export const appThemeAtom = atomWithStorage<"dark" | "light" | "system">(
  "app:theme",
  "dark",
);
export const isDarkModeAtom = atom(true);
export const openTxSheetAtom = atom(false);
export const showMNavBarAtom = atom(true);
