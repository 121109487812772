import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";
import tw from "@/utils/twmerge";
import LimitHistoryTabs from "./LimitHistoryTabs";
import LimitHistoryList from "./LimitHistoryList";
import HidePairsCheckbox from "../../common/HidePairsCheckbox";
import RefreshLimitHistoryButton from "./RefreshLimitHistoryButton";
import { loHistoryTabAtom } from "@/atoms/limitOrder.atom";
import SwapHistoryList from "../../common/SwapHistoryList";
import RefreshSwapHistoryButton from "../../swap/SwapHistory/RefreshSwapHistoryButton";

function LimitHistoryTabsContainer() {
  const isMobile = useAtomValue(isMobileAtom);
  const activeTab = useAtomValue(loHistoryTabAtom);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6 p-4 rounded-3xl border-[1.5px] border-[#222436] bg-black-80"
          : "gap-4 p-6 rounded-3xl border-[1.5px] border-[#222436] bg-black-80",
      )}
    >
      <div
        className={tw(
          isMobile
            ? "flex flex-col gap-4"
            : "flex items-center justify-between gap-2",
        )}
      >
        <LimitHistoryTabs />
        <div className="flex items-center justify-between sm:justify-start gap-2">
          <HidePairsCheckbox />
          {activeTab === "open-orders" ? (
            <RefreshLimitHistoryButton />
          ) : (
            <RefreshSwapHistoryButton />
          )}
        </div>
      </div>
      {activeTab === "open-orders" && (
        <div className="flex flex-col flex-1">
          <LimitHistoryList />
        </div>
      )}
      {activeTab === "history" && (
        <div className="flex flex-col flex-1">
          <SwapHistoryList />
        </div>
      )}
    </div>
  );
}

export default LimitHistoryTabsContainer;
