import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/UI/Sheet";
import { AnimatePresence } from "framer-motion";
import { memo } from "react";
import DcaHistoryContent from "./DcaHistoryContent";
import { DcaOrderItem } from "../hooks/useDcaOrderItems";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: DcaOrderItem;
  handleOpenCancelModal: (item: DcaOrderItem) => void;
}

function DcaHistorySheet({
  open,
  setOpen,
  data,
  handleOpenCancelModal,
}: Props) {
  const openCancelModal = (item: DcaOrderItem) => {
    handleOpenCancelModal(item);
    setOpen(false);
  };

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <AnimatePresence>
        {open && (
          <SheetContent forceMount side="bottom" onOpenChange={setOpen}>
            <div className="flex flex-col gap-6">
              <SheetHeader>
                <SheetTitle>DCA Details</SheetTitle>
                <SheetCloseEsc />
              </SheetHeader>
              <DcaHistoryContent
                data={data}
                handleOpenCancelModal={openCancelModal}
                size="large"
              />
            </div>
          </SheetContent>
        )}
      </AnimatePresence>
    </Sheet>
  );
}

export default memo(DcaHistorySheet);
