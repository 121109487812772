import { memeTokenListAtom } from "@/atoms/token.atom";
import { TokenDetails } from "@/types/token";
import { normalizeTokenType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

interface Config {
  refetchInterval?: number;
}

const useMemeTokenList = (config?: Config) => {
  const setMemeTokenList = useSetAtom(memeTokenListAtom);

  const { data, ...rest } = useQuery<TokenDetails[]>({
    queryKey: ["getMemeTokenList"],
    queryFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_TOKENS_API}/meme`);
      if (!response.ok) {
        throw new Error("Failed to fetch meme tokens");
      }
      return response.json();
    },
    refetchInterval: config?.refetchInterval,
  });

  useEffect(() => {
    if (Array.isArray(data)) {
      setMemeTokenList(
        data.map((token) => ({
          ...token,
          type: normalizeTokenType(token.type),
        })),
      );
    }
  }, [data, setMemeTokenList]);

  return {
    data,
    ...rest,
  };
};

export default useMemeTokenList;
