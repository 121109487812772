import {
  Dialog,
  DialogContent,
  DialogOverlay,
} from "../../../../../components/UI/Dialog";
import { useAtom } from "jotai";
import {
  dontShowUnverifiedTokenWarningAgainAtom,
  unverifiedTokenWarningOpenAtom,
} from "@/atoms/token.atom";
import { Checkbox } from "../../../../../components/UI/Checkbox";
import { useState } from "react";

function UnverifiedTokenWarning() {
  const [unverifiedTokenWarningOpen, setUnverifiedWarningOpen] = useAtom(
    unverifiedTokenWarningOpenAtom,
  );
  const [
    dontShowUnverifiedTokenWarningAgain,
    setDontShowUnverifiedTokenWarningAgain,
  ] = useAtom(dontShowUnverifiedTokenWarningAgainAtom);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  if (!unverifiedTokenWarningOpen || dontShowUnverifiedTokenWarningAgain) {
    return null;
  }

  return (
    <Dialog open modal>
      <DialogOverlay className="fixed z-toast" />
      <DialogContent
        className="fixed z-toast p-6 bg-skin-card shadow-soft-3 shadow-skin-alt dark:shadow-skin-alt/10 rounded-3xl"
        onPointerDownOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        style={{ width: "100%", maxWidth: "24rem" }}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="font-bold text-lg text-skin-base">
            Unverified Coin Warning
          </div>
        </div>
        <hr />
        <div className="mt-6 flex flex-col gap-6">
          <div className="text-sm text-gray-100">
            7K automatically lists coins from all DEXs, and we do not take
            responsibility for the legitimacy of these coins. Please ensure you
            understand the associated risks before proceeding with any swaps.
          </div>
          <label className="flex items-center gap-2 cursor-pointer">
            <Checkbox
              checked={dontShowAgain}
              onCheckedChange={(checked: boolean) => setDontShowAgain(checked)}
            />
            <span className="text-xs/none">Do not show again</span>
          </label>
          <button
            className="self-center min-w-[10rem] w-2/3 bg-[#383D4C] hover:bg-[#404862] px-2 h-12 text-white rounded-[10px] border-none outline-none"
            onClick={() => {
              setUnverifiedWarningOpen(false);
              setDontShowUnverifiedTokenWarningAgain(dontShowAgain);
            }}
          >
            <span className="text-gradient text-sm">I understand</span>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UnverifiedTokenWarning;
