import { DcaOrder } from "@/types/limitDca";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { LoDcaQueryConfig, LoDcaQueryParams } from "./types";
import { formatQueryParams } from "@/utils/url";

const useGetDcaOrders = (
  key: string,
  params: LoDcaQueryParams,
  config?: LoDcaQueryConfig,
) => {
  const paramsStr = useMemo(() => {
    return formatQueryParams(params);
  }, [params]);

  return useQuery<DcaOrder[]>({
    queryKey: [key, paramsStr],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_LO_DCA_API}/dca-orders?${paramsStr}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch dca orders");
      }
      return response.json();
    },
    refetchInterval: config?.refetchInterval,
    enabled: !!params.owner,
  });
};

export default useGetDcaOrders;
