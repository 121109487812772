import {
  agAmountInAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import { fullTokenMapAtom } from "@/atoms/token.atom";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useTradeQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [agTokenIn, setAgTokenIn] = useAtom(agTokenInAtom);
  const [agTokenOut, setAgTokenOut] = useAtom(agTokenOutAtom);
  const [agAmountIn, setAgAmountIn] = useAtom(agAmountInAtom);
  const fullTokenMap = useAtomValue(fullTokenMapAtom);

  useEffect(() => {
    if (!searchParams.has("from")) {
      setSearchParams((prev) => {
        prev.set("from", agTokenIn.type);
        return prev;
      });
    }
  }, [searchParams, setSearchParams, agTokenIn]);

  useEffect(() => {
    if (!searchParams.has("to")) {
      setSearchParams((prev) => {
        prev.set("to", agTokenOut.type);
        return prev;
      });
    }
  }, [searchParams, setSearchParams, agTokenOut]);

  useEffect(() => {
    const amountIn = searchParams.get("amountIn");
    if (!amountIn) {
      setSearchParams((prev) => {
        prev.set("amountIn", agAmountIn);
        return prev;
      });
    } else if (amountIn !== agAmountIn && Number(amountIn) >= 0) {
      setAgAmountIn(amountIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const from = searchParams.get("from");
    if (from && fullTokenMap[from] && from !== agTokenIn.type) {
      setAgTokenIn(fullTokenMap[from]);
    }
  }, [searchParams, fullTokenMap, agTokenIn, setAgTokenIn]);

  useEffect(() => {
    const to = searchParams.get("to");
    if (to && fullTokenMap[to] && to !== agTokenOut.type) {
      setAgTokenOut(fullTokenMap[to]);
    }
  }, [searchParams, fullTokenMap, agTokenOut, setAgTokenOut]);
};

export default useTradeQueryParams;
