import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useTradeType = () => {
  const { pathname } = useLocation();

  const isSwap = useMemo(() => pathname.includes("/trade/swap"), [pathname]);
  const isLimit = useMemo(() => pathname.includes("/trade/limit"), [pathname]);
  const isDca = useMemo(() => pathname.includes("/trade/dca"), [pathname]);

  return { isSwap, isLimit, isDca };
};

export default useTradeType;
