import { loExpiryAtom } from "@/atoms/limitOrder.atom";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { EXPIRY_OPTIONS } from "../constants";
import { formatExpiry } from "@/utils/limitOrder";

const useExpiryInfo = () => {
  const expiry = useAtomValue(loExpiryAtom);

  const selectedOption = useMemo(() => {
    const option = EXPIRY_OPTIONS.find((opt) => opt.value === expiry);
    return option ?? { label: "Custom", value: -1 };
  }, [expiry]);

  const selectedLabel = useMemo(() => {
    if (selectedOption.value === -1) {
      return formatExpiry(expiry);
    }
    return selectedOption.label;
  }, [selectedOption, expiry]);

  return {
    selectedOption,
    selectedLabel,
  };
};

export default useExpiryInfo;
