import { useMemo, useState } from "react";
import dayjs from "@/utils/dayjs";
import { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import { OpenOrderItem } from "./useOpenOrderItems";

interface Params {
  data: OpenOrderItem;
}

const useOpenOrderItem = ({ data }: Params) => {
  const [expiredH, expiredD] = useMemo(() => {
    const d = dayjs(data?.expireTs);
    return [d.format("HH:mm"), d.format("DD/MM/YYYY")];
  }, [data?.expireTs]);

  const [priceMode, setPriceMode] = useState<PriceMode>("out-in");
  const price = useMemo(() => {
    return priceMode === "in-out" ? data.priceInverted : data.price;
  }, [data.price, data.priceInverted, priceMode]);

  const [amountMode, setAmountMode] = useState<PriceMode>("out-in");
  const amount = useMemo(() => {
    return amountMode === "in-out" ? data.amountIn : data.amountOut;
  }, [data.amountIn, data.amountOut, amountMode]);

  return {
    ...data,
    expiredH,
    expiredD,
    price,
    priceMode,
    setPriceMode,
    amount,
    amountMode,
    setAmountMode,
  };
};

export default useOpenOrderItem;
