import { agInfoTabAtom, priceChartLoadedAtom } from "@/atoms/aggregator.atom";
import tw from "@/utils/twmerge";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { INFO_TABS } from "./constants";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";
import ReactGa from "@/utils/ga";
import useTradeType from "../../../hooks/useTradeType";

function DInfoTabs() {
  const [selectedTab, setSelectedTab] = useAtom(agInfoTabAtom);
  const setPriceChartLoaded = useSetAtom(priceChartLoadedAtom);

  const { isSwap } = useTradeType();

  const infoTabs = useMemo(() => {
    return isSwap
      ? INFO_TABS
      : INFO_TABS.filter((tab) => tab.key === "price-chart");
  }, [isSwap]);

  const selectedItem = useMemo(() => {
    return infoTabs.find((tab) => tab.key === selectedTab);
  }, [infoTabs, selectedTab]);

  useEffect(() => {
    if (isSwap) {
      setSelectedTab("routes");
    } else {
      setSelectedTab("price-chart");
      setPriceChartLoaded(true);
    }
  }, [isSwap, setSelectedTab, setPriceChartLoaded]);

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-1 p-1 rounded-xl bg-black-80">
        {infoTabs.map(({ key, label, tooltip }) => (
          <button
            key={key}
            className={tw(
              "flex items-center justify-center px-3 py-2 gap-1 transition-all rounded-lg min-h-[2.215rem]",
              key === selectedTab ? "bg-black-40" : "bg-transparent",
              "text-xs/none",
            )}
            onClick={() => {
              setSelectedTab(key);
              if (key === "price-chart") {
                setPriceChartLoaded(true);
                ReactGa.event("click_price_chart_tab");
              }
            }}
          >
            {label}
            {tooltip && (
              <div onClick={(e) => e.stopPropagation()}>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ICInfoCircle className="w-4 aspect-square" />
                    </TooltipTrigger>
                    <TooltipContentGlow className="w-[18rem]">
                      <span>{tooltip}</span>
                    </TooltipContentGlow>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </button>
        ))}
      </div>
      <div className="flex items-center gap-2">
        <div className="font-semibold text-2xl/none">{selectedItem?.label}</div>
      </div>
    </div>
  );
}

export default DInfoTabs;
