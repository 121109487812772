import tw from "@/utils/twmerge";
import { useAtom } from "jotai";
import { tokenListTabAtom } from "@/atoms/token.atom";

const TOKEN_LIST_TABS = [
  {
    key: "default",
    shortLabel: "Default",
    label: "Default",
  },
  {
    key: "meme",
    shortLabel: "Meme",
    label: "Meme",
  },
] as const;

function TokenListTabs() {
  const [selectedTab, setSelectedTab] = useAtom(tokenListTabAtom);

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-1 p-1 rounded-xl bg-black-80">
        {TOKEN_LIST_TABS.map(({ key, label }) => (
          <button
            key={key}
            className={tw(
              "flex items-center justify-center px-3 py-2 gap-1 transition-all rounded-lg min-h-[2.215rem]",
              key === selectedTab ? "bg-black-40" : "bg-transparent",
              "text-xs/none",
            )}
            onClick={() => {
              setSelectedTab(key);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default TokenListTabs;
