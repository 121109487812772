import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { AnimatePresence, m } from "framer-motion";
import { ICChevronDown } from "@/assets/icons";
import tw from "@/utils/twmerge";
import { loExpiryAtom } from "@/atoms/limitOrder.atom";
import { useSetAtom } from "jotai";
import CustomPeriodModal from "./CustomPeriodModal";
import { EXPIRY_OPTIONS } from "../constants";
import useExpiryInfo from "../hooks/useExpiryInfo";

function SelectExpiry() {
  const setExpiry = useSetAtom(loExpiryAtom);

  const [openPopover, setOpenPopover] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);

  const { selectedOption, selectedLabel } = useExpiryInfo();

  return (
    <div className="flex flex-col p-1 bg-black-80 border border-black-80 rounded-2xl h-full">
      <div className="flex items-center justify-between px-2 h-6 gap-2.5">
        <div className="flex items-center gap-0.5">
          <span className="text-2xs/none font-light text-gray-100">
            Expired
          </span>
        </div>
      </div>
      <Popover open={openPopover} onOpenChange={setOpenPopover}>
        <PopoverTrigger asChild>
          <button className="flex-1 flex items-center justify-between gap-1 px-2 py-3 rounded-xl bg-transparent">
            <span className="text-sm/none truncate" title={selectedLabel}>
              {selectedLabel}
            </span>
            <ICChevronDown className="w-4 aspect-square text-gray-100" />
          </button>
        </PopoverTrigger>
        <AnimatePresence>
          <PopoverPortal>
            <PopoverContent
              asChild
              align="start"
              sideOffset={10}
              className="relative overflow-hidden min-w-[8rem] max-w-screen bg-black-80 rounded-lg shadow-sm shadow-skin-alt dark:shadow-skin-alt/10 z-[100]"
            >
              <m.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <span className="absolute inset-0 blur-2xl bg-black-80"></span>
                <ul className="relative flex flex-col">
                  {EXPIRY_OPTIONS.map((option) => {
                    const isSelected = option.value === selectedOption.value;
                    return (
                      <li key={option.value}>
                        <button
                          className={tw(
                            "flex items-center gap-2 px-3 py-1.5 w-full text-xs/normal text-left hover:bg-gray-20",
                            isSelected && "bg-gray-20",
                          )}
                          onClick={() => {
                            if (option.value === -1) {
                              setOpenCustom(true);
                            } else {
                              setExpiry(option.value);
                            }
                            setOpenPopover(false);
                          }}
                        >
                          {option.label}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </m.div>
            </PopoverContent>
          </PopoverPortal>
        </AnimatePresence>
      </Popover>
      <CustomPeriodModal open={openCustom} onOpenChange={setOpenCustom} />
    </div>
  );
}

export default SelectExpiry;
