import { ESort } from "@/types/data";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type SwapHistorySortType =
  | "timestamp"
  | "amount-in"
  | "amount-out"
  | "price"
  | "volume";

export const swapHistorySortOptAtom = atom<
  Partial<Record<SwapHistorySortType, ESort>>
>({ timestamp: ESort.DESC });

export const hideOtherPairsAtom = atomWithStorage("hideOtherPairs", false);
