import { useTxExecution } from "@/hooks/transactions/useTxExecution";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useMutation } from "@tanstack/react-query";
import useMutateTxStatusList from "@/hooks/transactions/useMutateTxStatusList";
import { useAtomValue } from "jotai";
import {
  agSlippageAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import { formatRawBalance } from "@/utils/number";
import BigNumber from "bignumber.js";
import {
  DCA_MAX_RATE,
  RATE_SCALE,
  SLIPPAGE_SCALE,
} from "@/utils/limitDca/constants";
import { suiClient } from "@/constants/suiClient";
import { Sentry } from "@/utils/sentry";
import CopyBtn from "@/components/CopyBtn";
import { ICCopy } from "@/assets/icons";
import { placeDcaOrder } from "@/utils/limitDca";
import {
  dcaIntervalTsAtom,
  dcaMaxPriceAtom,
  dcaMinPriceAtom,
  dcaOrdersNumAtom,
  dcaPayPerOrderAtom,
} from "@/atoms/dca.atom";

const usePlaceDcaOrderMutation = () => {
  const currentAccount = useCurrentAccount();
  const executeTx = useTxExecution();
  const mutateTxStatusList = useMutateTxStatusList();

  const tokenIn = useAtomValue(agTokenInAtom);
  const tokenOut = useAtomValue(agTokenOutAtom);
  const payPerOrder = useAtomValue(dcaPayPerOrderAtom);
  const numOrders = useAtomValue(dcaOrdersNumAtom);
  const interval = useAtomValue(dcaIntervalTsAtom);
  const agSlippage = useAtomValue(agSlippageAtom);
  const minPrice = useAtomValue(dcaMinPriceAtom);
  const maxPrice = useAtomValue(dcaMaxPriceAtom);

  return useMutation({
    mutationFn: async ({ txTitle }: { txTitle: string }) => {
      if (!currentAccount?.address) {
        mutateTxStatusList({
          status: "error",
          title: txTitle,
          message: "You need to connect your wallet!",
        });
        throw new Error("No account connected");
      }

      const accountAddress = currentAccount.address;
      const payCoinAmountEach = formatRawBalance(payPerOrder, tokenIn.decimals);
      const slippage = agSlippage
        .toBigNumber()
        .multipliedBy(SLIPPAGE_SCALE.toString());
      const decimalRateScale = new BigNumber(10).pow(
        tokenOut.decimals - tokenIn.decimals,
      );
      const minRate = (
        new BigNumber(minPrice).gte(0)
          ? new BigNumber(minPrice)
          : new BigNumber(0)
      )
        .multipliedBy(decimalRateScale)
        .multipliedBy(RATE_SCALE.toString());
      const maxRate = new BigNumber(maxPrice).gt(0)
        ? new BigNumber(maxPrice)
            .multipliedBy(decimalRateScale)
            .multipliedBy(RATE_SCALE.toString())
        : DCA_MAX_RATE;

      const tx = await placeDcaOrder({
        payCoinType: tokenIn.type,
        targetCoinType: tokenOut.type,
        payCoinAmountEach: BigInt(payCoinAmountEach.toFixed(0)),
        numOrders: Number(numOrders),
        interval,
        slippage: BigInt(slippage.toFixed(0)),
        minRate: BigInt(minRate.toFixed(0)),
        maxRate: BigInt(maxRate.toFixed(0)),
      });

      if (tx) {
        const { error } = await suiClient.devInspectTransactionBlock({
          sender: accountAddress,
          transactionBlock: tx,
        });

        if (error) {
          const errorId = Sentry.captureException(new Error(error));
          mutateTxStatusList({
            status: "error",
            title: txTitle,
            message: `Transaction simulation failure. Please try again in a few seconds or contact support. (ID: ${errorId})`,
            extraAction: (
              <CopyBtn text={`${errorId}: ${error}`} className="p-0.5">
                <ICCopy className="w-5 h-auto" />
              </CopyBtn>
            ),
          });
          throw new Error("Transaction simulation failure");
        }
      }

      return executeTx(tx, txTitle);
    },
  });
};

export default usePlaceDcaOrderMutation;
