export const INFO_TABS = [
  {
    key: "routes",
    shortLabel: "Routes",
    label: "Routes",
    tooltip: null,
  },
  {
    key: "price-chart",
    shortLabel: "Price Chart",
    label: "Price Chart",
    tooltip: null,
  },
] as const;
