import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { TokenAmount } from "@/types/token";
import { POPULAR_TOKENS, SUGGESTED_TOKENS_MAP } from "@/constants/tokens";
import { useAtomValue } from "jotai";
import { accountPriceMapAtom } from "@/atoms/account.atom";

function useSortTrendingBalances(
  balances: TokenAmount[],
  trendingTokens: string[],
): TokenAmount[] {
  const accountPriceMap = useAtomValue(accountPriceMapAtom);

  const sortedBalances = useMemo(() => {
    return balances.slice().sort((a, b) => {
      // Sort by special tokens
      const aIsSpecial = SUGGESTED_TOKENS_MAP[a.token.type];
      const bIsSpecial = SUGGESTED_TOKENS_MAP[b.token.type];
      if (aIsSpecial && bIsSpecial) {
        return 0;
      }
      if (aIsSpecial) {
        return -1;
      }
      if (bIsSpecial) {
        return 1;
      }

      // Sort by usd balance
      const aUsd = new BigNumber(a.amount).multipliedBy(
        accountPriceMap[a.token.type] ?? 0,
      );
      const bUsd = new BigNumber(b.amount).multipliedBy(
        accountPriceMap[b.token.type] ?? 0,
      );
      if (aUsd.gt(0) && bUsd.gt(0)) {
        return bUsd.comparedTo(aUsd);
      }
      if (aUsd.gt(0)) {
        return -1;
      }
      if (bUsd.gt(0)) {
        return 1;
      }

      // Sort by verified tokens
      if (a.token.verified && !b.token.verified) {
        return -1;
      }
      if (!a.token.verified && b.token.verified) {
        return 1;
      }

      // Sort by popular tokens
      const aIndex2 = POPULAR_TOKENS.indexOf(a.token.type);
      const bIndex2 = POPULAR_TOKENS.indexOf(b.token.type);
      if (aIndex2 !== -1 && bIndex2 !== -1) {
        return aIndex2 - bIndex2;
      }
      if (aIndex2 !== -1) {
        return -1;
      }
      if (bIndex2 !== -1) {
        return 1;
      }

      // Sort by trending tokens
      const aIndex = trendingTokens.indexOf(a.token.type);
      const bIndex = trendingTokens.indexOf(b.token.type);
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      if (aIndex !== -1) {
        return -1;
      }
      if (bIndex !== -1) {
        return 1;
      }

      // Sort by symbol
      return a.token.symbol?.localeCompare(b.token.symbol ?? "");
    });
  }, [balances, trendingTokens, accountPriceMap]);

  return sortedBalances;
}

export default useSortTrendingBalances;
