import { useTxExecution } from "@/hooks/transactions/useTxExecution";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useMutation } from "@tanstack/react-query";
import useMutateTxStatusList from "@/hooks/transactions/useMutateTxStatusList";
import { useAtomValue } from "jotai";
import {
  agAmountInAtom,
  agSlippageAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import {
  placeLimitOrder,
  PlaceLimitOrderParams,
} from "@/utils/limitDca/placeLimitOrder";
import { formatRawBalance } from "@/utils/number";
import { loExpiryAtom, loRateValueAtom } from "@/atoms/limitOrder.atom";
import BigNumber from "bignumber.js";
import { RATE_SCALE, SLIPPAGE_SCALE } from "@/utils/limitDca/constants";
import { suiClient } from "@/constants/suiClient";
import { Sentry } from "@/utils/sentry";
import CopyBtn from "@/components/CopyBtn";
import { ICCopy } from "@/assets/icons";

const usePlaceLimitOrderMutation = () => {
  const currentAccount = useCurrentAccount();
  const executeTx = useTxExecution();
  const mutateTxStatusList = useMutateTxStatusList();

  const tokenIn = useAtomValue(agTokenInAtom);
  const tokenOut = useAtomValue(agTokenOutAtom);
  const amountIn = useAtomValue(agAmountInAtom);
  const loRateValue = useAtomValue(loRateValueAtom);
  const loExpiry = useAtomValue(loExpiryAtom);
  const agSlippage = useAtomValue(agSlippageAtom);

  return useMutation({
    mutationFn: async ({ txTitle }: { txTitle: string }) => {
      if (!currentAccount?.address) {
        mutateTxStatusList({
          status: "error",
          title: txTitle,
          message: "You need to connect your wallet!",
        });
        throw new Error("No account connected");
      }

      const accountAddress = currentAccount.address;
      const payCoinAmount = formatRawBalance(amountIn, tokenIn.decimals);
      const decimalRateScale = new BigNumber(10).pow(
        tokenOut.decimals - tokenIn.decimals,
      );
      const rate = loRateValue
        .multipliedBy(decimalRateScale)
        .multipliedBy(RATE_SCALE.toString());
      const slippage = agSlippage
        .toBigNumber()
        .multipliedBy(SLIPPAGE_SCALE.toString());

      const params: PlaceLimitOrderParams = {
        accountAddress,
        payCoinType: tokenIn.type,
        targetCoinType: tokenOut.type,
        payCoinAmount: BigInt(payCoinAmount.toFixed(0)),
        rate: BigInt(rate.toFixed(0)),
        slippage: BigInt(slippage.toFixed(0)),
        expireTs: BigInt(Date.now() + loExpiry),
      };

      const simTx = await placeLimitOrder({
        ...params,
        devInspect: true,
      });

      // cannot use simTx to real execution
      if (simTx) {
        const { error } = await suiClient.devInspectTransactionBlock({
          sender: accountAddress,
          transactionBlock: simTx,
        });

        if (error) {
          const errorId = Sentry.captureException(new Error(error));
          mutateTxStatusList({
            status: "error",
            title: txTitle,
            message: `Transaction simulation failure. Please try again in a few seconds or contact support. (ID: ${errorId})`,
            extraAction: (
              <CopyBtn text={`${errorId}: ${error}`} className="p-0.5">
                <ICCopy className="w-5 h-auto" />
              </CopyBtn>
            ),
          });
          throw new Error("Transaction simulation failure");
        }
      }

      // build tx for real execution
      const tx = await placeLimitOrder(params);

      return executeTx(tx, txTitle);
    },
  });
};

export default usePlaceLimitOrderMutation;
