import { useCallback, useMemo, useState } from "react";
import Accordion, {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/UI/Accordion";
import { ICInfoCircle } from "@/assets/icons";
import TextAmt from "@/components/TextAmt";
import tw from "@/utils/twmerge";
import { useAtomValue } from "jotai";
import OrderItem from "../../../common/OrderInfo/OrderItem";
import PriceButton from "../../../common/OrderInfo/PriceButton";
import BigNumber from "bignumber.js";
import { LO_PLATFORM_FEE } from "@/constants/limitDca";
import {
  agAmountInAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import {
  dcaIntervalTsAtom,
  dcaIntervalUnitAtom,
  dcaIntervalValueAtom,
  dcaOrdersNumAtom,
  dcaPayPerOrderAtom,
} from "@/atoms/dca.atom";
import dayjs from "@/utils/dayjs";

type PriceMode = "in-out" | "out-in";

interface Props {
  marketRate: BigNumber;
}

function OrderInfo({ marketRate }: Props) {
  const [openAccordion, setOpenAccordion] = useState("");
  const [priceMode, setPriceMode] = useState<PriceMode>("in-out");

  const tokenIn = useAtomValue(agTokenInAtom);
  const tokenOut = useAtomValue(agTokenOutAtom);
  const amountIn = useAtomValue(agAmountInAtom);
  const intervalUnit = useAtomValue(dcaIntervalUnitAtom);
  const intervalValue = useAtomValue(dcaIntervalValueAtom);
  const intervalTs = useAtomValue(dcaIntervalTsAtom);
  const ordersNum = useAtomValue(dcaOrdersNumAtom);
  const payPerOrder = useAtomValue(dcaPayPerOrderAtom);

  const estimatedStartDate = useMemo(() => {
    return dayjs().add(intervalTs);
  }, [intervalTs]);

  const intervals = useMemo(() => {
    return intervalTs * (Number(ordersNum) - 1);
  }, [intervalTs, ordersNum]);

  const estimatedEndDate = useMemo(() => {
    const endDate = estimatedStartDate.add(intervals);
    return endDate;
  }, [estimatedStartDate, intervals]);

  const handleChangePriceMode = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
      setPriceMode((prev) => (prev === "in-out" ? "out-in" : "in-out"));
    },
    [],
  );

  const priceElement = useMemo(() => {
    return priceMode === "in-out" ? (
      <span>
        1 {tokenIn.symbol} ={" "}
        {marketRate ? <TextAmt number={marketRate} /> : "-"} {tokenOut.symbol}
      </span>
    ) : (
      <span>
        1 {tokenOut.symbol} ={" "}
        {marketRate ? (
          <TextAmt number={new BigNumber(1).dividedBy(marketRate)} />
        ) : (
          "-"
        )}{" "}
        {tokenIn.symbol}
      </span>
    );
  }, [priceMode, tokenIn.symbol, tokenOut.symbol, marketRate]);

  const trigger = useMemo(() => {
    if (openAccordion) {
      return (
        <div className="font-semibold text-white text-lg/none">
          Order details
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2">
        <ICInfoCircle className="w-3 aspect-square" />
        <PriceButton
          className="text-white text-xs/none font-normal"
          onClick={handleChangePriceMode}
        >
          {priceElement}
        </PriceButton>
      </div>
    );
  }, [openAccordion, priceElement, handleChangePriceMode]);

  return (
    <Accordion
      type="single"
      collapsible
      value={openAccordion}
      onValueChange={(val) => setOpenAccordion(val)}
    >
      <AccordionItem
        value="default"
        className="border border-darkblue-100 bg-darkblue-100"
      >
        <AccordionTrigger
          className={tw(openAccordion ? "px-6 py-4" : "p-4")}
          iconClassName="w-3 aspect-square"
        >
          {trigger}
        </AccordionTrigger>
        <div className="transition-all duration-300 ease-in-out">
          {openAccordion && (
            <AccordionContent forceMount collapsedAnimate={false}>
              <div className="flex flex-col gap-4 px-6 pb-6 pt-0">
                <div className="h-px bg-[#707585]" />
                <OrderItem
                  label="Sell total"
                  value={
                    <span>
                      {amountIn ? <TextAmt number={amountIn} /> : "-"}{" "}
                      {tokenIn.symbol}
                    </span>
                  }
                />
                <OrderItem
                  label="Sell per order"
                  value={
                    <span>
                      {amountIn && ordersNum ? (
                        <TextAmt number={payPerOrder} />
                      ) : (
                        "-"
                      )}{" "}
                      {tokenIn.symbol}
                    </span>
                  }
                />
                <OrderItem label="To buy" value={tokenOut.symbol} />
                <OrderItem
                  label="Order interval"
                  value={`${intervalValue} ${intervalUnit}(s)`}
                />
                <OrderItem
                  label="Estimated start date"
                  value={estimatedStartDate.format("DD MMM, YYYY HH:mm")}
                />
                <OrderItem
                  label="Estimated end date"
                  value={estimatedEndDate.format("DD MMM, YYYY HH:mm")}
                />
                {/* <OrderItem
                  label="Estimated price impact per order"
                  value={"0.04%"}
                /> */}
                <div className="h-px bg-[#707585]" />
                <OrderItem
                  label="Platform Fee"
                  value={
                    <span>
                      <TextAmt
                        number={LO_PLATFORM_FEE.toBigNumber().multipliedBy(100)}
                        suffix="%"
                      />
                    </span>
                  }
                />
              </div>
            </AccordionContent>
          )}
        </div>
      </AccordionItem>
    </Accordion>
  );
}

export default OrderInfo;
