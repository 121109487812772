import {
  accountBalanceMapAtom,
  accountPriceMapAtom,
} from "@/atoms/account.atom";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import useTokenPrices from "../prices/useTokenPrices";

const useAccountPriceMap = () => {
  const setAccountPriceMap = useSetAtom(accountPriceMapAtom);

  const accountBalanceMap = useAtomValue(accountBalanceMapAtom);
  const accountBalanceIds = useMemo(
    () => Object.keys(accountBalanceMap),
    [accountBalanceMap],
  );

  const { data: prices } = useTokenPrices(accountBalanceIds);

  useEffect(() => {
    if (prices) {
      setAccountPriceMap((prev) => ({
        ...prev,
        ...prices,
      }));
    }
  }, [prices, setAccountPriceMap]);
};

export default useAccountPriceMap;
