import { ICRefresh } from "@/assets/icons";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { memo } from "react";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

function RefreshHistoryButton({ onClick, disabled }: Props) {
  const currentAccount = useCurrentAccount();

  return (
    <button
      className="w-8 aspect-square flex items-center justify-center rounded-lg bg-[#252734] hover:bg-[#383D4C] disabled:cursor-not-allowed disabled:opacity-60"
      onClick={onClick}
      disabled={!currentAccount?.address || disabled}
    >
      <ICRefresh className="w-4 aspect-square" />
    </button>
  );
}

export default memo(RefreshHistoryButton);
