import { ICDotActive } from "@/assets/icons";
import { DcaOrderItem } from "./hooks/useDcaOrderItems";
import TokenAvatar from "@/components/Avatar/TokenAvatar";
import TextAmt from "@/components/TextAmt";
import tw from "@/utils/twmerge";
import ExplorerTxLink from "@/components/ExplorerLink/ExplorerTxLink";
import { formatAddress } from "@mysten/sui/utils";
import BigNumber from "bignumber.js";
import { useMemo, useState } from "react";
import PriceModeBtn, { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";

interface Props {
  data: DcaOrderItem;
  handleOpenCancelModal: (item: DcaOrderItem) => void;
  avgPrice: BigNumber;
  avgPriceInverted: BigNumber;
  totalSpent: BigNumber;
}

function DcaOrderInfo({
  data,
  handleOpenCancelModal,
  avgPrice,
  avgPriceInverted,
  totalSpent,
}: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  const [priceMode, setPriceMode] = useState<PriceMode>("out-in");

  const price = useMemo(() => {
    return priceMode === "in-out" ? avgPriceInverted : avgPrice;
  }, [avgPrice, avgPriceInverted, priceMode]);

  const { tokenIn, tokenOut, filledNumOrders, totalNumOrders, digest } = data;

  const statusElement = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <span className="text-2xs/none">Status</span>
        <div className="flex items-center justify-end gap-2 px-2 h-7.5 rounded-lg bg-black-60">
          <span className="text-xs/none text-green-90">Running</span>
          <ICDotActive />
        </div>
      </div>
    );
  }, []);

  const totalSpentElement = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <span className="text-2xs/none">Total Spent</span>
        <div className="flex items-center justify-end gap-2 px-2 h-7.5 rounded-lg bg-black-60">
          {tokenIn && (
            <div className="flex items-center gap-1">
              <TokenAvatar token={tokenIn} className="w-3 h-3" />
              <TextAmt number={totalSpent} className="text-xs" />
              <span className="font-normal text-3xs text-gray-100">
                {tokenIn.symbol}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }, [tokenIn, totalSpent]);

  const avgPriceElement = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <span className="text-2xs/none">Avg. Price</span>
        <div className="flex items-center justify-end gap-2 px-2 h-7.5 rounded-lg bg-black-60">
          <div className="flex items-center gap-1">
            <TextAmt
              number={price}
              className="text-xs"
              decimalClassName="text-gray-100"
            />{" "}
            <PriceModeBtn
              tokenIn={tokenIn?.symbol || ""}
              tokenOut={tokenOut?.symbol || ""}
              value={priceMode}
              onChange={setPriceMode}
            />
          </div>
        </div>
      </div>
    );
  }, [price, priceMode, tokenIn, tokenOut]);

  const boughtTimeElement = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <span className="text-2xs/none">Bought Time / Total</span>
        <div className="flex items-center justify-end gap-2.5 px-2 h-7.5 rounded-lg bg-black-60">
          <span className="text-xs">
            <TextAmt
              number={filledNumOrders}
              className={tw(filledNumOrders.gt(0) && "text-green-80")}
            />{" "}
            / <TextAmt number={totalNumOrders} />
          </span>
          <span className="flex items-center p-1 h-5 rounded bg-black-40 font-semibold text-3xs">
            Orders
          </span>
        </div>
      </div>
    );
  }, [filledNumOrders, totalNumOrders]);

  const dcaDigestElement = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <span className="text-2xs/none">Open DCA Digest</span>
        <div className="flex items-center justify-end gap-2.5 px-2 h-7.5 rounded-lg bg-black-60">
          <ExplorerTxLink
            txHash={digest}
            className="text-xs text-gray-100 underline hover:text-green-80"
          >
            {formatAddress(digest)}
          </ExplorerTxLink>
        </div>
      </div>
    );
  }, [digest]);

  const stopDcaButton = useMemo(() => {
    return (
      <button
        className="flex items-center justify-start px-4 h-10 rounded-xl bg-pink-100"
        onClick={() => handleOpenCancelModal(data)}
      >
        <span className="text-xs/none">Stop DCA</span>
      </button>
    );
  }, [data, handleOpenCancelModal]);

  return (
    <div className="flex flex-col gap-2">
      <div
        className={tw(
          isMobile
            ? "grid grid-cols-2 gap-2"
            : "flex flex-col gap-2 p-4 border border-[#222436] rounded-xl",
        )}
      >
        {statusElement}
        {totalSpentElement}
        {!isMobile && avgPriceElement}
        {boughtTimeElement}
        {dcaDigestElement}
      </div>
      {stopDcaButton}
    </div>
  );
}

export default DcaOrderInfo;
