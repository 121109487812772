import { getTokenPrices } from "@/utils/price";
import { useQuery } from "@tanstack/react-query";

interface Config {
  refetchInterval?: number;
}

const useTokenPrices = (ids: string[], config?: Config) => {
  return useQuery<Record<string, number>>({
    queryKey: ["getTokenPrices", ids],
    queryFn: async () => {
      return getTokenPrices(ids);
    },
    enabled: ids.length > 0,
    refetchInterval: config?.refetchInterval,
  });
};

export default useTokenPrices;
