import useGetOpenLimitOrders from "@/hooks/limitDca/useGetOpenLimitOrders";
import RefreshHistoryButton from "../../common/RefreshHistoryButton";
import { useSetAtom } from "jotai";
import { loIsRefetchingOpenOrdersAtom } from "@/atoms/limitOrder.atom";

function RefreshLimitHistoryButton() {
  const { refetch, isPending } = useGetOpenLimitOrders();
  const setIsRefetching = useSetAtom(loIsRefetchingOpenOrdersAtom);

  const handleRefetch = async () => {
    try {
      setIsRefetching(true);
      await refetch();
      setIsRefetching(false);
    } catch (e) {
      setIsRefetching(false);
    }
  };

  return <RefreshHistoryButton onClick={handleRefetch} disabled={isPending} />;
}

export default RefreshLimitHistoryButton;
