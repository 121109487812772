import { Percent } from "@bicarus/utils";
import { TS_1_MINUTE, TS_30_DAYS } from "./time";

const MIN_USD_VALUE = import.meta.env.VITE_ENV === "alpha" ? 0.1 : 5;

export const LO_PLATFORM_FEE = new Percent(1, 1_000); // 0.1%

export const LO_DCA_MAX_EXPIRE_TS = TS_30_DAYS;

export const LO_RATE_DECIMALS = 12;

export const LO_SLIPPAGE_DECIMALS = 4;

export const LO_MIN_USD_VALUE = MIN_USD_VALUE;

export const DCA_MIN_INTERVAL_TS = 15 * TS_1_MINUTE; // 15 minutes

export const DCA_MIN_PER_ORDER_USD_VALUE = MIN_USD_VALUE;
