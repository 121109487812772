import { memo, useMemo } from "react";
import tw from "@/utils/twmerge";
import TextAmt from "@/components/TextAmt";
import TokenGroupAvatar from "@/components/Avatar/TokenGroupAvatar";
import ExplorerTxLink from "@/components/ExplorerLink/ExplorerTxLink";
import { formatAddress } from "@mysten/sui/utils";
import PriceModeBtn from "@/pages/trade/common/PriceModeBtn";
import { OpenOrderItem } from "../hooks/useOpenOrderItems";
import useOpenOrderItem from "../hooks/useOpenOrderItem";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "@/components/CircularProgressbar";
import Countdown from "@/components/Countdown";

interface Props {
  data: OpenOrderItem;
  handleOpenCancelModal: (item: OpenOrderItem) => void;
  handleOpenClaimModal: (item: OpenOrderItem) => void;
  onClick?: () => void;
  className?: string;
  size?: "default" | "large";
}

function LimitHistoryContent({
  data,
  onClick,
  handleOpenCancelModal,
  handleOpenClaimModal,
  className,
  size = "default",
}: Props) {
  const {
    tokenIn,
    tokenOut,
    price,
    priceMode,
    setPriceMode,
    fillPercentage,
    obtainedAmount,
    totalPayAmount,
    status,
  } = useOpenOrderItem({ data });

  const isLarge = useMemo(() => size === "large", [size]);
  const isActive = useMemo(() => status === "ACTIVE", [status]);
  const isExpired = useMemo(() => status === "EXPIRED", [status]);

  if (isLarge) {
    return (
      <div className="flex flex-col gap-6 w-full">
        <div className="flex flex-col gap-2">
          <div
            className={tw(
              "flex flex-1 flex-col rounded-2xl bg-black-80 gap-4 p-4 border",
              isExpired ? "border-pink-100" : "border-black-80",
              className,
            )}
          >
            <div className="flex flex-col gap-2">
              {tokenIn && tokenOut && (
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex items-center gap-1">
                    <TokenGroupAvatar tokens={[tokenIn, tokenOut]} />
                    <span className="font-bold text-sm/none">
                      {tokenIn.symbol} - {tokenOut.symbol}
                    </span>
                  </div>

                  <p className="flex items-center gap-1">
                    <span className="text-sm/none text-green-80">Buy</span>
                    <TextAmt
                      number={data.amountOut}
                      className="font-normal text-sm/none text-gray-100"
                      suffix={` ${tokenOut.symbol}`}
                    />
                  </p>
                </div>
              )}
            </div>

            <div className="h-px bg-black-40" />

            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col items-start gap-4">
                <span className="flex items-center font-light text-2xs/none text-gray-100 min-h-4">
                  Price
                </span>
                <span className="text-xs/none">
                  <div className="flex items-center gap-1">
                    <TextAmt number={price} className="text-sm/none" />{" "}
                    <div onClick={(e) => e.stopPropagation()}>
                      <PriceModeBtn
                        tokenIn={tokenIn?.symbol || ""}
                        tokenOut={tokenOut?.symbol || ""}
                        value={priceMode}
                        onChange={setPriceMode}
                        className="bg-black-100"
                      />
                    </div>
                  </div>
                </span>
              </div>

              <div className="flex flex-col items-start gap-4">
                <span className="font-light text-2xs/none text-gray-100">
                  Digest
                </span>
                <ExplorerTxLink
                  txHash={data.orderId}
                  className="text-sm/none hover:text-green-80"
                >
                  {formatAddress(data.orderId)}
                </ExplorerTxLink>
              </div>
            </div>

            <div className="flex-1 flex items-center gap-2">
              <div className="h-10 w-10">
                <CircularProgressbarWithChildren
                  value={fillPercentage}
                  strokeWidth={12}
                  styles={buildStyles({
                    pathColor: isExpired ? "#F24DB0" : "#85FF99",
                    trailColor: "#0B0D14",
                  })}
                >
                  <span
                    className={tw(
                      "font-normal text-xs/none text-gray-100",
                      fillPercentage > 0 && "text-green-80",
                      isExpired && "text-pink-100",
                    )}
                  >
                    {fillPercentage}
                  </span>
                </CircularProgressbarWithChildren>
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <div className="flex items-center justify-between gap-2">
                  <span className="font-normal text-2xs/none text-gray-100">
                    {" "}
                    Fill{" "}
                    <TextAmt
                      number={fillPercentage}
                      suffix="%"
                      className={tw(fillPercentage > 0 && "text-green-80")}
                    />
                  </span>
                  <span className="text-xs/none text-gray-100">
                    <TextAmt
                      number={obtainedAmount}
                      className="text-white"
                      decimalClassName="text-gray-100"
                    />{" "}
                    {tokenIn.symbol}
                  </span>
                </div>
                <div className="h-px w-full bg-black-60" />
                <div className="flex items-center justify-between gap-2">
                  <span className="font-normal text-2xs/none text-gray-100">
                    Total
                  </span>
                  <span className="text-xs/none text-gray-100">
                    <TextAmt
                      number={totalPayAmount}
                      className="text-white"
                      decimalClassName="text-gray-100"
                    />{" "}
                    {tokenIn.symbol}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {isActive && (
            <div className="flex items-center justify-between gap-4 p-3 bg-black-80 border border-black-80 rounded-2xl text-xs/none h-10">
              <span>Duration</span>
              <Countdown timestamp={Math.floor(data.expireTs / 1000)} />
            </div>
          )}
          {isExpired && (
            <div className="flex items-center justify-between gap-4 p-3 border border-pink-100 bg-pink-100/20 rounded-2xl text-xs/none text-pink-100 h-10">
              <span>Order Expired</span>
            </div>
          )}
        </div>

        <div className="flex-1 flex">
          {isActive && (
            <button
              className="flex-1 flex items-center justify-center h-[3.5rem] rounded-2xl bg-pink-100"
              onClick={() => handleOpenCancelModal(data)}
            >
              <span className="text-lg/none text-white">Cancel order</span>
            </button>
          )}
          {isExpired && (
            <button
              className="flex-1 flex items-center justify-center h-[3.5rem] rounded-2xl bg-yellow-90"
              onClick={() => handleOpenClaimModal(data)}
            >
              <span className="text-lg/none text-black-80">Claim back</span>
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <a className="flex w-full" onClick={onClick}>
      <div
        className={tw(
          "flex flex-1 flex-col rounded-2xl bg-black-60 gap-4 p-4",
          className,
        )}
      >
        {tokenIn && tokenOut && (
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center gap-1">
              <TokenGroupAvatar tokens={[tokenIn, tokenOut]} />
              <span className="font-bold text-xs/none">
                {tokenIn.symbol} - {tokenOut.symbol}
              </span>
            </div>

            <p className="flex items-center gap-1">
              <span className="text-xs/none text-green-80">Buy</span>
              <TextAmt
                number={data.amountOut}
                className="font-normal text-xs/none text-gray-100"
                suffix={` ${tokenOut.symbol}`}
              />
            </p>
          </div>
        )}

        <div className="h-px bg-black-40" />

        <div className="grid grid-cols-5 gap-2">
          <div className="col-span-2 flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Price
            </span>
            <span className="text-xs/none">
              <div className="flex items-center gap-1">
                <TextAmt number={price} className="text-xs/none" />{" "}
                <div onClick={(e) => e.stopPropagation()}>
                  <PriceModeBtn
                    tokenIn={tokenIn?.symbol || ""}
                    tokenOut={tokenOut?.symbol || ""}
                    value={priceMode}
                    onChange={setPriceMode}
                    className="bg-black-100"
                  />
                </div>
              </div>
            </span>
          </div>

          <div className="col-span-2 flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Total value
            </span>
            <span className="text-xs/none">
              <div className="flex items-center gap-1">
                <TextAmt
                  number={data.amountIn.isZero() ? null : data.amountIn}
                  className="text-xs/none"
                  suffix={<span> {tokenIn.symbol}</span>}
                />
              </div>
            </span>
          </div>

          <div className="col-span-1 flex items-center justify-center gap-4">
            <div className="h-10 w-10">
              <CircularProgressbarWithChildren
                value={fillPercentage}
                strokeWidth={12}
                styles={buildStyles({
                  pathColor: isExpired ? "#F24DB0" : "#85FF99",
                  trailColor: "#1C1E2C",
                })}
              >
                <span
                  className={tw(
                    "font-normal text-xs/none text-gray-100",
                    fillPercentage > 0 && "text-green-80",
                    isExpired && "text-pink-100",
                  )}
                >
                  {fillPercentage}
                </span>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default memo(LimitHistoryContent);
