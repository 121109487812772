import { useSetAtom } from "jotai";
import RefreshHistoryButton from "../../common/RefreshHistoryButton";
import useGetOpenDcaOrders from "@/hooks/limitDca/useGetOpenDcaOrders";
import { dcaIsRefetchingOpenOrdersAtom } from "@/atoms/dca.atom";

function RefreshDcaHistoryButton() {
  const { refetch, isPending } = useGetOpenDcaOrders();
  const setIsRefetching = useSetAtom(dcaIsRefetchingOpenOrdersAtom);

  const handleRefetch = async () => {
    try {
      setIsRefetching(true);
      await refetch();
      setIsRefetching(false);
    } catch (e) {
      setIsRefetching(false);
    }
  };

  return <RefreshHistoryButton onClick={handleRefetch} disabled={isPending} />;
}

export default RefreshDcaHistoryButton;
