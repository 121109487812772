import { Transaction } from "@mysten/sui/transactions";
import { SUI_CLOCK_OBJECT_ID } from "@mysten/sui/utils";
import { BaseContract } from "../base";

const PACKAGE_ID_ESTIMATE =
  "0x52971e17352901fb1250bc3fe8aed0fe0c1c0dc449ddac0c603ee2e368d22aa6";
const PACKAGE_ID =
  "0x7efa6e45163cf24b31ca71bd8f2ca5b93af89b7b34bdb50ec1795223b75d729b";
const CONFIG =
  "0xd746495d04a6119987c2b9334c5fefd7d8cff52a8a02a3ea4e3995b9a041ace4";
const DEX_INFO_ID =
  "0x3f2d9f724f4a1ce5e71676448dc452be9a6243dac9c5b975a588c8c867066e92";

export class MovepumpContract extends BaseContract {
  async swap(tx: Transaction) {
    // coinX = SUI, coinY = MEME
    const [coinX, coinY] = this.swapInfo.pool.allTokens;
    const swapXToY = this.swapInfo.assetIn === coinX.address;
    const [SuiOut, MemeOut] = tx.moveCall(
      swapXToY
        ? {
            target: `${PACKAGE_ID}::move_pump::buy_returns`,
            typeArguments: [coinY.address],
            arguments: [
              tx.object(CONFIG),
              this.inputCoinObject, // input coin
              tx.object(DEX_INFO_ID),
              tx.moveCall({
                target: `${PACKAGE_ID_ESTIMATE}::move_pump::estimate_amount_out`,
                typeArguments: [coinY.address],
                arguments: [
                  tx.object(CONFIG),
                  this.getInputCoinValue(tx),
                  tx.pure.u64(0),
                ],
              })[1], // exact meme output amount
              tx.object(SUI_CLOCK_OBJECT_ID),
            ],
          }
        : {
            target: `${PACKAGE_ID}::move_pump::sell_returns`,
            typeArguments: [coinY.address],
            arguments: [
              tx.object(CONFIG),
              this.inputCoinObject, // input coin
              tx.pure.u64(0), // min sui output amount
              tx.object(SUI_CLOCK_OBJECT_ID),
            ],
          },
    );
    tx.transferObjects([swapXToY ? SuiOut : MemeOut], this.currentAccount);
    return swapXToY ? MemeOut : SuiOut;
  }
}
