import { useTxExecution } from "@/hooks/transactions/useTxExecution";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useMutation } from "@tanstack/react-query";
import { buildTx } from "@/utils/aggregator";
import { SorSwapResponse } from "@/types/swapInfo";
import { suiClient } from "@/constants/suiClient";
import useMutateTxStatusList from "@/hooks/transactions/useMutateTxStatusList";
import { useAtomValue } from "jotai";
import { agDebugSimulate } from "@/atoms/aggregator.atom";
import { Sentry } from "@/utils/sentry";
import { TokenDetails } from "@/types/token";
import CopyBtn from "@/components/CopyBtn";
import { ICCopy } from "@/assets/icons";

const useAggregateMutation = () => {
  const currentAccount = useCurrentAccount();
  const executeTx = useTxExecution();
  const mutateTxStatusList = useMutateTxStatusList();
  const isSimulate = useAtomValue(agDebugSimulate);

  return useMutation({
    mutationFn: async ({
      sorResponse,
      txTitle,
      extra: { tokenIn, tokenOut },
    }: {
      sorResponse: SorSwapResponse;
      txTitle: string;
      extra: {
        tokenIn: TokenDetails;
        tokenOut: TokenDetails;
      };
    }) => {
      if (!currentAccount?.address) {
        mutateTxStatusList({
          status: "error",
          title: txTitle,
          message: "You need to connect your wallet!",
        });
        throw new Error("No account connected");
      }

      const accountAddress = currentAccount.address;
      // cannot use simTx to real execution
      const simTx = await buildTx({
        sorResponse,
        accountAddress,
        devInspect: true,
      });

      if (simTx) {
        const { error } = await suiClient.devInspectTransactionBlock({
          sender: accountAddress,
          transactionBlock: simTx,
        });

        if (error) {
          if (error.includes("::settle::settle")) {
            mutateTxStatusList({
              status: "error",
              title: txTitle,
              message:
                "Slippage too small. Please increase slippage to prevent transaction from failing.",
            });
            throw new Error("Slippage too small");
          }

          if (error.includes("AddressDeniedForCoin")) {
            const coinType = error.match(/coin_type: "([^"]+)"/)?.[1];
            let symbol = "this coin";
            if (coinType) {
              if (tokenIn.type.includes(coinType)) {
                symbol = tokenIn.symbol;
              } else if (tokenOut.type.includes(coinType)) {
                symbol = tokenOut.symbol;
              }
            }
            mutateTxStatusList({
              status: "error",
              title: txTitle,
              message: `Your wallet has been restricted for ${symbol}. Kindly contact this project about your case.`,
            });
            throw new Error("Address denied for coin");
          }

          const errorId = Sentry.captureException(new Error(error));
          mutateTxStatusList({
            status: "error",
            title: txTitle,
            message: `Transaction simulation failure. Please try again in a few seconds or contact support. (ID: ${errorId})`,
            extraAction: (
              <CopyBtn text={`${errorId}: ${error}`} className="p-0.5">
                <ICCopy className="w-5 h-auto" />
              </CopyBtn>
            ),
          });
          throw new Error("Transaction simulation failure");
        }
      }

      // build tx for real execution
      const tx = await buildTx({ sorResponse, accountAddress });

      if (isSimulate) {
        throw new Error("Simulation mode is enabled");
      }

      return executeTx(tx, txTitle);
    },
  });
};

export default useAggregateMutation;
