import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue, useSetAtom } from "jotai";
import { AnimatePresence } from "framer-motion";
import {
  Dialog,
  DialogContentDefault,
  DialogTrigger,
} from "@/components/UI/Dialog";
import { Sheet, SheetContent, SheetTrigger } from "@/components/UI/Sheet";
import { useMemo, useState } from "react";
import Avatar from "@/components/Avatar";
import { ICChevronDown, ICSearch } from "@/assets/icons";
import { useDebounce } from "use-debounce";
import Input from "@/components/UI/Input";
import { TokenAmount, TokenDetails } from "@/types/token";
import uniqBy from "lodash/uniqBy";
import { VList } from "virtua";
import TokenItem from "./TokenItem";
import useSearchTokenList from "@/hooks/tokens/useSearchTokenList";
import { Skeleton } from "@/components/UI/Skeleton";
import Repeat from "@/components/UI/Repeat";
import EmptyData from "@/components/EmptyData/EmptyData";
import {
  memeTokenListAtom,
  pinTokenListAtom,
  strictTokenListAtom,
  tokenListTabAtom,
  trendingTokenListAtom,
  unverifiedTokenWarningOpenAtom,
} from "@/atoms/token.atom";
import SuggestedTokenItem from "./SuggestedTokenItem";
import TokenListTabs from "./TokenListTabs";
import useSortTokenBalances from "@/hooks/balances/useSortTokenBalances";
import useSortTrendingBalances from "@/hooks/balances/useSortTrendingBalances";
import tw from "@/utils/twmerge";
import { useSearchParams } from "react-router-dom";

type Props = {
  token: TokenDetails | undefined;
  setToken: (token: TokenDetails) => void;
  pivotTokenId: string;
  accountBalanceMap: Record<string, string> | undefined;
  type: "from" | "to";
  triggerClassName?: string;
};

function SelectTokenModal({
  token,
  setToken,
  pivotTokenId,
  accountBalanceMap,
  type,
  triggerClassName,
}: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  const setSearchParams = useSearchParams()[1];

  const setUnverifiedTokenWarningOpen = useSetAtom(
    unverifiedTokenWarningOpenAtom,
  );

  const [open, setOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [debounceSearchTerm] = useDebounce(searchTerm, 200);
  const isSearching = useMemo(() => !!debounceSearchTerm, [debounceSearchTerm]);

  const trendingTokenList = useAtomValue(trendingTokenListAtom);
  const trendingTokenTypes = useMemo(
    () => trendingTokenList.map((t) => t.type),
    [trendingTokenList],
  );
  const trendingTokenBalances = useMemo(() => {
    let tokens = trendingTokenList;

    // remove pivot token
    tokens = tokens.filter((token) => token.type !== pivotTokenId);

    // format token balances
    const tokenBalances: TokenAmount[] = tokens.map((token) => ({
      token,
      amount: accountBalanceMap?.[token.type] ?? "0",
    }));

    return uniqBy(tokenBalances, "token.type");
  }, [trendingTokenList, accountBalanceMap, pivotTokenId]);

  const selectedTab = useAtomValue(tokenListTabAtom);

  const strictTokenList = useAtomValue(strictTokenListAtom);
  const pinTokenList = useAtomValue(pinTokenListAtom);
  const memeTokenList = useAtomValue(memeTokenListAtom);
  const selectedTokenList = useMemo(() => {
    if (selectedTab === "meme") {
      return memeTokenList;
    }
    return uniqBy([...pinTokenList, ...strictTokenList], "type");
  }, [selectedTab, memeTokenList, strictTokenList, pinTokenList]);
  const { data: searchedTokenList, isLoading } =
    useSearchTokenList(debounceSearchTerm);
  const tokenList = useMemo(() => {
    if (isSearching) {
      return searchedTokenList ?? [];
    }
    return selectedTokenList;
  }, [isSearching, searchedTokenList, selectedTokenList]);

  const _tokenBalances: TokenAmount[] = useMemo(() => {
    // default tokens
    let tokens = tokenList;

    // remove pivot token
    tokens = tokens.filter((token) => token.type !== pivotTokenId);

    // format token balances
    const tokenBalances: TokenAmount[] = tokens.map((token) => ({
      token,
      amount: accountBalanceMap?.[token.type] ?? "0",
    }));

    return uniqBy(tokenBalances, "token.type");
  }, [accountBalanceMap, pivotTokenId, tokenList]);
  const tokenBalances = useSortTokenBalances(
    _tokenBalances,
    trendingTokenTypes,
  );

  const selectedTrendingTokenBalances = useSortTrendingBalances(
    trendingTokenBalances,
    trendingTokenTypes,
  );

  const top10SelectedTokenBalances = useMemo(
    () => selectedTrendingTokenBalances.slice(0, 10),
    [selectedTrendingTokenBalances],
  );

  const trigger = useMemo(
    () => (
      <button
        className={tw(
          "flex items-center gap-2 p-3 rounded-xl",
          triggerClassName,
        )}
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center gap-2">
          <Avatar
            src={token?.iconUrl || ""}
            alt={token?.symbol}
            w={48}
            h={48}
            className="w-5 aspect-square"
          />
          <span className="text-sm/none">{token?.symbol}</span>
        </div>
        <ICChevronDown className="w-4 aspect-square text-gray-100" />
      </button>
    ),
    [token, triggerClassName],
  );

  const trendingTokenListElement = useMemo(() => {
    if (top10SelectedTokenBalances.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-wrap gap-2">
        {top10SelectedTokenBalances.map((tokenBalance) => (
          <SuggestedTokenItem
            key={tokenBalance.token.type}
            item={tokenBalance}
            onClick={(tokenBalance) => {
              setToken(tokenBalance.token);
              setOpen(false);
              if (!tokenBalance.token.verified) {
                setUnverifiedTokenWarningOpen(true);
              }
              setSearchParams((prev) => {
                prev.set(type, tokenBalance.token.type);
                return prev;
              });
            }}
          />
        ))}
      </div>
    );
  }, [
    top10SelectedTokenBalances,
    setToken,
    setUnverifiedTokenWarningOpen,
    setSearchParams,
    type,
  ]);

  const tokenListElement = useMemo(() => {
    if (searchTerm && isLoading) {
      return (
        <VList
          style={{ height: isMobile ? "50vh" : "60vh" }}
          className="scroll-container"
        >
          <Repeat count={10}>
            <Skeleton className="h-12 p-4 bg-black-80 rounded-xl text-sm/normal mb-1" />
          </Repeat>
        </VList>
      );
    }

    if (!tokenBalances.length) {
      return (
        <EmptyData
          title="No tokens found"
          className={tw(isMobile ? "h-[50vh]" : "h-[60vh]")}
        />
      );
    }

    return (
      <VList
        style={{ height: isMobile ? "50vh" : "60vh" }}
        className="scroll-container"
      >
        {tokenBalances.map((tokenBalance) => (
          <TokenItem
            key={tokenBalance.token.type}
            item={tokenBalance}
            onClick={(tokenBalance) => {
              setToken(tokenBalance.token);
              setOpen(false);
              if (!tokenBalance.token.verified) {
                setUnverifiedTokenWarningOpen(true);
              }
              setSearchParams((prev) => {
                prev.set(type, tokenBalance.token.type);
                return prev;
              });
            }}
          />
        ))}
      </VList>
    );
  }, [
    isLoading,
    tokenBalances,
    searchTerm,
    setToken,
    isMobile,
    setUnverifiedTokenWarningOpen,
    setSearchParams,
    type,
  ]);

  const content = useMemo(
    () => (
      <>
        <Input
          placeholder="Search coin name or type"
          prefixSlot={
            <ICSearch className="shrink-0 w-4 h-auto text-gray-100" />
          }
          postfixSlot={
            <button
              className="shrink-0 flex items-center justify-center px-2 py-1 rounded-lg bg-black-50 font-bold text-sm/none text-black-inverted-100"
              onClick={() => setOpen(false)}
            >
              ESC
            </button>
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 bg-black-100 rounded-xl text-sm/normal"
          innerClassName="py-4"
        />

        {!isSearching && trendingTokenListElement}

        <div className="flex flex-col gap-4">
          {!isSearching && <TokenListTabs />}
          {tokenListElement}
        </div>
      </>
    ),
    [searchTerm, trendingTokenListElement, tokenListElement, isSearching],
  );

  if (isMobile) {
    return (
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>{trigger}</SheetTrigger>
        <AnimatePresence>
          {open && (
            <SheetContent
              forceMount
              side="bottom"
              onOpenChange={setOpen}
              animation={false}
              className="p-4 flex flex-col gap-4"
            >
              {content}
            </SheetContent>
          )}
        </AnimatePresence>
      </Sheet>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <AnimatePresence>
        {open && (
          <DialogContentDefault
            forceMount
            className="shadow-none flex items-start gap-4 p-0 max-w-full md:w-[28.75rem]"
          >
            <div className="flex-1 flex flex-col gap-4 p-1 rounded-2xl bg-black-80 border border-black-50 backdrop-blur-md shadow-soft-3 shadow-skin-alt dark:shadow-skin-alt/10">
              {content}
            </div>
          </DialogContentDefault>
        )}
      </AnimatePresence>
    </Dialog>
  );
}

export default SelectTokenModal;
