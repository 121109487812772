import { ICFrame, ICWallet } from "@/assets/icons";
import {
  agAmountInAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import TextAmt from "@/components/TextAmt";
import { BIG_ZERO, MINIMUM_SUI_AMT } from "@/constants/amount";
import useAccountBalanceList from "@/hooks/accounts/useAccountBalanceList";
import { formatBalance } from "@/utils/number";
import { checkIsSui } from "@/utils/token";
import tw from "@/utils/twmerge";
import { formatAmount } from "@bicarus/utils";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import BigNumber from "bignumber.js";
import { getDefaultStore, useAtom, useAtomValue } from "jotai";
import { useCallback, useMemo, useRef } from "react";
import OrderInfo from "./OrderInfo";
import NumericalInput from "@/components/Input/NumericalInput";
import { accountBalanceMapAtom } from "@/atoms/account.atom";
import ReactGa from "@/utils/ga";
import useTokenPrices from "@/hooks/prices/useTokenPrices";
import { useSearchParams } from "react-router-dom";
import TradeTabs from "../../common/TradeTabs";
import SelectTokenModal from "../../common/SelectTokenModal";
import SelectIntervalOrders from "./SelectIntervalOrders";
import PriceRangeInput from "./PriceRangeInput";
import {
  dcaIntervalTsAtom,
  dcaIntervalUnitAtom,
  dcaIntervalValueAtom,
  dcaMaxPriceAtom,
  dcaMinPriceAtom,
  dcaOrdersNumAtom,
} from "@/atoms/dca.atom";
import {
  DCA_MIN_PER_ORDER_USD_VALUE,
  LO_DCA_MAX_EXPIRE_TS,
  DCA_MIN_INTERVAL_TS,
} from "@/constants/limitDca";
import usePlaceDcaOrderMutation from "@/mutations/limitDca/usePlaceDcaOrderMutation";
import SlippageDropdown from "../../common/SlippageDropdown";
import useGetOpenDcaOrders from "@/hooks/limitDca/useGetOpenDcaOrders";
import { DCA_MAX_RATE, RATE_SCALE } from "@/utils/limitDca/constants";

function DcaForm() {
  const setSearchParams = useSearchParams()[1];

  const [tokenIn, setTokenIn] = useAtom(agTokenInAtom);
  const [tokenOut, setTokenOut] = useAtom(agTokenOutAtom);
  const [amountIn, setAmountIn] = useAtom(agAmountInAtom);

  const tokenInId = useMemo(() => tokenIn?.type, [tokenIn]);
  const tokenOutId = useMemo(() => tokenOut?.type, [tokenOut]);

  const accountBalanceMap = useAtomValue(accountBalanceMapAtom);
  const { refetch: refetchAccountBalances } = useAccountBalanceList();

  const { refetch: refetchDcaOrders } = useGetOpenDcaOrders();

  const tokenInBalance = useMemo(() => {
    if (accountBalanceMap?.[tokenInId]) {
      return new BigNumber(
        formatBalance(accountBalanceMap[tokenInId], tokenIn.decimals),
      );
    }
    return BIG_ZERO;
  }, [accountBalanceMap, tokenInId, tokenIn.decimals]);

  const tokenOutBalance = useMemo(() => {
    if (accountBalanceMap?.[tokenOutId]) {
      return new BigNumber(
        formatBalance(accountBalanceMap[tokenOutId], tokenOut.decimals),
      );
    }
    return BIG_ZERO;
  }, [accountBalanceMap, tokenOutId, tokenOut.decimals]);

  const { data: prices, isLoading: isLoadingPrices } = useTokenPrices(
    [tokenInId, tokenOutId],
    { refetchInterval: 10_000 },
  );
  const tokenInPrice = useMemo(() => {
    return prices?.[tokenInId] ?? 0;
  }, [prices, tokenInId]);
  const tokenOutPrice = useMemo(() => {
    return prices?.[tokenOutId] ?? 0;
  }, [prices, tokenOutId]);
  const marketRate = useMemo(() => {
    if (tokenInPrice === 0 || tokenOutPrice === 0) {
      return new BigNumber(0);
    }
    return new BigNumber(tokenInPrice).dividedBy(tokenOutPrice);
  }, [tokenInPrice, tokenOutPrice]);

  const amountInUsdValue = useMemo(() => {
    if (!amountIn) {
      return BIG_ZERO;
    }
    return new BigNumber(amountIn).multipliedBy(tokenInPrice);
  }, [amountIn, tokenInPrice]);

  const intervalValue = useAtomValue(dcaIntervalValueAtom);
  const intervalTs = useAtomValue(dcaIntervalTsAtom);
  const ordersNum = useAtomValue(dcaOrdersNumAtom);
  const minPrice = useAtomValue(dcaMinPriceAtom);
  const maxPrice = useAtomValue(dcaMaxPriceAtom);

  const decimalRateScale = useMemo(() => {
    if (!tokenIn || !tokenOut) {
      return new BigNumber(1);
    }
    return new BigNumber(10).pow(tokenIn?.decimals - tokenOut?.decimals);
  }, [tokenIn, tokenOut]);
  const dcaMaxRate = useMemo(() => {
    return new BigNumber(DCA_MAX_RATE)
      .multipliedBy(decimalRateScale)
      .dividedBy(RATE_SCALE.toString());
  }, [decimalRateScale]);

  const amountInPerOrderUsdValue = useMemo(() => {
    if (!amountIn || !ordersNum) {
      return BIG_ZERO;
    }
    return amountInUsdValue.dividedBy(ordersNum);
  }, [amountIn, ordersNum, amountInUsdValue]);

  const ordersNumError = useMemo(() => {
    if (!ordersNum || Math.floor(Number(ordersNum)) <= 0) {
      return "Number of orders must be at least 1";
    }
    return "";
  }, [ordersNum]);
  const minPerOrderValueError = useMemo(() => {
    if (amountInPerOrderUsdValue.lt(DCA_MIN_PER_ORDER_USD_VALUE)) {
      return `Amount per order must be at least $${formatAmount(
        DCA_MIN_PER_ORDER_USD_VALUE,
      )}`;
    }
    return "";
  }, [amountInPerOrderUsdValue]);
  const intervalValueError = useMemo(() => {
    if (!intervalValue || Math.floor(Number(intervalValue)) <= 0) {
      return "Interval must be greater than 0";
    }
    if (intervalTs < DCA_MIN_INTERVAL_TS) {
      return "Interval must be at least 15 minutes";
    }
    if (intervalTs > LO_DCA_MAX_EXPIRE_TS) {
      return "Interval must not exceed 30 days";
    }
    return "";
  }, [intervalValue, intervalTs]);
  const priceRangeError = useMemo(() => {
    if (minPrice && new BigNumber(minPrice).lt(0)) {
      return "Min price must be greater than or equal to 0";
    }
    if (maxPrice && new BigNumber(maxPrice).lt(0)) {
      return "Max price must be greater than or equal to 0";
    }
    if (minPrice && maxPrice && new BigNumber(minPrice).gt(maxPrice)) {
      return "Min price must be less than or equal to max price";
    }
    if (minPrice && new BigNumber(minPrice).gt(dcaMaxRate)) {
      return `Min price must not exceed ${formatAmount(dcaMaxRate)}`;
    }
    if (maxPrice && new BigNumber(maxPrice).gt(dcaMaxRate)) {
      return `Max price must not exceed ${formatAmount(dcaMaxRate)}`;
    }
    return "";
  }, [minPrice, maxPrice, dcaMaxRate]);

  const handleClickBalance = useCallback(() => {
    if (checkIsSui(tokenInId)) {
      setAmountIn(tokenInBalance.minus(MINIMUM_SUI_AMT).toString());
      return;
    }
    setAmountIn(tokenInBalance.toString());
  }, [tokenInBalance, setAmountIn, tokenInId]);

  const handleRevertTokens = useCallback(() => {
    const store = getDefaultStore();
    const tokenIn = store.get(agTokenOutAtom);
    const tokenOut = store.get(agTokenInAtom);
    store.set(agTokenInAtom, tokenIn);
    store.set(agTokenOutAtom, tokenOut);
    setSearchParams((prev) => {
      prev.set("from", tokenIn.type);
      prev.set("to", tokenOut.type);
      return prev;
    });
  }, [setSearchParams]);

  const currentAccount = useCurrentAccount();
  const isInsufficientBalance = useMemo(() => {
    if (!amountIn) {
      return false;
    }
    return tokenInBalance.isLessThan(amountIn);
  }, [amountIn, tokenInBalance]);

  const { mutate: placeDcaOrder, isPending } = usePlaceDcaOrderMutation();

  const canPlaceDcaOrder = useMemo(() => {
    const validTokens =
      tokenIn.type && tokenOut.type && tokenIn.type !== tokenOut.type;
    const validAmounts = new BigNumber(amountIn).gt(0);
    return (
      !!validTokens &&
      validAmounts &&
      !!currentAccount &&
      !isInsufficientBalance &&
      !isPending &&
      !ordersNumError &&
      !minPerOrderValueError &&
      !intervalValueError &&
      !priceRangeError
    );
  }, [
    tokenIn,
    tokenOut,
    amountIn,
    currentAccount,
    isInsufficientBalance,
    isPending,
    ordersNumError,
    minPerOrderValueError,
    intervalValueError,
    priceRangeError,
  ]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handlePlaceDcaOrder = useCallback(() => {
    if (!canPlaceDcaOrder) return;

    inputRef.current?.blur();

    const amount_in = formatAmount(amountIn);
    const token_in = tokenIn.symbol;
    const token_out = tokenOut.symbol;
    const orders_num = ordersNum;
    const intervalUnit = getDefaultStore().get(dcaIntervalUnitAtom);
    const interval = `${intervalValue} ${intervalUnit}(s)`;
    const min_price = getDefaultStore().get(dcaMinPriceAtom);
    const max_price = getDefaultStore().get(dcaMaxPriceAtom);

    ReactGa.event("dca", {
      amount_in,
      token_in,
      token_out,
      orders_num,
      interval,
      min_price,
      max_price,
    });

    placeDcaOrder(
      {
        txTitle: `Start DCA ${token_in} to buy ${token_out}`,
      },
      {
        onSuccess: () => {
          setAmountIn("");
          setTimeout(() => {
            refetchAccountBalances();
          }, 1_000);
          setTimeout(() => {
            refetchDcaOrders();
          }, 6_000);
        },
      },
    );
  }, [
    canPlaceDcaOrder,
    placeDcaOrder,
    setAmountIn,
    refetchAccountBalances,
    refetchDcaOrders,
    amountIn,
    tokenIn,
    tokenOut,
    ordersNum,
    intervalValue,
  ]);

  const actionButton = useMemo(() => {
    if (!currentAccount) {
      return (
        <ConnectModal
          trigger={
            <button
              className="flex items-center justify-center gap-2 p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white hover:bg-[#404862] active:bg-[#31384F]"
              onClick={() => {
                ReactGa.event("connect_wallet");
              }}
            >
              <ICWallet className="w-4 aspect-square" />
              <span className="text-lg/none">Connect Wallet</span>
            </button>
          }
        />
      );
    }

    if (!amountIn || !tokenIn) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">Enter an amount</span>
        </button>
      );
    }

    if (isInsufficientBalance) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">
            Insufficient {tokenIn.symbol} balance
          </span>
        </button>
      );
    }

    return (
      <button
        className="transition-all duration-200 relative overflow-hidden flex items-center justify-center p-4 rounded-2xl bg-iris-100 text-white font-cyberwayRiders text-[2rem]/none shadow-soft-3 shadow-[rgba(102,103,238,0.50)] hover:bg-[#6667EE] hover:shadow-[#6667EE] active:bg-[#3E40E3] active:shadow-none disabled:cursor-not-allowed disabled:opacity-60"
        onClick={handlePlaceDcaOrder}
        disabled={!canPlaceDcaOrder}
      >
        <span className="z-10">Confirm</span>
      </button>
    );
  }, [
    currentAccount,
    amountIn,
    tokenIn,
    isInsufficientBalance,
    canPlaceDcaOrder,
    handlePlaceDcaOrder,
  ]);

  return (
    <div className="flex flex-col gap-2 p-2 rounded-3xl bg-black-60">
      <div className="flex items-center justify-between gap-2.5">
        <TradeTabs />
        <div className="flex items-center gap-2">
          <SlippageDropdown />
        </div>
      </div>

      <div className="flex flex-col gap-0.5">
        <div className="flex flex-col p-1 rounded-2xl bg-black-80">
          <div className="flex items-center justify-between px-2 h-6 gap-2.5">
            <div className="flex items-center gap-0.5">
              <span className="text-xs/none font-light text-gray-100">
                I want to allocate
              </span>
            </div>
          </div>
          <div className="flex items-center gap-1">
            <NumericalInput
              className="flex-1 p-2 outline-none bg-transparent text-lg sm:text-2xl overflow-hidden grow"
              placeholder="0"
              value={amountIn}
              onUserInput={setAmountIn}
              precision={tokenIn?.decimals}
              onBlur={() => {
                setSearchParams((prev) => {
                  prev.set("amountIn", Number(amountIn) > 0 ? amountIn : "0");
                  return prev;
                });
              }}
              ref={inputRef}
            />
            <SelectTokenModal
              token={tokenIn}
              setToken={(token) => {
                if (token.type === tokenIn.type) return;
                setAmountIn("");
                setTokenIn(token);
              }}
              pivotTokenId={tokenOutId}
              accountBalanceMap={accountBalanceMap}
              type="from"
            />
          </div>
          <div className="flex items-center justify-between gap-2.5 p-2 rounded-xl">
            <TextAmt
              number={amountInUsdValue}
              className={tw(
                "text-gray-100 text-xs font-light",
                (!amountIn || isLoadingPrices) && "invisible",
              )}
              prefix="~ $"
            />
            {tokenInBalance.isGreaterThan(0) ? (
              <button className="font-normal" onClick={handleClickBalance}>
                <span className="text-gray-100">Balance: </span>
                <TextAmt number={tokenInBalance} className="text-green-80" />
              </button>
            ) : (
              <span className="font-normal">
                <span className="text-gray-100">Balance: </span>
                <span className="text-gray-100">0</span>
              </span>
            )}
          </div>
        </div>

        <button
          className="flex items-center justify-center m-auto p-1.5 bg-[#252734] text-gray-100 rounded-[0.625rem] border-2 border-black-80 my-[-1.0625rem] z-[2]"
          onClick={handleRevertTokens}
        >
          <ICFrame className="w-4 aspect-square" />
        </button>

        <div className="flex flex-col p-1 rounded-2xl bg-black-80">
          <div className="flex items-center justify-between px-2 h-6 gap-2.5">
            <div className="flex items-center gap-0.5">
              <span className="text-xs/none font-light text-gray-100">
                To buy
              </span>
            </div>
          </div>
          <div className="flex items-center gap-1">
            <SelectTokenModal
              token={tokenOut}
              setToken={setTokenOut}
              pivotTokenId={tokenInId}
              accountBalanceMap={accountBalanceMap}
              type="to"
              triggerClassName="w-full flex items-center justify-between"
            />
          </div>
          <div className="flex items-center justify-end p-2 rounded-xl">
            <span className="font-normal">
              <span className="text-gray-100">Balance: </span>
              <TextAmt number={tokenOutBalance} className="text-gray-100" />
            </span>
          </div>
        </div>

        {amountIn &&
          amountInUsdValue.gte(0) &&
          !ordersNumError &&
          !intervalValueError &&
          minPerOrderValueError && (
            <span className="p-2 text-xs/none text-red-80">
              {minPerOrderValueError}
            </span>
          )}

        <SelectIntervalOrders />
        {intervalValueError && (
          <span className="p-2 text-xs/none text-red-80">
            {intervalValueError}
          </span>
        )}
        {!intervalValueError && ordersNumError && (
          <span className="p-2 text-xs/none text-red-80">{ordersNumError}</span>
        )}
      </div>

      <PriceRangeInput />
      {priceRangeError && (
        <span className="p-2 text-xs/none text-red-80">{priceRangeError}</span>
      )}

      {actionButton}

      <OrderInfo marketRate={marketRate} />
    </div>
  );
}

export default DcaForm;
