import { fullTokenMapAtom } from "@/atoms/token.atom";
import { DcaOrder, LoDcaOrderStatus } from "@/types/limitDca";
import { TokenDetails } from "@/types/token";
import { DCA_MAX_RATE, RATE_SCALE } from "@/utils/limitDca/constants";
import { formatBalance } from "@/utils/number";
import BigNumber from "bignumber.js";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

export interface DcaOrderItem {
  orderId: string;
  payCoinName: string;
  targetCoinName: string;
  tokenIn: TokenDetails;
  tokenOut: TokenDetails;
  amountIn: BigNumber;
  minPrice: BigNumber;
  minPriceInverted: BigNumber;
  maxPrice: BigNumber;
  maxPriceInverted: BigNumber;
  status: LoDcaOrderStatus;
  amountPerOrder: BigNumber;
  totalNumOrders: BigNumber;
  filledNumOrders: BigNumber;
  interval: number;
  digest: string;
}

const useDcaOrderItems = (orders: DcaOrder[]): DcaOrderItem[] => {
  const fullTokenMap = useAtomValue(fullTokenMapAtom);

  const items = useMemo(() => {
    return orders
      .filter((order) => {
        const tokenIn = fullTokenMap[order.payCoinName];
        const tokenOut = fullTokenMap[order.targetCoinName];
        return tokenIn && tokenOut;
      })
      .map((order) => {
        const tokenIn = fullTokenMap[order.payCoinName];
        const tokenOut = fullTokenMap[order.targetCoinName];
        const amountIn = formatBalance(
          order.totalPayAmount,
          tokenIn?.decimals ?? 0,
        );
        const decimalRateScale = new BigNumber(10).pow(
          tokenIn?.decimals - tokenOut?.decimals,
        );
        const minPrice = new BigNumber(order.minRate)
          .multipliedBy(decimalRateScale)
          .dividedBy(RATE_SCALE.toString());
        const minPriceInverted = minPrice.isZero()
          ? new BigNumber(0)
          : new BigNumber(1).dividedBy(minPrice);
        const isMaxRate = new BigNumber(order.maxRate).eq(DCA_MAX_RATE);
        const maxPrice = isMaxRate
          ? new BigNumber(-1)
          : new BigNumber(order.maxRate)
              .multipliedBy(decimalRateScale)
              .dividedBy(RATE_SCALE.toString());
        const maxPriceInverted = new BigNumber(1).dividedBy(maxPrice);
        const amountPerOrder = formatBalance(
          order.amountPerOrder,
          tokenIn?.decimals ?? 0,
        );
        const totalNumOrders = new BigNumber(
          new BigNumber(order.totalPayAmount)
            .dividedBy(order.amountPerOrder ?? 1)
            .toFixed(0),
        );
        const unfilledNumOrders = new BigNumber(order.numOrders);
        const filledNumOrders = totalNumOrders.minus(unfilledNumOrders);

        return {
          orderId: order.orderId,
          payCoinName: order.payCoinName,
          targetCoinName: order.targetCoinName,
          tokenIn,
          tokenOut,
          amountIn,
          minPrice,
          minPriceInverted,
          maxPrice,
          maxPriceInverted,
          status: order.status,
          amountPerOrder,
          totalNumOrders,
          filledNumOrders,
          interval: order.interval,
          digest: order.digest ?? "",
        };
      });
  }, [orders, fullTokenMap]);

  return items;
};

export default useDcaOrderItems;
