import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { CoinBalance } from "@mysten/sui/client";
import { POPULAR_TOKENS } from "@/constants/tokens";
import { useAtomValue } from "jotai";
import { accountPriceMapAtom } from "@/atoms/account.atom";

function useSortWalletBalances(balances: CoinBalance[]): CoinBalance[] {
  const accountPriceMap = useAtomValue(accountPriceMapAtom);

  const sortedBalances = useMemo(() => {
    return balances.slice().sort((a, b) => {
      // Sort by usd balance
      const aUsd = new BigNumber(a.totalBalance).multipliedBy(
        accountPriceMap[a.coinType] ?? 0,
      );
      const bUsd = new BigNumber(b.totalBalance).multipliedBy(
        accountPriceMap[b.coinType] ?? 0,
      );
      if (aUsd.gt(0) && bUsd.gt(0)) {
        return bUsd.comparedTo(aUsd);
      }
      if (aUsd.gt(0)) {
        return -1;
      }
      if (bUsd.gt(0)) {
        return 1;
      }

      // Sort by popular tokens
      const aIndex = POPULAR_TOKENS.indexOf(a.coinType);
      const bIndex = POPULAR_TOKENS.indexOf(b.coinType);
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      if (aIndex !== -1) {
        return -1;
      }
      if (bIndex !== -1) {
        return 1;
      }

      // Sort by balance
      const aBalance = new BigNumber(a.totalBalance);
      const bBalance = new BigNumber(b.totalBalance);
      return bBalance.comparedTo(aBalance);
    });
  }, [balances, accountPriceMap]);

  return sortedBalances;
}

export default useSortWalletBalances;
