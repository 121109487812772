import tw from "@/utils/twmerge";
import { useAtom, useAtomValue } from "jotai";
import { loHistoryTabAtom, loOpenOrdersAtom } from "@/atoms/limitOrder.atom";
import { useMemo } from "react";
import { isMobileAtom } from "@/atoms/layout.atom";
import NumBadge from "@/pages/trade/common/NumBadge";

export const LIMIT_HISTORY_TABS = [
  {
    key: "open-orders",
    label: "Open Orders",
    shortLabel: "Orders",
  },
  {
    key: "history",
    label: "History",
    shortLabel: "History",
  },
] as const;

function LimitHistoryTabs() {
  const isMobile = useAtomValue(isMobileAtom);
  const [selectedTab, setSelectedTab] = useAtom(loHistoryTabAtom);
  const openOrders = useAtomValue(loOpenOrdersAtom);

  const openOrdersNum = useMemo(() => {
    return openOrders.length;
  }, [openOrders]);

  return (
    <div className="flex items-center gap-2 sm:gap-4 h-8">
      {LIMIT_HISTORY_TABS.map(({ key, label, shortLabel }) => {
        return (
          <button
            key={key}
            className={tw(
              "flex items-center justify-center px-3 h-full gap-2 transition-all rounded-lg text-sm/none sm:text-lg/none",
              key === selectedTab
                ? "bg-black-100 sm:bg-transparent text-white"
                : "bg-transparent text-gray-100",
            )}
            onClick={() => {
              setSelectedTab(key);
            }}
          >
            {isMobile ? shortLabel : label}
            {key === "open-orders" && openOrdersNum > 0 && (
              <NumBadge num={openOrdersNum} />
            )}
          </button>
        );
      })}
    </div>
  );
}

export default LimitHistoryTabs;
