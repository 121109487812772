import { ICChevronRight } from "@/assets/icons";
import { useCallback, useMemo, useState } from "react";
import Button from "@/components/UI/Button";
import * as Popover from "@radix-ui/react-popover";
import NumericalInput from "@/components/Input/NumericalInput";
import tw from "@/utils/twmerge";

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  maxVisiblePages?: number;
  className?: string;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  maxVisiblePages = 5,
  className,
}: Props) => {
  const [popoverPage, setPopoverPage] = useState("1");

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > 0 && newPage <= totalPages) {
        onPageChange(newPage);
        setPopoverPage(`${newPage}`);
      }
    },
    [onPageChange, totalPages],
  );

  const handleGotoPage = useCallback(() => {
    if (Number(popoverPage) > 0 && Number(popoverPage) <= totalPages) {
      onPageChange(Number(popoverPage));
      setPopoverPage(`${popoverPage}`);
    }
  }, [onPageChange, popoverPage, totalPages]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleGotoPage();
      }
    },
    [handleGotoPage],
  );

  const pageNumbers = useMemo((): number[] => {
    if (totalPages <= maxVisiblePages * 2) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const pageNumbers: number[] = [];
    for (let i = 1; i <= maxVisiblePages; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push(-1);
    for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }, [totalPages, maxVisiblePages]);

  return (
    <div
      className={tw(
        "flex items-center justify-center gap-2 rounded-md h-[4.5rem]",
        className,
      )}
    >
      <Button
        className="bg-skin-card disabled:bg-skin-alt hover:bg-gray-20 hover:border-transparent w-8 h-8 rounded-[0.625rem]"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ICChevronRight className="w-4 h-auto rotate-180" />
      </Button>

      {pageNumbers.map((page, index) =>
        page === -1 ? (
          <Popover.Root key={page}>
            <Popover.Trigger asChild>
              <Button
                key={index}
                className={tw(
                  "hover:border-transparent hover:bg-gray-20 w-8 h-8 rounded-[0.625rem]",
                  "w-[4.5rem]",
                  currentPage > maxVisiblePages &&
                    currentPage < totalPages - maxVisiblePages + 1
                    ? "bg-skin-alt"
                    : "bg-skin-card",
                )}
                innerClassName={tw(
                  "text-xs/none font-medium",
                  currentPage > maxVisiblePages &&
                    currentPage < totalPages - maxVisiblePages + 1
                    ? "text-white"
                    : "text-gray-100",
                )}
              >
                ...
              </Button>
            </Popover.Trigger>
            <Popover.Content className="flex flex-col p-6 gap-2 min-w-[9.5rem] bg-[#252734] rounded-2xl backdrop-blur-[2.25rem] z-[100]">
              <NumericalInput
                value={popoverPage}
                onUserInput={setPopoverPage}
                placeholder="0"
                onKeyDown={handleEnter}
                className="p-3 bg-black-100 rounded-xl text-sm/normal"
              />
              <Popover.Close asChild>
                <Button
                  size="sm"
                  className="h-12 rounded-[10px] hover:bg-gray-10 bg-gray-20 w-full"
                  innerClassName="flex gap-2.5 items-center justify-center text-gray-100"
                  onClick={handleGotoPage}
                >
                  Go to page
                </Button>
              </Popover.Close>
            </Popover.Content>
          </Popover.Root>
        ) : (
          <Button
            key={page}
            onClick={() => handlePageChange(page)}
            className={tw(
              "hover:border-transparent hover:bg-gray-20 w-8 h-8 rounded-[0.625rem]",
              page === currentPage ? "bg-skin-alt" : "bg-skin-card",
            )}
            innerClassName={tw(
              "text-xs/none font-medium",
              page === currentPage ? "text-white" : "text-gray-100",
            )}
          >
            {page}
          </Button>
        ),
      )}

      <Button
        className="bg-skin-card disabled:bg-skin-alt hover:bg-gray-20 hover:border-transparent w-8 h-8 rounded-[0.625rem]"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ICChevronRight className="w-4 h-auto" />
      </Button>
    </div>
  );
};

export default Pagination;
