import { useAtomValue } from "jotai";
import SwapHistoryList from "../../common/SwapHistoryList";
import { isMobileAtom } from "@/atoms/layout.atom";
import tw from "@/utils/twmerge";
import HidePairsCheckbox from "../../common/HidePairsCheckbox";
import RefreshSwapHistoryButton from "./RefreshSwapHistoryButton";

function SwapHistory() {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6 p-4 rounded-3xl border-[1.5px] border-[#222436] bg-black-80"
          : "gap-4 p-6 rounded-3xl border-[1.5px] border-[#222436] bg-black-80",
      )}
    >
      <div className="flex items-center justify-between gap-2">
        <h2 className="flex items-center justify-center px-3 h-8 text-lg/none">
          History
        </h2>
        <div className="flex items-center gap-2">
          <HidePairsCheckbox />
          <RefreshSwapHistoryButton />
        </div>
      </div>
      <SwapHistoryList />
    </div>
  );
}

export default SwapHistory;
