import EmptyTable from "@/components/EmptyData/EmptyData";
import Pagination from "@/components/Table/Pagination";
import Repeat from "@/components/UI/Repeat";
import { useAtomValue } from "jotai";
import { Fragment, useEffect, useMemo, useState } from "react";
import DcaHistoryHead from "./DcaHistoryHead";
import DcaHistoryRow, { DcaHistoryRowSkeleton } from "./DcaHistoryRow";
import { hideOtherPairsAtom } from "@/atoms/swapHistory.atom";
import { DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import { isMobileAtom } from "@/atoms/layout.atom";
import DcaHistoryItem, { DcaHistoryItemSkeleton } from "./DcaHistoryItem";
import { useCurrentAccount } from "@mysten/dapp-kit";
import useDcaOrderItems, { DcaOrderItem } from "./hooks/useDcaOrderItems";
import useAccountBalanceList from "@/hooks/accounts/useAccountBalanceList";
import CancelOrderModal from "./CancelOrderModal";
import useGetOpenDcaOrders from "@/hooks/limitDca/useGetOpenDcaOrders";
import {
  dcaIsRefetchingOpenOrdersAtom,
  dcaOpenOrdersAtom,
} from "@/atoms/dca.atom";
import useCancelDcaOrderMutation from "@/mutations/limitDca/useCancelDcaOrderMutation";
import DcaHistoryExpandableRow from "./DcaHistoryExpandableRow";

function DcaHistoryList() {
  const isMobile = useAtomValue(isMobileAtom);

  const isRefetching = useAtomValue(dcaIsRefetchingOpenOrdersAtom);

  const [selectedRowIds, setSelectedRowIds] = useState(new Set<string>());
  const [selectedOrder, setSelectedOrder] = useState<DcaOrderItem | null>(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const { refetch: refetchDcaOrders, isLoading } = useGetOpenDcaOrders({
    refetchInterval: 60_000,
  });
  const openOrders = useAtomValue(dcaOpenOrdersAtom);
  const openTransactions = useDcaOrderItems(openOrders);
  const hideOtherPairs = useAtomValue(hideOtherPairsAtom);

  const totalPages = useMemo(() => {
    return Math.ceil((openTransactions.length ?? 0) / DEFAULT_PAGE_SIZE);
  }, [openTransactions.length]);

  const displayedTransactions = useMemo(() => {
    return openTransactions.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [currentPage, openTransactions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [hideOtherPairs]);

  const currentAccount = useCurrentAccount();
  const emptyMessage = useMemo(() => {
    return currentAccount ? "No open orders" : "No data to display";
  }, [currentAccount]);

  const { refetch: refetchAccountBalances } = useAccountBalanceList();
  const { mutate: cancelDcaOrder } = useCancelDcaOrderMutation();

  const handleOpenCancelModal = (item: DcaOrderItem) => {
    setSelectedOrder(item);
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
    setSelectedOrder(null);
  };

  const handleCancelOrder = () => {
    if (!selectedOrder) return;

    handleCloseCancelModal();

    cancelDcaOrder(
      {
        txTitle: "Stop DCA Order",
        orderId: selectedOrder.orderId,
        payCoinType: selectedOrder.payCoinName,
        targetCoinType: selectedOrder.targetCoinName,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            refetchAccountBalances();
          }, 1_000);
          setTimeout(() => {
            refetchDcaOrders();
          }, 6_000);
        },
      },
    );
  };

  const handleToggleRow = (id: string) => {
    // only allow one row to be selected
    setSelectedRowIds((prev) => {
      if (prev.has(id)) {
        return new Set();
      }
      return new Set([id]);
    });
  };

  return (
    <div className="flex flex-col gap-1">
      {isMobile ? (
        <div className="flex flex-col gap-2">
          {isLoading || isRefetching ? (
            <Repeat count={5}>
              <DcaHistoryItemSkeleton />
            </Repeat>
          ) : (
            <>
              {displayedTransactions.map((tx) => (
                <DcaHistoryItem
                  key={tx.orderId}
                  data={tx}
                  handleOpenCancelModal={handleOpenCancelModal}
                />
              ))}
            </>
          )}
        </div>
      ) : (
        <table className="flex flex-col gap-1">
          <DcaHistoryHead />
          <tbody className="flex flex-col gap-1">
            {isLoading || isRefetching ? (
              <Repeat count={5}>
                <DcaHistoryRowSkeleton />
              </Repeat>
            ) : (
              <>
                {displayedTransactions.map((tx) => (
                  <Fragment key={tx.orderId}>
                    <DcaHistoryRow
                      data={tx}
                      selectedRowIds={selectedRowIds}
                      handleToggleRow={handleToggleRow}
                    />
                    <DcaHistoryExpandableRow
                      data={tx}
                      expanded={selectedRowIds.has(tx.orderId)}
                      handleOpenCancelModal={handleOpenCancelModal}
                    />
                  </Fragment>
                ))}
              </>
            )}
          </tbody>
        </table>
      )}
      {totalPages === 0 && !isLoading && !isRefetching && (
        <EmptyTable title={emptyMessage} />
      )}
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          maxVisiblePages={isMobile ? 2 : 5}
        />
      )}

      <CancelOrderModal
        open={openCancelModal}
        onOpenChange={setOpenCancelModal}
        onSubmit={handleCancelOrder}
      />
    </div>
  );
}

export default DcaHistoryList;
