import { LimitOrder } from "@/types/limitDca";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { LoDcaQueryConfig, LoDcaQueryParams } from "./types";
import { formatQueryParams } from "@/utils/url";

interface Params {
  key: string;
  params: LoDcaQueryParams;
  enabled?: boolean;
  config?: LoDcaQueryConfig;
}

const useGetLimitOrders = ({ key, params, enabled, config }: Params) => {
  const paramsStr = useMemo(() => {
    return formatQueryParams(params);
  }, [params]);

  return useQuery<LimitOrder[]>({
    queryKey: [key, paramsStr],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_LO_DCA_API}/limit-orders?${paramsStr}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch limit orders");
      }
      return response.json();
    },
    refetchInterval: config?.refetchInterval,
    enabled,
  });
};

export default useGetLimitOrders;
