import { useAtom, useAtomValue } from "jotai";
import { dcaMaxPriceAtom, dcaMinPriceAtom } from "@/atoms/dca.atom";
import NumericalInput from "@/components/Input/NumericalInput";
import { agTokenOutAtom } from "@/atoms/aggregator.atom";
import { LO_RATE_DECIMALS } from "@/constants/limitDca";

function PriceRangeInput() {
  const [minPrice, setMinPrice] = useAtom(dcaMinPriceAtom);
  const [maxPrice, setMaxPrice] = useAtom(dcaMaxPriceAtom);
  const tokenOut = useAtomValue(agTokenOutAtom);

  return (
    <div className="flex flex-col gap-1 p-1 bg-[#2C3043] rounded-[1.25rem]">
      <div className="grid grid-cols-2 gap-0.5">
        <div className="col-span-1">
          <div className="flex flex-col p-1 bg-black-80 border border-black-80 rounded-2xl h-full">
            <div className="flex items-center justify-between px-2 h-6 gap-2.5">
              <div className="flex items-center gap-0.5">
                <span className="text-2xs/none font-light text-gray-100">
                  <span className="text-green-80">Min</span> price (optional)
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between gap-1">
              <NumericalInput
                className="outline-none bg-transparent text-sm/none overflow-hidden px-2 py-3 w-1/2 grow"
                placeholder="0"
                value={minPrice}
                onUserInput={setMinPrice}
                precision={LO_RATE_DECIMALS}
                min={0}
              />
              <span className="text-sm/none text-gray-100 px-2">
                {tokenOut.symbol}
              </span>
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <div className="flex flex-col p-1 bg-black-80 border border-black-80 rounded-2xl h-full">
            <div className="flex items-center justify-between px-2 h-6 gap-2.5">
              <div className="flex items-center gap-0.5">
                <span className="text-2xs/none font-light text-gray-100">
                  <span className="text-green-80">Max</span> price (optional)
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between gap-1">
              <NumericalInput
                className="outline-none bg-transparent text-sm/none overflow-hidden px-2 py-3 w-1/2 grow"
                placeholder="0"
                value={maxPrice}
                onUserInput={setMaxPrice}
                precision={LO_RATE_DECIMALS}
                min={0}
              />
              <span className="text-sm/none text-gray-100 px-2">
                {tokenOut.symbol}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceRangeInput;
