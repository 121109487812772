import { ICLoadingRing } from "@/assets/icons";
import { agTokenInAtom, agTokenOutAtom } from "@/atoms/aggregator.atom";
import { loRateInputAtom, loRateModeAtom } from "@/atoms/limitOrder.atom";
import NumericalInput from "@/components/Input/NumericalInput";
import TextAmt from "@/components/TextAmt";
import { LO_RATE_DECIMALS } from "@/constants/limitDca";
import PriceModeBtn, { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import tw from "@/utils/twmerge";
import BigNumber from "bignumber.js";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

const DIFF_RATE_THRESHOLD = 0.01;

interface Props {
  marketRate: BigNumber;
  diffRatePct: BigNumber;
  tokenOutPrice: number;
  isLoading?: boolean;
}

function RateInput({
  marketRate,
  diffRatePct,
  tokenOutPrice,
  isLoading,
}: Props) {
  const tokenIn = useAtomValue(agTokenInAtom);
  const tokenOut = useAtomValue(agTokenOutAtom);
  const [loRateInput, setLoRateInput] = useAtom(loRateInputAtom);
  const [loRateMode, setLoRateMode] = useAtom(loRateModeAtom);

  const [shouldResetLoRate, setShouldResetLoRate] = useState(false);

  const rateInUsdValue = useMemo(() => {
    return new BigNumber(loRateInput).multipliedBy(tokenOutPrice);
  }, [loRateInput, tokenOutPrice]);

  const handleChangePriceMode = (value: PriceMode) => {
    setLoRateMode(value);
    setLoRateInput(new BigNumber(1).div(loRateInput).toFixed(LO_RATE_DECIMALS));
  };

  const diffPctElement = useMemo(() => {
    if (isLoading) {
      return (
        <ICLoadingRing className="shrink-0 w-3 h-3 text-iris-100 animate-spin" />
      );
    }

    if (diffRatePct.abs().lte(DIFF_RATE_THRESHOLD)) {
      return null;
    }

    if (diffRatePct.gt(10_000)) {
      return <span className="text-xs/none text-green-80">{"(>10,000%)"}</span>;
    }

    return (
      <TextAmt
        number={diffRatePct}
        precision={2}
        prefix={diffRatePct.gt(0) ? "(+" : "("}
        suffix="%)"
        className={tw(
          "text-xs/none",
          diffRatePct.gt(0) ? "text-green-80" : "text-red-80",
        )}
      />
    );
  }, [diffRatePct, isLoading]);

  useEffect(() => {
    setShouldResetLoRate(true);
  }, [tokenIn, tokenOut]);

  useEffect(() => {
    if (shouldResetLoRate && marketRate.gt(0)) {
      setLoRateInput(marketRate.toFixed(LO_RATE_DECIMALS));
      setShouldResetLoRate(false);
    }
  }, [shouldResetLoRate, setLoRateInput, marketRate, tokenIn]);

  return (
    <div className="flex flex-col p-1 bg-black-80 border border-black-80 rounded-2xl">
      <div className="flex-1 flex items-center justify-between px-2 min-h-[1.125rem] gap-2">
        <div className="flex-1 flex items-center gap-0.5 truncate">
          <span className="text-2xs/none font-light text-gray-100 truncate">
            {loRateMode === "in-out"
              ? `Buy ${tokenOut.symbol}`
              : `Sell ${tokenIn.symbol}`}{" "}
            at rate
          </span>
          <span className="flex-1">{diffPctElement}</span>
        </div>
        <button
          className="w-16 h-full flex items-center justify-end font-normal text-xs/none text-iris-100 hover:text-green-80"
          onClick={() => setLoRateInput(marketRate.toFixed(LO_RATE_DECIMALS))}
        >
          Use Market
        </button>
      </div>
      <div className="flex items-center justify-between gap-0.5 pr-2 h-9">
        <NumericalInput
          className="flex-1 p-2 outline-none bg-transparent text-lg sm:text-2xl overflow-hidden grow"
          placeholder="0"
          value={loRateInput}
          onUserInput={setLoRateInput}
          precision={LO_RATE_DECIMALS}
          min={0}
        />
        <PriceModeBtn
          tokenIn={tokenIn.symbol ?? ""}
          tokenOut={tokenOut.symbol ?? ""}
          value={loRateMode}
          onChange={handleChangePriceMode}
        />
      </div>
      <div className="h-6 px-2">
        <TextAmt
          number={rateInUsdValue}
          className={tw(
            "text-gray-100 text-xs font-light",
            isLoading && "invisible",
          )}
          prefix="~ $"
        />
      </div>
    </div>
  );
}

export default RateInput;
