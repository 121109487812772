import { useAtom } from "jotai";
import { Checkbox } from "@/components/UI/Checkbox";
import { hideOtherPairsAtom } from "@/atoms/swapHistory.atom";

function HidePairsCheckbox() {
  const [hideOtherPairs, setHideOtherPairs] = useAtom(hideOtherPairsAtom);

  return (
    <label className="flex items-center gap-2 cursor-pointer">
      <Checkbox
        checked={hideOtherPairs}
        onCheckedChange={(checked: boolean) => setHideOtherPairs(checked)}
      />
      <span className="text-xs/none">Hide Other Pairs</span>
    </label>
  );
}

export default HidePairsCheckbox;
