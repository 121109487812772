import { useCallback, useMemo, useState } from "react";
import Accordion, {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/UI/Accordion";
import { ICInfoCircle } from "@/assets/icons";
import TextAmt from "@/components/TextAmt";
import tw from "@/utils/twmerge";
import { useAtomValue } from "jotai";
import OrderItem from "../../../common/OrderInfo/OrderItem";
import PriceButton from "../../../common/OrderInfo/PriceButton";
import BigNumber from "bignumber.js";
import { TokenDetails } from "@/types/token";
import { loRateValueAtom } from "@/atoms/limitOrder.atom";
import { LO_PLATFORM_FEE } from "@/constants/limitDca";
import useExpiryInfo from "../hooks/useExpiryInfo";

type PriceMode = "in-out" | "out-in";

interface Props {
  tokenIn: TokenDetails;
  tokenOut: TokenDetails;
  amountIn: BigNumber;
  amountOut: BigNumber;
}

function OrderInfo({ tokenIn, tokenOut, amountIn, amountOut }: Props) {
  const [openAccordion, setOpenAccordion] = useState("");
  const [priceMode, setPriceMode] = useState<PriceMode>("in-out");
  const loRateValue = useAtomValue(loRateValueAtom);
  const { selectedLabel: expiryLabel } = useExpiryInfo();

  const handleChangePriceMode = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
      setPriceMode((prev) => (prev === "in-out" ? "out-in" : "in-out"));
    },
    [],
  );

  const priceElement = useMemo(() => {
    return priceMode === "in-out" ? (
      <span>
        1 {tokenIn.symbol} ={" "}
        {loRateValue.gt(0) ? <TextAmt number={loRateValue} /> : "-"}{" "}
        {tokenOut.symbol}
      </span>
    ) : (
      <span>
        1 {tokenOut.symbol} ={" "}
        {loRateValue.gt(0) ? (
          <TextAmt number={new BigNumber(1).dividedBy(loRateValue)} />
        ) : (
          "-"
        )}{" "}
        {tokenIn.symbol}
      </span>
    );
  }, [priceMode, tokenIn.symbol, tokenOut.symbol, loRateValue]);

  const trigger = useMemo(() => {
    if (openAccordion) {
      return (
        <div className="font-semibold text-white text-lg/none">
          Order details
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2">
        <ICInfoCircle className="w-3 aspect-square" />
        <PriceButton
          className="text-white text-xs/none font-normal"
          onClick={handleChangePriceMode}
        >
          {priceElement}
        </PriceButton>
      </div>
    );
  }, [openAccordion, priceElement, handleChangePriceMode]);

  return (
    <Accordion
      type="single"
      collapsible
      value={openAccordion}
      onValueChange={(val) => setOpenAccordion(val)}
    >
      <AccordionItem
        value="default"
        className="border border-darkblue-100 bg-darkblue-100"
      >
        <AccordionTrigger
          className={tw(openAccordion ? "px-6 py-4" : "p-4")}
          iconClassName="w-3 aspect-square"
        >
          {trigger}
        </AccordionTrigger>
        <div className="transition-all duration-300 ease-in-out">
          {openAccordion && (
            <AccordionContent forceMount collapsedAnimate={false}>
              <div className="flex flex-col gap-4 px-6 pb-6 pt-0">
                <div className="h-px bg-[#707585]" />
                <OrderItem
                  label={<span>Price</span>}
                  value={
                    <PriceButton onClick={handleChangePriceMode}>
                      {priceElement}
                    </PriceButton>
                  }
                />
                <OrderItem
                  label="Sell Order"
                  value={
                    <span>
                      <TextAmt number={amountIn} /> {tokenIn.symbol}
                    </span>
                  }
                />
                <OrderItem
                  label="To Buy"
                  value={
                    <span>
                      <TextAmt number={amountOut} /> {tokenOut.symbol}
                    </span>
                  }
                />
                <div className="h-px bg-[#707585]" />
                <OrderItem
                  label="Expiry"
                  value={<span className="text-[#30EE00]">{expiryLabel}</span>}
                />
                <OrderItem
                  label="Platform Fee"
                  value={
                    <span>
                      <TextAmt
                        number={LO_PLATFORM_FEE.toBigNumber().multipliedBy(100)}
                        suffix="%"
                      />
                    </span>
                  }
                />
              </div>
            </AccordionContent>
          )}
        </div>
      </AccordionItem>
    </Accordion>
  );
}

export default OrderInfo;
