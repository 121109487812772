import { memo, useCallback } from "react";
import tw from "@/utils/twmerge";
import { ICRevert } from "@/assets/icons";

export type PriceMode = "in-out" | "out-in";

interface Props {
  tokenIn: string;
  tokenOut: string;
  value: PriceMode;
  onChange: (value: PriceMode) => void;
  className?: string;
  iconClassName?: string;
}

function PriceModeBtn({
  tokenIn,
  tokenOut,
  value,
  onChange,
  className,
  iconClassName,
}: Props) {
  const handleChangePriceMode = useCallback(() => {
    onChange(value === "in-out" ? "out-in" : "in-out");
  }, [onChange, value]);

  return (
    <button
      className={tw(
        "h-5 flex items-center p-1 gap-1 text-2xs/none bg-black-60 hover:bg-[#383D4C] rounded",
        className,
      )}
      onClick={handleChangePriceMode}
    >
      {value === "in-out" ? tokenIn : tokenOut}
      <ICRevert className={tw("w-2.5 h-2.5 text-gray-100", iconClassName)} />
    </button>
  );
}

export default memo(PriceModeBtn);
