import { ICFrame, ICWallet } from "@/assets/icons";
import {
  agAmountInAtom,
  agTokenInAtom,
  agTokenOutAtom,
} from "@/atoms/aggregator.atom";
import TextAmt from "@/components/TextAmt";
import { BIG_ZERO, MINIMUM_SUI_AMT } from "@/constants/amount";
import useAccountBalanceList from "@/hooks/accounts/useAccountBalanceList";
import { formatBalance } from "@/utils/number";
import { checkIsSui } from "@/utils/token";
import tw from "@/utils/twmerge";
import { formatAmount, formatToSignificant } from "@bicarus/utils";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import BigNumber from "bignumber.js";
import { getDefaultStore, useAtom, useAtomValue } from "jotai";
import { useCallback, useMemo, useRef } from "react";
import OrderInfo from "./OrderInfo";
import NumericalInput from "@/components/Input/NumericalInput";
import { accountBalanceMapAtom } from "@/atoms/account.atom";
import ReactGa from "@/utils/ga";
import useTokenPrices from "@/hooks/prices/useTokenPrices";
import { Link, useSearchParams } from "react-router-dom";
import TradeTabs from "../../common/TradeTabs";
import SelectTokenModal from "../../common/SelectTokenModal";
import RateInput from "./RateInput";
import {
  loRateInputAtom,
  loRateModeAtom,
  loRateValueAtom,
} from "@/atoms/limitOrder.atom";
import SelectExpiry from "./SelectExpiry";
import usePlaceLimitOrderMutation from "@/mutations/limitDca/usePlaceLimitOrderMutation";
import SlippageDropdown from "../../common/SlippageDropdown";
import useGetOpenLimitOrders from "@/hooks/limitDca/useGetOpenLimitOrders";
import { LO_MIN_USD_VALUE } from "@/constants/limitDca";

const DIFF_RATE_WARNING_THRESHOLD = 0.5;

function LimitOrderForm() {
  const loRateValue = useAtomValue(loRateValueAtom);
  const loRateInput = useAtomValue(loRateInputAtom);
  const loRateMode = useAtomValue(loRateModeAtom);
  const isBuy = useMemo(() => loRateMode === "in-out", [loRateMode]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [tokenIn, setTokenIn] = useAtom(agTokenInAtom);
  const [tokenOut, setTokenOut] = useAtom(agTokenOutAtom);
  const [amountIn, setAmountIn] = useAtom(agAmountInAtom);

  const tokenInId = useMemo(() => tokenIn?.type, [tokenIn]);
  const tokenOutId = useMemo(() => tokenOut?.type, [tokenOut]);

  const accountBalanceMap = useAtomValue(accountBalanceMapAtom);
  const { refetch: refetchAccountBalances } = useAccountBalanceList();

  const { refetch: refetchLimitOrders } = useGetOpenLimitOrders();

  const tokenInBalance = useMemo(() => {
    if (accountBalanceMap?.[tokenInId]) {
      return new BigNumber(
        formatBalance(accountBalanceMap[tokenInId], tokenIn.decimals),
      );
    }
    return BIG_ZERO;
  }, [accountBalanceMap, tokenInId, tokenIn.decimals]);

  const tokenOutBalance = useMemo(() => {
    if (accountBalanceMap?.[tokenOutId]) {
      return new BigNumber(
        formatBalance(accountBalanceMap[tokenOutId], tokenOut.decimals),
      );
    }
    return BIG_ZERO;
  }, [accountBalanceMap, tokenOutId, tokenOut.decimals]);

  const {
    data: prices,
    isLoading: isLoadingPrices,
    isRefetching: isRefetchingPrices,
  } = useTokenPrices([tokenInId, tokenOutId], { refetchInterval: 10_000 });
  const tokenInPrice = useMemo(() => {
    return prices?.[tokenInId] ?? 0;
  }, [prices, tokenInId]);
  const tokenOutPrice = useMemo(() => {
    return prices?.[tokenOutId] ?? 0;
  }, [prices, tokenOutId]);
  const marketRate = useMemo(() => {
    if (tokenInPrice === 0 || tokenOutPrice === 0) {
      return new BigNumber(0);
    }
    return isBuy
      ? new BigNumber(tokenOutPrice).dividedBy(tokenInPrice)
      : new BigNumber(tokenInPrice).dividedBy(tokenOutPrice);
  }, [isBuy, tokenInPrice, tokenOutPrice]);

  const amountOut = useMemo(() => {
    if (!amountIn || loRateValue.isZero()) {
      return BIG_ZERO;
    }
    return new BigNumber(amountIn).multipliedBy(loRateValue);
  }, [amountIn, loRateValue]);

  const amountOutDisplay = useMemo(() => {
    return formatToSignificant(amountOut, 10);
  }, [amountOut]);

  const amountInUsdValue = useMemo(() => {
    if (!amountIn) {
      return BIG_ZERO;
    }
    return new BigNumber(amountIn).multipliedBy(tokenInPrice);
  }, [amountIn, tokenInPrice]);
  const amountOutUsdValue = useMemo(() => {
    return amountOut.multipliedBy(tokenOutPrice);
  }, [amountOut, tokenOutPrice]);

  const handleClickBalance = useCallback(() => {
    if (checkIsSui(tokenInId)) {
      setAmountIn(tokenInBalance.minus(MINIMUM_SUI_AMT).toString());
      return;
    }
    setAmountIn(tokenInBalance.toString());
  }, [tokenInBalance, setAmountIn, tokenInId]);

  const handleRevertTokens = useCallback(() => {
    const store = getDefaultStore();
    const tokenIn = store.get(agTokenOutAtom);
    const tokenOut = store.get(agTokenInAtom);
    store.set(agTokenInAtom, tokenIn);
    store.set(agTokenOutAtom, tokenOut);
    setSearchParams((prev) => {
      prev.set("from", tokenIn.type);
      prev.set("to", tokenOut.type);
      return prev;
    });
  }, [setSearchParams]);

  const currentAccount = useCurrentAccount();
  const isInsufficientBalance = useMemo(() => {
    if (!amountIn) {
      return false;
    }
    return tokenInBalance.isLessThan(amountIn);
  }, [amountIn, tokenInBalance]);

  const { mutate: placeLimitOrder, isPending } = usePlaceLimitOrderMutation();

  const diffRatePct = useMemo(() => {
    const loRateNum = new BigNumber(loRateInput);
    if (loRateNum.isZero() || marketRate.isZero()) {
      return new BigNumber(0);
    }
    return isBuy
      ? new BigNumber(loRateNum.minus(marketRate))
          .dividedBy(marketRate)
          .multipliedBy(100)
      : loRateNum.dividedBy(marketRate).minus(1).multipliedBy(100);
  }, [isBuy, marketRate, loRateInput]);

  const isShowDiffRateWarning = useMemo(() => {
    return isBuy
      ? diffRatePct.gt(DIFF_RATE_WARNING_THRESHOLD)
      : diffRatePct.lt(-DIFF_RATE_WARNING_THRESHOLD);
  }, [diffRatePct, isBuy]);

  const minOrderValueError = useMemo(() => {
    if (amountInUsdValue.isLessThan(LO_MIN_USD_VALUE)) {
      return `Minimum order value is $${LO_MIN_USD_VALUE}`;
    }
    return "";
  }, [amountInUsdValue]);

  const canPlaceOrder = useMemo(() => {
    const validTokens =
      tokenIn.type && tokenOut.type && tokenIn.type !== tokenOut.type;
    const validAmounts =
      new BigNumber(amountIn).gt(0) && new BigNumber(amountOut).gt(0);
    return (
      !!validTokens &&
      validAmounts &&
      !!currentAccount &&
      !isInsufficientBalance &&
      !isPending &&
      !isShowDiffRateWarning &&
      !minOrderValueError
    );
  }, [
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    currentAccount,
    isInsufficientBalance,
    isPending,
    isShowDiffRateWarning,
    minOrderValueError,
  ]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handlePlaceOrder = useCallback(() => {
    if (!canPlaceOrder) return;

    inputRef.current?.blur();

    const amount_in = formatAmount(amountIn);
    const token_in = tokenIn.symbol;
    const amount_out = formatAmount(amountOut);
    const token_out = tokenOut.symbol;
    const rate = loRateValue;

    ReactGa.event("limit-order", {
      amount_in,
      token_in,
      amount_out,
      token_out,
      rate,
    });

    placeLimitOrder(
      {
        txTitle: `Place limit order to swap ${amount_in} ${token_in} for ${amount_out} ${token_out}`,
      },
      {
        onSuccess: () => {
          setAmountIn("");
          setTimeout(() => {
            refetchAccountBalances();
          }, 1_000);
          setTimeout(() => {
            refetchLimitOrders();
          }, 6_000);
        },
      },
    );
  }, [
    canPlaceOrder,
    placeLimitOrder,
    setAmountIn,
    refetchAccountBalances,
    refetchLimitOrders,
    amountIn,
    tokenIn,
    amountOut,
    tokenOut,
    loRateValue,
  ]);

  const actionButton = useMemo(() => {
    if (!currentAccount) {
      return (
        <ConnectModal
          trigger={
            <button
              className="flex items-center justify-center gap-2 p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white hover:bg-[#404862] active:bg-[#31384F]"
              onClick={() => {
                ReactGa.event("connect_wallet");
              }}
            >
              <ICWallet className="w-4 aspect-square" />
              <span className="text-lg/none">Connect Wallet</span>
            </button>
          }
        />
      );
    }

    if (!amountIn || !tokenIn) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">Enter an amount</span>
        </button>
      );
    }

    if (isInsufficientBalance) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">
            Insufficient {tokenIn.symbol} balance
          </span>
        </button>
      );
    }

    if (minOrderValueError) {
      return (
        <button
          className="flex items-center justify-center p-4 rounded-2xl bg-darkblue-100 h-[4.25rem] text-white disabled:cursor-not-allowed disabled:opacity-60"
          disabled
        >
          <span className="text-lg/none">{minOrderValueError}</span>
        </button>
      );
    }

    return (
      <button
        className="transition-all duration-200 relative overflow-hidden flex items-center justify-center p-4 rounded-2xl bg-iris-100 text-white font-cyberwayRiders text-[2rem]/none shadow-soft-3 shadow-[rgba(102,103,238,0.50)] hover:bg-[#6667EE] hover:shadow-[#6667EE] active:bg-[#3E40E3] active:shadow-none disabled:cursor-not-allowed disabled:opacity-60"
        onClick={handlePlaceOrder}
        disabled={!canPlaceOrder}
      >
        <span className="z-10">Place Order</span>
      </button>
    );
  }, [
    currentAccount,
    amountIn,
    tokenIn,
    isInsufficientBalance,
    minOrderValueError,
    canPlaceOrder,
    handlePlaceOrder,
  ]);

  const isInvalidAmountOut = useMemo(() => {
    return new BigNumber(amountOut).lte(0);
  }, [amountOut]);

  return (
    <div className="flex flex-col gap-2 p-2 rounded-3xl bg-black-60">
      <div className="flex items-center justify-between gap-2.5">
        <TradeTabs />
        <div className="flex items-center gap-2">
          <SlippageDropdown />
        </div>
      </div>

      <div className="flex flex-col gap-0.5">
        <div className="flex flex-col gap-6 p-1 rounded-2xl bg-black-80">
          <div className="flex items-center gap-1">
            <NumericalInput
              className="flex-1 p-2 outline-none bg-transparent text-lg sm:text-2xl overflow-hidden grow"
              placeholder="0"
              value={amountIn}
              onUserInput={setAmountIn}
              precision={tokenIn?.decimals}
              onBlur={() => {
                setSearchParams((prev) => {
                  prev.set("amountIn", Number(amountIn) > 0 ? amountIn : "0");
                  return prev;
                });
              }}
              ref={inputRef}
            />
            <SelectTokenModal
              token={tokenIn}
              setToken={(token) => {
                if (token.type === tokenIn.type) return;
                setAmountIn("");
                setTokenIn(token);
              }}
              pivotTokenId={tokenOutId}
              accountBalanceMap={accountBalanceMap}
              type="from"
            />
          </div>
          <div className="flex items-center justify-between gap-2.5 p-2 rounded-xl">
            <TextAmt
              number={amountInUsdValue}
              className={tw(
                "text-gray-100 text-xs font-light",
                (!amountIn || isLoadingPrices) && "invisible",
              )}
              prefix="~ $"
            />
            {tokenInBalance.isGreaterThan(0) ? (
              <button className="font-normal" onClick={handleClickBalance}>
                <span className="text-gray-100">Balance: </span>
                <TextAmt number={tokenInBalance} className="text-green-80" />
              </button>
            ) : (
              <span className="font-normal">
                <span className="text-gray-100">Balance: </span>
                <span className="text-gray-100">0</span>
              </span>
            )}
          </div>
        </div>

        <button
          className="flex items-center justify-center m-auto p-1.5 bg-[#252734] text-gray-100 rounded-[0.625rem] border-2 border-black-80 my-[-1.0625rem] z-[2]"
          onClick={handleRevertTokens}
        >
          <ICFrame className="w-4 aspect-square" />
        </button>

        <div
          className={tw(
            "flex flex-col gap-6 p-1 rounded-2xl border",
            !isInvalidAmountOut ? "border-black-80" : "border-transparent",
          )}
        >
          <div className="flex items-center gap-1">
            <NumericalInput
              className={`transition-all duration-200 flex-1 p-2 outline-none bg-transparent text-lg sm:text-2xl overflow-hidden grow disabled:text-gray-100`}
              placeholder="0"
              disabled
              value={amountOutDisplay}
              precision={tokenOut?.decimals}
            />
            <SelectTokenModal
              token={tokenOut}
              setToken={setTokenOut}
              pivotTokenId={tokenInId}
              accountBalanceMap={accountBalanceMap}
              type="to"
            />
          </div>
          <div className="flex items-center justify-between gap-2.5 p-2 rounded-xl">
            <TextAmt
              number={amountOutUsdValue}
              className={tw(
                "text-gray-100 text-xs font-light",
                (isLoadingPrices || isInvalidAmountOut) && "invisible",
              )}
              prefix="~ $"
            />
            <span className="font-normal">
              <span className="text-gray-100">Balance: </span>
              <TextAmt number={tokenOutBalance} className="text-gray-100" />
            </span>
          </div>
        </div>

        <div className="grid grid-cols-9 gap-0.5">
          <div className="col-span-6">
            <RateInput
              marketRate={marketRate}
              diffRatePct={diffRatePct}
              tokenOutPrice={tokenOutPrice}
              isLoading={isLoadingPrices || isRefetchingPrices}
            />
          </div>
          <div className="col-span-3">
            <SelectExpiry />
          </div>
        </div>
      </div>

      {isShowDiffRateWarning && (
        <div className="flex items-center gap-2.5 p-4 rounded-xl border border-pink-100 bg-pink-100/20">
          <span className="text-white text-xs font-normal">
            Limit price is{" "}
            <TextAmt number={diffRatePct} precision={2} suffix="%" />{" "}
            {isBuy ? "higher" : "lower"} than market, you are{" "}
            {isBuy ? "buying" : "selling"} at a much{" "}
            {isBuy ? "lower" : "higher"} rate. We recommend that you use{" "}
            <Link
              target="_blank"
              to={`/trade/swap?${searchParams.toString()}`}
              className="text-green-80"
            >
              7K Swap
            </Link>{" "}
            instead.
          </span>
        </div>
      )}

      {actionButton}

      <OrderInfo
        tokenIn={tokenIn}
        tokenOut={tokenOut}
        amountIn={new BigNumber(amountIn)}
        amountOut={amountOut}
      />
    </div>
  );
}

export default LimitOrderForm;
