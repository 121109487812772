import InfoTabs from "./InfoTabs";
import { useAtomValue } from "jotai";
import { agInfoTabAtom, priceChartLoadedAtom } from "@/atoms/aggregator.atom";
import BatchSwapContent from "./BatchSwap/BatchSwapContent";
import PriceChart from "./PriceChart";
import tw from "@/utils/twmerge";
import { isMobileAtom } from "@/atoms/layout.atom";

function InfoTabsContainer() {
  const isMobile = useAtomValue(isMobileAtom);
  const activeTab = useAtomValue(agInfoTabAtom);
  const priceChartLoaded = useAtomValue(priceChartLoadedAtom);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6 p-4 rounded-3xl border-[1.5px] border-[#222436] bg-black-80"
          : "gap-2",
      )}
    >
      <InfoTabs />
      {activeTab === "routes" && (
        <div className="flex flex-col flex-1">
          <BatchSwapContent />
        </div>
      )}
      {priceChartLoaded && (
        <div
          className={tw(
            "flex flex-col flex-1",
            activeTab === "price-chart" ? "flex" : "hidden",
          )}
        >
          <PriceChart />
        </div>
      )}
    </div>
  );
}

export default InfoTabsContainer;
