import { accountIncentiveAtom } from "@/atoms/account.atom";
import { DropHistoryType, IncentivePoint } from "@/types/drop";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

const useAccountIncentivePoint = (
  account?: string,
  type: DropHistoryType = "all",
) => {
  const setIncentivePoint = useSetAtom(accountIncentiveAtom);
  const currentAccount = useCurrentAccount();
  const accountAddress = account ?? currentAccount?.address;

  const { data } = useQuery<IncentivePoint>({
    queryKey: ["getAccountIncentivePoint", accountAddress, type],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_STATISTIC_API}/point?user=${accountAddress}&point_type=${type}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch search tokens");
      }
      return await response.json();
    },
    enabled: !!accountAddress,
  });

  useEffect(() => {
    if (data) {
      setIncentivePoint(data);
    }
  }, [data, setIncentivePoint]);

  return data;
};

export default useAccountIncentivePoint;
