import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";
import tw from "@/utils/twmerge";
import DcaHistoryTabs from "./DcaHistoryTabs";
import DcaHistoryList from "./DcaHistoryList";
import HidePairsCheckbox from "../../common/HidePairsCheckbox";
import RefreshDcaHistoryButton from "./RefreshDcaHistoryButton";
import SwapHistoryList from "../../common/SwapHistoryList";
import RefreshSwapHistoryButton from "../../swap/SwapHistory/RefreshSwapHistoryButton";
import { dcaHistoryTabAtom } from "@/atoms/dca.atom";

function DcaHistoryTabsContainer() {
  const isMobile = useAtomValue(isMobileAtom);
  const activeTab = useAtomValue(dcaHistoryTabAtom);

  return (
    <div
      className={tw(
        "flex flex-col flex-1",
        isMobile
          ? "gap-6 p-4 rounded-3xl border-[1.5px] border-[#222436] bg-black-80"
          : "gap-4 p-6 rounded-3xl border-[1.5px] border-[#222436] bg-black-80",
      )}
    >
      <div
        className={tw(
          isMobile
            ? "flex flex-col gap-4"
            : "flex items-center justify-between gap-2",
        )}
      >
        <DcaHistoryTabs />
        <div className="flex items-center justify-between sm:justify-start gap-2">
          <HidePairsCheckbox />
          {activeTab === "dca" ? (
            <RefreshDcaHistoryButton />
          ) : (
            <RefreshSwapHistoryButton />
          )}
        </div>
      </div>
      {activeTab === "dca" && (
        <div className="flex flex-col flex-1">
          <DcaHistoryList />
        </div>
      )}
      {activeTab === "history" && (
        <div className="flex flex-col flex-1">
          <SwapHistoryList />
        </div>
      )}
    </div>
  );
}

export default DcaHistoryTabsContainer;
