import { memo, useMemo, useState } from "react";
import tw from "@/utils/twmerge";
import TextAmt from "@/components/TextAmt";
import PriceModeBtn, { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import { DcaOrderItem } from "../hooks/useDcaOrderItems";
import useDcaOrderItem from "../hooks/useDcaOrderItem";
import TokenAvatar from "@/components/Avatar/TokenAvatar";
import useGetOrderExecutionsByOrderId from "@/hooks/limitDca/useGetOrderExecutionsByOrderId";
import useDcaExecutionItems from "../DcaExecutionList/hooks/useDcaExecutionItems";
import DcaExecutionList from "../DcaExecutionList";
import { ScrollArea, ScrollBar } from "@/components/UI/ScrollArea";
import DcaOrderInfo from "../DcaOrderInfo";

interface Props {
  data: DcaOrderItem;
  handleOpenCancelModal: (item: DcaOrderItem) => void;
  onClick?: () => void;
  size?: "default" | "large";
}

function DcaHistoryContent({
  data,
  onClick,
  handleOpenCancelModal,
  size = "default",
}: Props) {
  const {
    orderId,
    tokenIn,
    tokenOut,
    minPrice,
    minPriceMode,
    setMinPriceMode,
    maxPrice,
    maxPriceMode,
    setMaxPriceMode,
    priceRange: [minPriceRange, maxPriceRange],
    priceRangeMode,
    setPriceRangeMode,
    amountPerOrder,
    filledNumOrders,
    totalNumOrders,
    intervalValue,
    intervalUnit,
  } = useDcaOrderItem({ data });

  const isLarge = useMemo(() => size === "large", [size]);

  const {
    data: executions,
    isLoading,
    isRefetching,
  } = useGetOrderExecutionsByOrderId({
    orderId,
    expanded: isLarge,
  });
  const {
    items: transactions,
    avgPrice,
    avgPriceInverted,
    totalSpent,
  } = useDcaExecutionItems(executions ?? []);

  const [priceMode, setPriceMode] = useState<PriceMode>("out-in");

  const price = useMemo(() => {
    return priceMode === "in-out" ? avgPriceInverted : avgPrice;
  }, [avgPrice, avgPriceInverted, priceMode]);

  if (isLarge) {
    return (
      <div className="flex flex-col gap-6 w-full">
        <div className="flex flex-col gap-4">
          <div className="flex flex-1 flex-col rounded-2xl bg-black-80 gap-4 p-4">
            {tokenOut && (
              <div className="grid grid-cols-2 gap-2">
                <div className="flex items-center gap-1">
                  <TokenAvatar token={tokenOut} className="w-4 h-4" />
                  <span className="font-bold text-xs/none">
                    {tokenOut.symbol}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-xs/none text-gray-100">Avg</span>
                  <div className="flex items-center gap-1">
                    <TextAmt
                      number={price}
                      className={tw("text-xs", price.gt(0) && "text-green-80")}
                      decimalClassName="text-gray-100"
                    />{" "}
                    <PriceModeBtn
                      tokenIn={tokenIn?.symbol || ""}
                      tokenOut={tokenOut?.symbol || ""}
                      value={priceMode}
                      onChange={setPriceMode}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="h-px bg-black-40" />
            <DcaOrderInfo
              data={data}
              handleOpenCancelModal={handleOpenCancelModal}
              avgPrice={avgPrice}
              avgPriceInverted={avgPriceInverted}
              totalSpent={totalSpent}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-2xs/none text-gray-100">
              Amount Each Order/ Over Orders/ Every
            </span>
            <div className="flex items-center rounded-xl bg-black-80 p-2">
              <div className="flex-1 grid grid-cols-7 gap-1">
                <div className="col-span-3 flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
                  {tokenIn && (
                    <div className="flex items-center gap-1">
                      <TokenAvatar token={tokenIn} className="w-3 h-3" />
                      <TextAmt number={amountPerOrder} className="text-xs" />
                      <span className="font-normal text-3xs text-gray-100">
                        {tokenIn.symbol}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-span-2 flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
                  <div className="flex items-center gap-2.5">
                    <TextAmt number={totalNumOrders} className="text-xs" />
                    <span className="flex items-center p-1 h-5 rounded bg-black-40 font-semibold text-3xs">
                      Orders
                    </span>
                  </div>
                </div>
                <div className="col-span-2 flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
                  <div className="flex items-center gap-2.5">
                    <TextAmt number={intervalValue} className="text-xs" />
                    <span className="flex items-center p-1 h-5 rounded bg-black-40 font-semibold text-3xs">
                      {intervalUnit}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-2xs/none text-gray-100">
              Min Price - Max Price
            </span>
            <div className="flex items-center rounded-xl bg-black-80 p-2">
              <div className="flex-1 grid grid-cols-2 gap-1">
                <div className="flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
                  <div className="flex items-center gap-1">
                    <TextAmt
                      number={minPrice}
                      className="text-xs"
                      decimalClassName="text-gray-100"
                    />{" "}
                    <div onClick={(e) => e.stopPropagation()}>
                      <PriceModeBtn
                        tokenIn={tokenIn?.symbol || ""}
                        tokenOut={tokenOut?.symbol || ""}
                        value={minPriceMode}
                        onChange={setMinPriceMode}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
                  <div className="flex items-center gap-1">
                    {maxPrice.eq(-1) ? (
                      <span>∞</span>
                    ) : (
                      <TextAmt
                        number={maxPrice}
                        className="text-xs"
                        decimalClassName="text-gray-100"
                      />
                    )}{" "}
                    <div onClick={(e) => e.stopPropagation()}>
                      <PriceModeBtn
                        tokenIn={tokenIn?.symbol || ""}
                        tokenOut={tokenOut?.symbol || ""}
                        value={maxPriceMode}
                        onChange={setMaxPriceMode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollArea className="w-full -mb-4 pb-4">
          <div className="min-w-[42rem]">
            <DcaExecutionList
              transactions={transactions}
              isLoading={isLoading}
              isRefetching={isRefetching}
            />
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    );
  }

  return (
    <a className="flex w-full" onClick={onClick}>
      <div className="flex flex-1 flex-col rounded-2xl bg-black-60 gap-4 p-4">
        {tokenOut && (
          <div className="grid grid-cols-5 gap-2">
            <div className="col-span-2 flex items-center gap-1">
              <TokenAvatar token={tokenOut} className="w-4 h-4" />
              <span className="font-bold text-xs/none">{tokenOut.symbol}</span>
            </div>

            <div className="col-span-3 flex flex-col items-start gap-4">
              <span className="font-normal text-2xs/none text-gray-100">
                Price Range
              </span>
              <span className="text-xs/none">
                <div className="flex items-center gap-1">
                  <TextAmt
                    number={minPriceRange}
                    className="text-xs/none"
                    decimalClassName="text-gray-100"
                  />
                  {" - "}
                  {maxPriceRange.eq(-1) ? (
                    <span>∞</span>
                  ) : (
                    <TextAmt
                      number={maxPriceRange}
                      className="text-xs"
                      decimalClassName="text-gray-100"
                    />
                  )}{" "}
                  <div onClick={(e) => e.stopPropagation()}>
                    <PriceModeBtn
                      tokenIn={tokenIn?.symbol || ""}
                      tokenOut={tokenOut?.symbol || ""}
                      value={priceRangeMode}
                      onChange={setPriceRangeMode}
                      className="bg-black-100"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
        )}

        <div className="h-px bg-black-40" />

        <div className="grid grid-cols-5 gap-2">
          <div className="col-span-2 flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Total Spent
            </span>
            <span className="text-xs/none">
              <div className="flex items-center gap-1">
                <TextAmt
                  number={totalSpent}
                  className="text-xs/none"
                  decimalClassName="text-gray-100"
                />{" "}
                <span className="font-normal text-3xs text-gray-100">
                  {tokenIn.symbol}
                </span>
              </div>
            </span>
          </div>

          <div className="col-span-3 flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Bought/Total
            </span>
            <span className="text-xs/none">
              <TextAmt
                number={filledNumOrders}
                className={tw(filledNumOrders.gt(0) && "text-green-80")}
              />
              /<TextAmt number={totalNumOrders} />
            </span>
          </div>
        </div>
      </div>
    </a>
  );
}

export default memo(DcaHistoryContent);
