import {
  TS_1_DAY,
  TS_1_HOUR,
  TS_1_MINUTE,
  TS_30_DAYS,
  TS_7_DAYS,
} from "@/constants/time";

export const EXPIRY_OPTIONS: Array<{ label: string; value: number }> = [
  {
    label: "10 Minutes",
    value: 10 * TS_1_MINUTE,
  },
  {
    label: "1 Hour",
    value: TS_1_HOUR,
  },
  {
    label: "1 Day",
    value: TS_1_DAY,
  },
  {
    label: "3 Days",
    value: 3 * TS_1_DAY,
  },
  {
    label: "7 Days",
    value: TS_7_DAYS,
  },
  {
    label: "30 Days",
    value: TS_30_DAYS,
  },
  {
    label: "Custom",
    value: -1,
  },
];
