import { useTxExecution } from "@/hooks/transactions/useTxExecution";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useMutation } from "@tanstack/react-query";
import useMutateTxStatusList from "@/hooks/transactions/useMutateTxStatusList";
import { suiClient } from "@/constants/suiClient";
import { Sentry } from "@/utils/sentry";
import CopyBtn from "@/components/CopyBtn";
import { ICCopy } from "@/assets/icons";
import { cancelDcaOrder } from "@/utils/limitDca";

const useCancelDcaOrderMutation = () => {
  const currentAccount = useCurrentAccount();
  const executeTx = useTxExecution();
  const mutateTxStatusList = useMutateTxStatusList();

  return useMutation({
    mutationFn: async ({
      txTitle,
      orderId,
      payCoinType,
      targetCoinType,
    }: {
      txTitle: string;
      orderId: string;
      payCoinType: string;
      targetCoinType: string;
    }) => {
      if (!currentAccount?.address) {
        mutateTxStatusList({
          status: "error",
          title: txTitle,
          message: "You need to connect your wallet!",
        });
        throw new Error("No account connected");
      }

      const accountAddress = currentAccount.address;

      const tx = await cancelDcaOrder({
        orderId,
        payCoinType,
        targetCoinType,
      });

      if (tx) {
        const { error } = await suiClient.devInspectTransactionBlock({
          sender: accountAddress,
          transactionBlock: tx,
        });

        if (error) {
          const errorId = Sentry.captureException(new Error(error));
          mutateTxStatusList({
            status: "error",
            title: txTitle,
            message: `Transaction simulation failure. Please try again in a few seconds or contact support. (ID: ${errorId})`,
            extraAction: (
              <CopyBtn text={`${errorId}: ${error}`} className="p-0.5">
                <ICCopy className="w-5 h-auto" />
              </CopyBtn>
            ),
          });
          throw new Error("Transaction simulation failure");
        }
      }

      return executeTx(tx, txTitle);
    },
  });
};

export default useCancelDcaOrderMutation;
