import { Outlet } from "react-router-dom";
import InfoTabsContainer from "./InfoTabsContainer";
import UnverifiedTokenWarning from "./UnverifiedTokenWarning";
import useTradeQueryParams from "../hooks/useTradeQueryParams";
import useTradeType from "../hooks/useTradeType";
import LimitHistoryTabsContainer from "../../limitOrder/LimitHistoryTabsContainer";
import DcaHistoryTabsContainer from "../../dca/DcaHistoryTabsContainer";
import SwapHistory from "../../swap/SwapHistory";

function TradeLayout() {
  useTradeQueryParams();

  const { isSwap, isLimit, isDca } = useTradeType();

  return (
    <>
      <div className="flex flex-col sm:flex-row w-full gap-2 px-6 sm:px-12 pb-24 sm:pb-12">
        <div className="max-w-[25.5rem] w-full">
          <Outlet />
        </div>
        <div className="flex flex-col gap-2 grow min-h-screen sm:min-h-[38.75rem]">
          <div className="flex flex-col min-h-screen sm:min-h-[38.75rem]">
            <InfoTabsContainer />
          </div>
          {isSwap && <SwapHistory />}
          {isLimit && <LimitHistoryTabsContainer />}
          {isDca && <DcaHistoryTabsContainer />}
        </div>
      </div>
      <UnverifiedTokenWarning />
    </>
  );
}

export default TradeLayout;
