import { AnimatePresence, m } from "framer-motion";
import { memo } from "react";
import { DcaOrderItem } from "../hooks/useDcaOrderItems";
import DcaOrderInfo from "../DcaOrderInfo";
import DcaExecutionList from "../DcaExecutionList";
import useDcaExecutionItems from "../DcaExecutionList/hooks/useDcaExecutionItems";
import useGetOrderExecutionsByOrderId from "@/hooks/limitDca/useGetOrderExecutionsByOrderId";

interface Props {
  data: DcaOrderItem;
  expanded: boolean;
  handleOpenCancelModal: (item: DcaOrderItem) => void;
}

function DcaHistoryExpandableRow({
  data: order,
  expanded,
  handleOpenCancelModal,
}: Props) {
  const { data, isLoading, isRefetching } = useGetOrderExecutionsByOrderId({
    orderId: order.orderId,
    expanded,
  });
  const {
    items: transactions,
    avgPrice,
    avgPriceInverted,
    totalSpent,
  } = useDcaExecutionItems(data ?? []);

  return (
    <AnimatePresence>
      {expanded && (
        <m.tr
          initial="closed"
          animate="open"
          exit="closed"
          variants={{
            open: {
              height: "auto",
              opacity: 1,
            },
            closed: {
              height: 0,
              opacity: 0,
            },
          }}
          transition={{ ease: "linear", duration: 0.2 }}
          className="flex items-center gap-2 p-4 rounded-2xl border border-[#222436] bg-black-80"
        >
          <td className="flex-1 grid grid-cols-9">
            <div className="col-span-7">
              <DcaExecutionList
                transactions={transactions}
                isLoading={isLoading}
                isRefetching={isRefetching}
              />
            </div>
            <div className="col-span-2">
              <DcaOrderInfo
                data={order}
                handleOpenCancelModal={handleOpenCancelModal}
                avgPrice={avgPrice}
                avgPriceInverted={avgPriceInverted}
                totalSpent={totalSpent}
              />
            </div>
          </td>
        </m.tr>
      )}
    </AnimatePresence>
  );
}

export default memo(DcaHistoryExpandableRow);
