import EmptyTable from "@/components/EmptyData/EmptyData";
import Pagination from "@/components/Table/Pagination";
import Repeat from "@/components/UI/Repeat";
import { ESort } from "@/types/data";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import LimitHistoryHead from "./LimitHistoryHead";
import LimitHistoryRow, { LimitHistoryRowSkeleton } from "./LimitHistoryRow";
import { hideOtherPairsAtom } from "@/atoms/swapHistory.atom";
import { DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import { isMobileAtom } from "@/atoms/layout.atom";
import LimitHistoryItem, { LimitHistoryItemSkeleton } from "./LimitHistoryItem";
import { useCurrentAccount } from "@mysten/dapp-kit";
import {
  loIsRefetchingOpenOrdersAtom,
  loOpenOrdersAtom,
  loOpenOrdersSortOptAtom,
} from "@/atoms/limitOrder.atom";
import useOpenOrderItems, { OpenOrderItem } from "./hooks/useOpenOrderItems";
import useCancelLimitOrderMutation from "@/mutations/limitDca/useCancelLimitOrderMutation";
import useAccountBalanceList from "@/hooks/accounts/useAccountBalanceList";
import useClaimExpiredLimitOrderMutation from "@/mutations/limitDca/useClaimExpiredLimitOrderMutation";
import CancelOrderModal from "./CancelOrderModal";
import ClaimOrderModal from "./ClaimOrderModal";
import useGetOpenLimitOrders from "@/hooks/limitDca/useGetOpenLimitOrders";

function LimitHistoryList() {
  const isMobile = useAtomValue(isMobileAtom);

  const isRefetching = useAtomValue(loIsRefetchingOpenOrdersAtom);

  const [selectedOrder, setSelectedOrder] = useState<OpenOrderItem | null>(
    null,
  );
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const sortOpts = useAtomValue(loOpenOrdersSortOptAtom);
  const sortOpt = useMemo(() => {
    return Object.entries(sortOpts)[0] || ["createdTs", ESort.DESC];
  }, [sortOpts]);
  useEffect(() => {
    setCurrentPage(1);
  }, [sortOpt]);

  const { refetch: refetchLimitOrders, isLoading } = useGetOpenLimitOrders({
    refetchInterval: 60_000,
  });
  const openOrders = useAtomValue(loOpenOrdersAtom);
  const openTransactions = useOpenOrderItems(openOrders);
  const hideOtherPairs = useAtomValue(hideOtherPairsAtom);

  const sortedTransactions = useMemo(() => {
    return [...openTransactions].sort((a, b) => {
      if (sortOpt[1] === ESort.DEFAULT) {
        return 0;
      }
      if (sortOpt[0] === "createdTs") {
        return sortOpt[1] === ESort.ASC
          ? a.createdTs - b.createdTs
          : b.createdTs - a.createdTs;
      }
      if (sortOpt[0] === "expireTs") {
        return sortOpt[1] === ESort.ASC
          ? a.expireTs - b.expireTs
          : b.expireTs - a.expireTs;
      }
      if (sortOpt[0] === "amount-out") {
        return sortOpt[1] === ESort.ASC
          ? a.amountOut.minus(b.amountOut).toNumber()
          : b.amountOut.minus(a.amountOut).toNumber();
      }
      if (sortOpt[0] === "price") {
        return sortOpt[1] === ESort.ASC
          ? a.price.minus(b.price).toNumber()
          : b.price.minus(a.price).toNumber();
      }
      if (sortOpt[0] === "fillPercentage") {
        return sortOpt[1] === ESort.ASC
          ? a.fillPercentage - b.fillPercentage
          : b.fillPercentage - a.fillPercentage;
      }
      return 0;
    });
  }, [sortOpt, openTransactions]);

  const totalPages = useMemo(() => {
    return Math.ceil((sortedTransactions.length ?? 0) / DEFAULT_PAGE_SIZE);
  }, [sortedTransactions.length]);

  const displayedTransactions = useMemo(() => {
    return sortedTransactions.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [currentPage, sortedTransactions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [hideOtherPairs]);

  const currentAccount = useCurrentAccount();
  const emptyMessage = useMemo(() => {
    return currentAccount ? "No open orders" : "No data to display";
  }, [currentAccount]);

  const { refetch: refetchAccountBalances } = useAccountBalanceList();
  const { mutate: cancelLimitOrder } = useCancelLimitOrderMutation();
  const { mutate: claimExpiredLimitOrder } =
    useClaimExpiredLimitOrderMutation();

  const handleOpenCancelModal = (item: OpenOrderItem) => {
    setSelectedOrder(item);
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
    setSelectedOrder(null);
  };

  const handleCancelOrder = () => {
    if (!selectedOrder) return;

    handleCloseCancelModal();

    cancelLimitOrder(
      {
        txTitle: "Cancel Limit Order",
        orderId: selectedOrder.orderId,
        payCoinType: selectedOrder.payCoinName,
        targetCoinType: selectedOrder.targetCoinName,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            refetchAccountBalances();
          }, 1_000);
          setTimeout(() => {
            refetchLimitOrders();
          }, 6_000);
        },
      },
    );
  };

  const handleOpenClaimModal = (item: OpenOrderItem) => {
    setSelectedOrder(item);
    setOpenClaimModal(true);
  };

  const handleCloseClaimModal = () => {
    setOpenClaimModal(false);
    setSelectedOrder(null);
  };

  const handleClaimOrder = () => {
    if (!selectedOrder) return;

    handleCloseClaimModal();

    claimExpiredLimitOrder(
      {
        txTitle: "Claim Expired Limit Order",
        orderId: selectedOrder.orderId,
        payCoinType: selectedOrder.payCoinName,
        targetCoinType: selectedOrder.targetCoinName,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            refetchAccountBalances();
          }, 1_000);
          setTimeout(() => {
            refetchLimitOrders();
          }, 6_000);
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-1">
      {isMobile ? (
        <div className="flex flex-col gap-2">
          {isLoading || isRefetching ? (
            <Repeat count={5}>
              <LimitHistoryItemSkeleton />
            </Repeat>
          ) : (
            <>
              {displayedTransactions.map((tx) => (
                <LimitHistoryItem
                  key={tx.orderId}
                  data={tx}
                  handleOpenCancelModal={handleOpenCancelModal}
                  handleOpenClaimModal={handleOpenClaimModal}
                />
              ))}
            </>
          )}
        </div>
      ) : (
        <table className="flex flex-col gap-1">
          <LimitHistoryHead />
          <tbody className="flex flex-col gap-1">
            {isLoading || isRefetching ? (
              <Repeat count={5}>
                <LimitHistoryRowSkeleton />
              </Repeat>
            ) : (
              <>
                {displayedTransactions.map((tx) => (
                  <LimitHistoryRow
                    key={tx.orderId}
                    data={tx}
                    handleOpenCancelModal={handleOpenCancelModal}
                    handleOpenClaimModal={handleOpenClaimModal}
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      )}
      {totalPages === 0 && !isLoading && !isRefetching && (
        <EmptyTable title={emptyMessage} />
      )}
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          maxVisiblePages={isMobile ? 2 : 5}
        />
      )}

      <CancelOrderModal
        open={openCancelModal}
        onOpenChange={setOpenCancelModal}
        onSubmit={handleCancelOrder}
      />

      <ClaimOrderModal
        open={openClaimModal}
        onOpenChange={setOpenClaimModal}
        selectedOrder={selectedOrder}
        onSubmit={handleClaimOrder}
      />
    </div>
  );
}

export default LimitHistoryList;
