import ConnectButton from "./ConnectButton";
import Logo from "./Logo";
import ImgX from "@/assets/images/socials/x.png";
import ImgDiscord from "@/assets/images/socials/discord.png";
import ImgDocs from "@/assets/images/socials/docs.png";
import { ICFlag, ICMenu, ICRanking } from "@/assets/icons";
import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";
import MenuSheet from "./MenuSheet";
import dayjs from "@/utils/dayjs";
import { LAUNCHING_PHASE } from "@/pages/common/LaunchingBanner/constants";
import MBottomBar from "./MBottomBar";
import { Link } from "react-router-dom";

function NavBar() {
  const isMobile = useAtomValue(isMobileAtom);
  const current = dayjs.utc();
  const isBeta = current.isBefore(LAUNCHING_PHASE.PRE);

  return (
    <nav className="flex items-center justify-between px-6 py-4 sm:px-12 sm:py-6">
      <div className="flex items-center gap-4">
        <Link to="/">
          <Logo />
        </Link>
        <div className="flex items-center gap-4 px-4 py-3 bg-[#252734] rounded-xl">
          <div className="flex items-center gap-2">
            <Link to="/">
              <span className="text-xs/none">Aggregator</span>
            </Link>
            {isBeta && (
              <span className="flex px-1.5 py-1 rounded-full bg-[#6265FF] text-3xs/none">
                Beta
              </span>
            )}
          </div>
          {!isMobile && (
            <>
              <div className="w-px h-3 bg-darkblue-100" />
              <div className="flex items-center gap-4">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/7k_ag_"
                  title="X"
                >
                  <img src={ImgX} alt="X" className="w-4 aspect-square" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.gg/vNhgKEawXn"
                  title="Discord"
                >
                  <img
                    src={ImgDiscord}
                    alt="Discord"
                    className="w-4 aspect-square"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.7k.ag"
                  title="Docs"
                >
                  <img src={ImgDocs} alt="Docs" className="w-4 aspect-square" />
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile ? (
        <>
          <MenuSheet
            trigger={
              <button className="flex items-center justify-center w-10 aspect-square rounded-xl bg-black-60">
                <ICMenu className="w-5 h-auto" />
              </button>
            }
          />
          <MBottomBar />
        </>
      ) : (
        <div className="flex items-center gap-2">
          <a
            href="https://7k.ag/support"
            target="_blank"
            rel="noopener noreferrer"
            title="Feedback"
            className="flex items-center gap-2 px-6 py-3 rounded-xl bg-[#383D4C] hover:bg-[#404862] text-white text-xs/none"
          >
            <ICFlag className="w-4 h-auto" />
            <span>Feedback</span>
          </a>
          <Link
            to="/leaderboard"
            className="flex items-center gap-2 px-6 py-3 rounded-xl bg-green-inverted-80 hover:bg-[#BAFFCA] text-black-80 text-xs/none"
          >
            <ICRanking className="w-4 h-auto" />
            <span>Leaderboard</span>
          </Link>
          <ConnectButton />
        </div>
      )}
    </nav>
  );
}

export default NavBar;
