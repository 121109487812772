import { fullTokenListAtom } from "@/atoms/token.atom";
import { TokenDetails } from "@/types/token";
import { SuiUtils } from "@/utils/sui";
import { getSuiscanTokenMetadata, normalizeTokenType } from "@/utils/token";
import { normalizeStructTag } from "@mysten/sui/utils";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

const useSearchTokenList = (searchTerm: string) => {
  const tokenList = useAtomValue(fullTokenListAtom);

  return useQuery<TokenDetails[]>({
    queryKey: ["searchTokenList", searchTerm, tokenList],
    queryFn: async () => {
      // search by token type
      if (SuiUtils.isValidStructTag(searchTerm)) {
        // search in full list
        let token = tokenList.find((token) => {
          try {
            return (
              normalizeStructTag(token.type).toLowerCase() ===
              normalizeStructTag(searchTerm).toLowerCase()
            );
          } catch (e) {
            return false;
          }
        });
        if (token) {
          return [token];
        }

        // search in suiscan
        token = await getSuiscanTokenMetadata(searchTerm);
        return token
          ? [{ ...token, type: normalizeTokenType(token.type) }]
          : [];
      }

      // search by keyword in full list
      const keyword = searchTerm.toLowerCase();
      return tokenList.filter(
        (token) =>
          token.name?.toLowerCase().includes(keyword) ||
          token.symbol?.toLowerCase().includes(keyword),
      );
    },
    enabled: !!searchTerm,
  });
};

export default useSearchTokenList;
