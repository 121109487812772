import { fullTokenMapAtom } from "@/atoms/token.atom";
import { EMPTY_TOKEN } from "@/constants/tokens";
import { LimitOrder, LoDcaOrderStatus } from "@/types/limitDca";
import { TokenDetails } from "@/types/token";
import { RATE_SCALE } from "@/utils/limitDca/constants";
import { formatBalance } from "@/utils/number";
import BigNumber from "bignumber.js";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

type OpenOrderItemType = "valid" | "invalid";

export interface OpenOrderItem {
  orderId: string;
  createdTs: number;
  expireTs: number;
  payCoinName: string;
  targetCoinName: string;
  tokenIn: TokenDetails;
  tokenOut: TokenDetails;
  amountIn: BigNumber;
  amountOut: BigNumber;
  price: BigNumber;
  priceInverted: BigNumber;
  obtainedAmount: BigNumber;
  totalPayAmount: BigNumber;
  status: LoDcaOrderStatus;
  fillPercentage: number;
  type: OpenOrderItemType;
}

const useOpenOrderItems = (orders: LimitOrder[]): OpenOrderItem[] => {
  const fullTokenMap = useAtomValue(fullTokenMapAtom);

  const items = useMemo(() => {
    return orders.map((order) => {
      const tokenIn = fullTokenMap[order.payCoinName];
      const tokenOut = fullTokenMap[order.targetCoinName];

      if (!tokenIn || !tokenOut) {
        return {
          orderId: order.orderId,
          createdTs: order.createdTs,
          expireTs: order.expireTs,
          payCoinName: order.payCoinName,
          targetCoinName: order.targetCoinName,
          tokenIn: tokenIn ?? EMPTY_TOKEN,
          tokenOut: tokenOut ?? EMPTY_TOKEN,
          amountIn: new BigNumber(0),
          amountOut: new BigNumber(0),
          price: new BigNumber(0),
          priceInverted: new BigNumber(0),
          obtainedAmount: new BigNumber(0),
          totalPayAmount: new BigNumber(0),
          status: order.status,
          fillPercentage: 0,
          type: "invalid" as OpenOrderItemType,
        };
      }

      const amountIn = formatBalance(
        order.totalPayAmount,
        tokenIn?.decimals ?? 0,
      );
      const decimalRateScale = new BigNumber(10).pow(
        tokenIn?.decimals - tokenOut?.decimals,
      );
      const price = new BigNumber(order.rate)
        .multipliedBy(decimalRateScale)
        .dividedBy(RATE_SCALE.toString());
      const priceInverted = new BigNumber(1).dividedBy(price);
      const amountOut = amountIn.multipliedBy(price);
      const obtainedAmount = formatBalance(
        order.obtainedAmount,
        tokenIn?.decimals ?? 0,
      );
      const totalPayAmount = formatBalance(
        order.totalPayAmount,
        tokenIn?.decimals ?? 0,
      );
      const fillPercentage = new BigNumber(order.filled)
        .multipliedBy(100)
        .toNumber();
      return {
        orderId: order.orderId,
        createdTs: order.createdTs,
        expireTs: order.expireTs,
        payCoinName: order.payCoinName,
        targetCoinName: order.targetCoinName,
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        price,
        priceInverted,
        obtainedAmount,
        totalPayAmount,
        status: order.status,
        fillPercentage,
        type: "valid" as OpenOrderItemType,
      };
    });
  }, [orders, fullTokenMap]);

  return items;
};

export default useOpenOrderItems;
