import { accountBalanceListAtom } from "@/atoms/account.atom";
import { useCurrentAccount, useSuiClientQuery } from "@mysten/dapp-kit";
import { CoinBalance } from "@mysten/sui/client";
import { normalizeStructTag } from "@mysten/sui/utils";
import BigNumber from "bignumber.js";
import { useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";

const useAccountBalanceList = () => {
  const account = useCurrentAccount();
  const setAccountBalanceList = useSetAtom(accountBalanceListAtom);

  const { data, ...rest } = useSuiClientQuery(
    "getAllBalances",
    {
      owner: account?.address as string,
    },
    {
      enabled: !!account,
    },
  );

  const balances: CoinBalance[] | undefined = useMemo(() => {
    return data
      ?.filter((token) => new BigNumber(token.totalBalance).isGreaterThan(0))
      ?.map((token) => ({
        ...token,
        coinType: normalizeStructTag(token.coinType),
      }));
  }, [data]);

  useEffect(() => {
    if (Array.isArray(balances)) {
      setAccountBalanceList(balances);
    }
  }, [balances, setAccountBalanceList]);

  useEffect(() => {
    if (!account) {
      setAccountBalanceList([]);
    }
  }, [account, setAccountBalanceList]);

  return {
    data,
    ...rest,
  };
};

export default useAccountBalanceList;
