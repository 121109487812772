import { Skeleton } from "@/components/UI/Skeleton";
import tw from "@/utils/twmerge";
import { memo } from "react";
import TextAmt from "@/components/TextAmt";
import { DcaExecutionItem } from "./hooks/useDcaExecutionItems";
import PriceModeBtn from "@/pages/trade/common/PriceModeBtn";
import useDcaExecutionItem from "./hooks/useDcaExecutionItem";
import TokenAvatar from "@/components/Avatar/TokenAvatar";
import ExplorerTxLink from "@/components/ExplorerLink/ExplorerTxLink";
import { formatAddress } from "@mysten/sui/utils";

type TdProps = JSX.IntrinsicElements["th"];

const Td = memo(function Td({ className, ...props }: TdProps) {
  return (
    <td className={tw("flex h-8 px-2 font-normal", className)} {...props} />
  );
});

type Props = {
  data: DcaExecutionItem;
};

function DcaExecutionRow({ data }: Props) {
  const {
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    executedD,
    executedH,
    price,
    priceMode,
    setPriceMode,
    digest,
  } = useDcaExecutionItem({ data });

  return (
    <tr className="grid grid-cols-10 p-1 gap-1 rounded-2xl bg-[#2C3043] cursor-pointer">
      <Td className="col-span-2 flex items-center justify-start">
        <div>
          <div className="mb-1 text-3xs text-gray-100">{executedH}</div>
          <div className="text-2xs">{executedD}</div>
        </div>
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        {tokenOut && (
          <div className="flex items-center gap-1">
            <TextAmt number={amountOut} className="text-xs" />
            <TokenAvatar token={tokenOut} className="w-3 h-3" />
            <span className="font-normal text-3xs text-gray-100">
              {tokenOut.symbol}
            </span>
          </div>
        )}
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <div className="flex items-center gap-1">
          <TextAmt
            number={price}
            className="text-xs"
            decimalClassName="text-gray-100"
          />{" "}
          <div onClick={(e) => e.stopPropagation()}>
            <PriceModeBtn
              tokenIn={tokenIn?.symbol || ""}
              tokenOut={tokenOut?.symbol || ""}
              value={priceMode}
              onChange={setPriceMode}
            />
          </div>
        </div>
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        {tokenIn && (
          <div className="flex items-center gap-1">
            <TextAmt number={amountIn} className="text-xs" />
            <span className="font-normal text-3xs text-gray-100">
              {tokenIn.symbol}
            </span>
          </div>
        )}
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <ExplorerTxLink
          txHash={digest}
          className="text-xs text-gray-100 underline hover:text-green-80"
        >
          {formatAddress(digest)}
        </ExplorerTxLink>
      </Td>
    </tr>
  );
}

export const DcaExecutionRowSkeleton = memo(function DcaExecutionRowSkeleton() {
  return (
    <tr className="grid grid-cols-10 p-1 gap-1 rounded-2xl bg-[#2C3043] cursor-pointer">
      <Td className="col-span-2 flex items-center justify-start">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
    </tr>
  );
});
export default memo(DcaExecutionRow);
