import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { LoDcaQueryConfig, LoDcaQueryParams } from "./types";
import { hideOtherPairsAtom } from "@/atoms/swapHistory.atom";
import { agTokenPairAtom } from "@/atoms/aggregator.atom";
import { dcaOpenOrdersAtom } from "@/atoms/dca.atom";
import useGetDcaOrders from "./useGetDcaOrders";
import { currentAccountAddressAtom } from "@/atoms/account.atom";

const useGetOpenDcaOrders = (config?: LoDcaQueryConfig) => {
  const accountAddress = useAtomValue(currentAccountAddressAtom);
  const setDcaOpenOrders = useSetAtom(dcaOpenOrdersAtom);
  const hideTokenPairs = useAtomValue(hideOtherPairsAtom);
  const tokenPair = useAtomValue(agTokenPairAtom);

  const params = useMemo(() => {
    const result: LoDcaQueryParams = {
      owner: accountAddress,
      statuses: ["ACTIVE", "EXPIRED"],
      offset: 0,
      limit: 50,
    };
    if (hideTokenPairs) {
      result.tokenPair = tokenPair;
    }
    return result;
  }, [accountAddress, hideTokenPairs, tokenPair]);

  const { data, ...rest } = useGetDcaOrders("openDcaOrders", params, config);

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      return b.createdTs - a.createdTs;
    });
  }, [data]);

  useEffect(() => {
    if (sortedData) {
      setDcaOpenOrders(sortedData);
    }
  }, [sortedData, setDcaOpenOrders]);

  useEffect(() => {
    if (!accountAddress) {
      setDcaOpenOrders([]);
    }
  }, [accountAddress, setDcaOpenOrders]);

  return { data: sortedData, ...rest };
};

export default useGetOpenDcaOrders;
