import { trendingTokenListAtom } from "@/atoms/token.atom";
import { SUGGESTED_TOKENS_FULL } from "@/constants/tokens";
import { TokenDetails } from "@/types/token";
import { normalizeTokenType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { uniqBy } from "lodash";
import { useEffect } from "react";

interface Config {
  refetchInterval?: number;
}

const useTrendingTokenList = (config?: Config) => {
  const setTrendingTokenList = useSetAtom(trendingTokenListAtom);

  const { data, ...rest } = useQuery<TokenDetails[]>({
    queryKey: ["getTrendingTokenList"],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_TOKENS_API}/trending`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch trending tokens");
      }
      return response.json();
    },
    refetchInterval: config?.refetchInterval,
  });

  useEffect(() => {
    if (Array.isArray(data)) {
      const tokens = uniqBy([...SUGGESTED_TOKENS_FULL, ...data], "type");
      setTrendingTokenList(
        tokens.map((token) => ({
          ...token,
          type: normalizeTokenType(token.type),
        })),
      );
    }
  }, [data, setTrendingTokenList]);

  return {
    data,
    ...rest,
  };
};

export default useTrendingTokenList;
