import tw from "@/utils/twmerge";
import { memo } from "react";

interface Props {
  num: number;
  className?: string;
}

function NumBadge({ num, className }: Props) {
  return (
    <span
      className={tw(
        "w-[0.875rem] aspect-square flex items-center justify-center rounded bg-[#6265FF] text-3xs/none text-white",
        className,
      )}
    >
      {num}
    </span>
  );
}

export default memo(NumBadge);
