import tw from "@/utils/twmerge";
import SortBtn from "@/components/Table/SortBtn";
import { ReactNode } from "react";
import {
  loOpenOrdersSortOptAtom,
  LoOpenOrdersSortType,
} from "@/atoms/limitOrder.atom";

const HistorySortBtn = ({
  label,
  type,
  align,
}: {
  label: ReactNode;
  type: LoOpenOrdersSortType;
  align: "left" | "right";
}) => {
  return (
    <SortBtn
      label={label}
      type={type}
      atom={loOpenOrdersSortOptAtom}
      align={align}
    />
  );
};

type ThProps = JSX.IntrinsicElements["th"];
function Th({ className, children }: ThProps) {
  return (
    <th
      className={tw(
        "w-full h-9 px-2 rounded-xl font-samsung font-medium text-2xs/none text-gray-100 bg-black-70",
        className,
      )}
    >
      {children}
    </th>
  );
}

function LimitHistoryHead() {
  return (
    <thead className="relative">
      <tr className="grid grid-cols-12 p-1 gap-1">
        <Th className="col-span-2 flex items-center">Pair</Th>
        <Th className="col-span-2">
          <HistorySortBtn label="Amount" type="amount-out" align="right" />
        </Th>
        <Th className="col-span-2">
          <HistorySortBtn label="Price" type="price" align="right" />
        </Th>
        <Th className="col-span-3">
          <HistorySortBtn
            label="Fill / Total"
            type="fillPercentage"
            align="right"
          />
        </Th>
        <Th className="col-span-3 flex items-center justify-end">
          Expiration & Action
        </Th>
      </tr>
    </thead>
  );
}

export default LimitHistoryHead;
