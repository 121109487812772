import { Skeleton } from "@/components/UI/Skeleton";
import tw from "@/utils/twmerge";
import { memo } from "react";
import TokenGroupAvatar from "@/components/Avatar/TokenGroupAvatar";
import TextAmt from "@/components/TextAmt";
import { ICChevronRight, ICCloseDot } from "@/assets/icons";
import { OpenOrderItem } from "./hooks/useOpenOrderItems";
import PriceModeBtn from "@/pages/trade/common/PriceModeBtn";
import useOpenOrderItem from "./hooks/useOpenOrderItem";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "@/components/CircularProgressbar";

type TdProps = JSX.IntrinsicElements["th"];

const Td = memo(function Td({ className, ...props }: TdProps) {
  return (
    <td className={tw("flex h-16 px-2 font-normal", className)} {...props} />
  );
});

type Props = {
  data: OpenOrderItem;
  handleOpenCancelModal: (item: OpenOrderItem) => void;
  handleOpenClaimModal: (item: OpenOrderItem) => void;
};

function LimitHistoryRow({
  data,
  handleOpenCancelModal,
  handleOpenClaimModal,
}: Props) {
  const {
    expiredH,
    expiredD,
    tokenIn,
    tokenOut,
    price,
    priceMode,
    setPriceMode,
    amount,
    amountMode,
    setAmountMode,
    fillPercentage,
    obtainedAmount,
    totalPayAmount,
    status,
  } = useOpenOrderItem({ data });

  return (
    <tr className="grid grid-cols-12 p-1 gap-1 rounded-2xl bg-[#2C3043]">
      <Td className="col-span-2 flex items-center">
        {tokenIn && tokenOut && (
          <div className="flex items-center gap-1">
            <TokenGroupAvatar
              tokens={[tokenIn, tokenOut]}
              tokenClassName="w-3 h-3 -ml-1 first:ml-0"
            />
            <span className="font-bold text-xs">{tokenIn.symbol}</span>
            <ICChevronRight className="w-4 aspect-square text-[#868098]" />
            <span className="font-bold text-xs">{tokenOut.symbol}</span>
          </div>
        )}
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <div className="flex items-center gap-1">
          <TextAmt
            number={amount}
            className="text-xs"
            decimalClassName="text-gray-100"
          />{" "}
          <PriceModeBtn
            tokenIn={tokenIn?.symbol || ""}
            tokenOut={tokenOut?.symbol || ""}
            value={amountMode}
            onChange={setAmountMode}
          />
        </div>
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <div className="flex items-center gap-1">
          <TextAmt
            number={price}
            className="text-xs"
            decimalClassName="text-gray-100"
          />{" "}
          <PriceModeBtn
            tokenIn={tokenIn?.symbol || ""}
            tokenOut={tokenOut?.symbol || ""}
            value={priceMode}
            onChange={setPriceMode}
          />
        </div>
      </Td>
      <Td className="col-span-3 flex items-center justify-start">
        <div className="flex-1 flex items-center gap-2">
          <div className="h-10 w-10">
            <CircularProgressbarWithChildren
              value={fillPercentage}
              strokeWidth={12}
              styles={buildStyles({
                pathColor: "#85FF99",
                trailColor: "#1C1E2C",
              })}
            >
              <span
                className={tw(
                  "font-normal text-3xs/none text-gray-100",
                  fillPercentage > 0 && "text-green-80",
                )}
              >
                {fillPercentage}
              </span>
            </CircularProgressbarWithChildren>
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="flex items-center justify-between gap-2">
              <span className="font-normal text-2xs/none text-gray-100">
                {" "}
                Fill{" "}
                <TextAmt
                  number={fillPercentage}
                  suffix="%"
                  className={tw(fillPercentage > 0 && "text-green-80")}
                />
              </span>
              <span className="text-xs/none text-gray-100">
                <TextAmt
                  number={obtainedAmount}
                  className="text-white"
                  decimalClassName="text-gray-100"
                />{" "}
                {tokenIn.symbol}
              </span>
            </div>
            <div className="h-px w-full bg-black-60" />
            <div className="flex items-center justify-between gap-2">
              <span className="font-normal text-2xs/none text-gray-100">
                Total
              </span>
              <span className="text-xs/none text-gray-100">
                <TextAmt
                  number={totalPayAmount}
                  className="text-white"
                  decimalClassName="text-gray-100"
                />{" "}
                {tokenIn.symbol}
              </span>
            </div>
          </div>
        </div>
      </Td>
      <Td className="col-span-3 flex items-center justify-end">
        <div className="flex items-center gap-2">
          {status === "ACTIVE" && (
            <>
              <span className="text-xs/none text-gray-100">
                {expiredD} {expiredH}
              </span>
              <button
                className="flex items-center justify-center p-2 rounded-lg bg-black-60 text-gray-100 hover:bg-[#383D4C] hover:text-white"
                onClick={() => handleOpenCancelModal(data)}
                title="Cancel Order"
              >
                <ICCloseDot className="w-3 h-3" />
              </button>
            </>
          )}
          {status === "EXPIRED" && (
            <>
              <span className="text-xs/none text-pink-100">Expired</span>
              <button
                className="flex items-center gap-2 p-1 rounded-md bg-yellow-90 hover:bg-[#EBAA2A] text-black-80 text-xs/none"
                onClick={() => handleOpenClaimModal(data)}
                title="Claim Expired Order"
              >
                <span>Claim</span>
              </button>
            </>
          )}
        </div>
      </Td>
    </tr>
  );
}

export const LimitHistoryRowSkeleton = memo(function LimitHistoryRowSkeleton() {
  return (
    <tr className="grid grid-cols-12 p-1 gap-1 rounded-2xl bg-[#2C3043]">
      <Td className="col-span-2 flex items-center">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-3 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-3 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
    </tr>
  );
});
export default memo(LimitHistoryRow);
