import { TS_7_DAYS } from "@/constants/time";
import { ESort } from "@/types/data";
import { LimitOrder } from "@/types/limitDca";
import { atom } from "jotai";
import { agTokenPairAtom } from "./aggregator.atom";
import { hideOtherPairsAtom } from "./swapHistory.atom";
import { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import BigNumber from "bignumber.js";

export const loRateInputAtom = atom("");

export const loRateModeAtom = atom<PriceMode>("in-out");

export const loRateValueAtom = atom((get) => {
  const rate = get(loRateInputAtom);
  if (!rate || new BigNumber(rate).lte(0)) return new BigNumber(0);
  const mode = get(loRateModeAtom);
  return mode === "out-in" ? new BigNumber(rate) : new BigNumber(1).div(rate);
});

export const loMarketRateAtom = atom("");

export const loExpiryAtom = atom(TS_7_DAYS);

export const loHistoryTabAtom = atom<"open-orders" | "history">("open-orders");

export const loOpenOrdersAtom = atom<LimitOrder[]>([]);

export const loFilteredOpenOrdersAtom = atom((get) => {
  const hideOtherPairs = get(hideOtherPairsAtom);
  const tokenPair = get(agTokenPairAtom);
  const orders = get(loOpenOrdersAtom);
  return hideOtherPairs
    ? orders.filter(
        (order) =>
          tokenPair.includes(order.payCoinName) &&
          tokenPair.includes(order.targetCoinName),
      )
    : orders;
});

export type LoOpenOrdersSortType =
  | "createdTs"
  | "expireTs"
  | "amount-out"
  | "price"
  | "fillPercentage";

export const loOpenOrdersSortOptAtom = atom<
  Partial<Record<LoOpenOrdersSortType, ESort>>
>({ createdTs: ESort.DESC });

export const loIsRefetchingOpenOrdersAtom = atom(false);
