import tw from "@/utils/twmerge";

type ThProps = JSX.IntrinsicElements["th"];
function Th({ className, children }: ThProps) {
  return (
    <th
      className={tw(
        "w-full h-9 px-2 rounded-xl font-samsung font-medium text-2xs/none text-gray-100 bg-black-70",
        className,
      )}
    >
      {children}
    </th>
  );
}

function DcaHistoryHead() {
  return (
    <thead className="relative">
      <tr className="grid grid-cols-12 p-1 gap-1">
        <Th className="col-span-2 flex items-center">Coin</Th>
        <Th className="col-span-2 flex items-center">Min Price</Th>
        <Th className="col-span-2 flex items-center justify-end">Max Price</Th>
        <Th className="col-span-5 flex items-center">
          Amount Each Order/ Over Orders/ Every
        </Th>
        <Th className="col-span-1 flex items-center justify-center">Actions</Th>
      </tr>
    </thead>
  );
}

export default DcaHistoryHead;
