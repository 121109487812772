import { Skeleton } from "@/components/UI/Skeleton";
import tw from "@/utils/twmerge";
import { memo } from "react";
import TextAmt from "@/components/TextAmt";
import { DcaOrderItem } from "./hooks/useDcaOrderItems";
import PriceModeBtn from "@/pages/trade/common/PriceModeBtn";
import useDcaOrderItem from "./hooks/useDcaOrderItem";
import TokenAvatar from "@/components/Avatar/TokenAvatar";
import { ICChevronDown } from "@/assets/icons";

type TdProps = JSX.IntrinsicElements["th"];

const Td = memo(function Td({ className, ...props }: TdProps) {
  return (
    <td className={tw("flex h-12 px-2 font-normal", className)} {...props} />
  );
});

type Props = {
  data: DcaOrderItem;
  selectedRowIds: Set<string>;
  handleToggleRow: (id: string) => void;
};

function DcaHistoryRow({ data, selectedRowIds, handleToggleRow }: Props) {
  const {
    orderId,
    tokenIn,
    tokenOut,
    minPrice,
    minPriceMode,
    setMinPriceMode,
    maxPrice,
    maxPriceMode,
    setMaxPriceMode,
    amountPerOrder,
    totalNumOrders,
    intervalValue,
    intervalUnit,
  } = useDcaOrderItem({ data });

  return (
    <tr
      className="grid grid-cols-12 p-1 gap-1 rounded-2xl bg-[#2C3043] cursor-pointer"
      onClick={() => handleToggleRow(orderId)}
    >
      <Td className="col-span-2 flex items-center">
        {tokenOut && (
          <div className="flex items-center gap-1">
            <TokenAvatar token={tokenOut} className="w-3 h-3" />
            <span className="font-bold text-xs">{tokenOut.symbol}</span>
          </div>
        )}
      </Td>
      <Td className="col-span-2 flex items-center">
        <div className="flex items-center gap-1">
          <TextAmt
            number={minPrice}
            className="text-xs"
            decimalClassName="text-gray-100"
          />{" "}
          <div onClick={(e) => e.stopPropagation()}>
            <PriceModeBtn
              tokenIn={tokenIn?.symbol || ""}
              tokenOut={tokenOut?.symbol || ""}
              value={minPriceMode}
              onChange={setMinPriceMode}
            />
          </div>
        </div>
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <div className="flex items-center gap-1">
          {maxPrice.eq(-1) ? (
            <span>∞</span>
          ) : (
            <TextAmt
              number={maxPrice}
              className="text-xs"
              decimalClassName="text-gray-100"
            />
          )}{" "}
          <div onClick={(e) => e.stopPropagation()}>
            <PriceModeBtn
              tokenIn={tokenIn?.symbol || ""}
              tokenOut={tokenOut?.symbol || ""}
              value={maxPriceMode}
              onChange={setMaxPriceMode}
            />
          </div>
        </div>
      </Td>
      <Td className="col-span-5 flex items-center justify-start">
        <div className="flex-1 grid grid-cols-7 gap-1">
          <div className="col-span-3 flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
            {tokenIn && (
              <div className="flex items-center gap-1">
                <TokenAvatar token={tokenIn} className="w-3 h-3" />
                <TextAmt number={amountPerOrder} className="text-xs" />
                <span className="font-normal text-3xs text-gray-100">
                  {tokenIn.symbol}
                </span>
              </div>
            )}
          </div>
          <div className="col-span-2 flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
            <div className="flex items-center gap-2.5">
              <TextAmt number={totalNumOrders} className="text-xs" />
              <span className="flex items-center p-1 h-5 rounded bg-black-40 font-semibold text-3xs">
                Orders
              </span>
            </div>
          </div>
          <div className="col-span-2 flex items-center justify-end gap-1 p-1 rounded-lg bg-black-60 h-7.5">
            <div className="flex items-center gap-2.5">
              <TextAmt number={intervalValue} className="text-xs" />
              <span className="flex items-center p-1 h-5 rounded bg-black-40 font-semibold text-3xs">
                {intervalUnit}
              </span>
            </div>
          </div>
        </div>
      </Td>
      <Td className="col-span-1 flex items-center justify-center">
        <ICChevronDown
          className={tw(
            "transition-all duration-200 w-4 h-auto",
            selectedRowIds.has(orderId) && "rotate-180",
          )}
        />
      </Td>
    </tr>
  );
}

export const DcaHistoryRowSkeleton = memo(function DcaHistoryRowSkeleton() {
  return (
    <tr className="grid grid-cols-12 p-1 gap-1 rounded-2xl bg-[#2C3043] cursor-pointer">
      <Td className="col-span-2 flex items-center">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-2 flex items-center justify-end">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-5 flex items-center justify-start">
        <Skeleton className="w-full h-5" />
      </Td>
      <Td className="col-span-1 flex items-center justify-center">
        <Skeleton className="w-5 h-5" />
      </Td>
    </tr>
  );
});
export default memo(DcaHistoryRow);
