import EmptyTable from "@/components/EmptyData/EmptyData";
import Pagination from "@/components/Table/Pagination";
import Repeat from "@/components/UI/Repeat";
import { useAtomValue } from "jotai";
import { useMemo, useState } from "react";
import DcaExecutionHead from "./DcaExecutionHead";
import DcaExecutionRow, { DcaExecutionRowSkeleton } from "./DcaExecutionRow";
import { DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import { isMobileAtom } from "@/atoms/layout.atom";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { DcaExecutionItem } from "./hooks/useDcaExecutionItems";

interface Props {
  transactions: DcaExecutionItem[];
  isLoading: boolean;
  isRefetching: boolean;
}

function DcaExecutionList({ transactions, isLoading, isRefetching }: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => {
    return Math.ceil((transactions.length ?? 0) / DEFAULT_PAGE_SIZE);
  }, [transactions.length]);

  const displayedTransactions = useMemo(() => {
    return transactions.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [currentPage, transactions]);

  const currentAccount = useCurrentAccount();
  const emptyMessage = useMemo(() => {
    return currentAccount ? "No orders executed yet" : "No data to display";
  }, [currentAccount]);

  return (
    <div className="flex flex-col gap-1">
      <table className="flex flex-col gap-1">
        <DcaExecutionHead />
        <tbody className="flex flex-col gap-1">
          {isLoading || isRefetching ? (
            <Repeat count={5}>
              <DcaExecutionRowSkeleton />
            </Repeat>
          ) : (
            <>
              {displayedTransactions.map((tx) => (
                <DcaExecutionRow key={tx.id} data={tx} />
              ))}
            </>
          )}
        </tbody>
      </table>
      {totalPages === 0 && !isLoading && !isRefetching && (
        <EmptyTable title={emptyMessage} />
      )}
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          maxVisiblePages={isMobile ? 2 : 5}
        />
      )}
    </div>
  );
}

export default DcaExecutionList;
