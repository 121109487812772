import { ICImage } from "@/assets/icons";
import { WsrvParams } from "@/types/wsrv";
import tw from "@/utils/twmerge";
import { memo, useEffect, useMemo, useState } from "react";

type Props = JSX.IntrinsicElements["span"] &
  Partial<Pick<HTMLImageElement, "src" | "alt">> &
  WsrvParams & { fallbackSrc?: string };

function Avatar({
  src: _src,
  fallbackSrc = _src,
  alt,
  w = 48,
  h = 48,
  ...props
}: Props) {
  const [shouldFallbackDefault, setShouldFallbackDefault] = useState(false);
  const [src, setSrc] = useState(_src);
  const [error, setError] = useState(0);

  const className = useMemo(() => {
    return tw(
      "relative w-full h-full rounded-full overflow-hidden bg-stake-dark-300",
      props.className,
    );
  }, [props.className]);

  // Reset error state and fallback when source changes
  useEffect(() => {
    setError(0);
    setShouldFallbackDefault(false);
  }, [_src]);

  // Handle wsrv URL parameters
  useEffect(() => {
    if (_src) {
      const params = new URLSearchParams();

      params.append("url", _src);

      if (w) {
        params.append("w", w.toString());
      }
      if (h) {
        params.append("h", h.toString());
      }

      const srcWithParams = `https://wsrv.nl/?${params.toString()}`;
      setSrc(srcWithParams);
    } else {
      setSrc(_src);
    }
  }, [_src, w, h]);

  // Function to handle image loading errors
  const handleImageError = () => {
    if (error === 0 && fallbackSrc) {
      // First error, try the fallbackSrc
      setSrc(fallbackSrc);
    } else {
      // If fallbackSrc also fails, show the default error state
      setShouldFallbackDefault(true);
    }
    setError((prev) => prev + 1); // Increment error state
  };

  return (
    <span {...props} className={className}>
      {shouldFallbackDefault ? (
        <span className="w-full h-full inline-flex items-center justify-center">
          <ICImage className="min-w-4 max-w-20 w-1/2 h-auto" />
        </span>
      ) : (
        <img
          src={src}
          alt={alt || ""}
          className="w-full h-full object-cover"
          onError={handleImageError}
        />
      )}
    </span>
  );
}

export default memo(Avatar);
