import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/UI/Sheet";
import { AnimatePresence } from "framer-motion";
import { memo } from "react";
import LimitHistoryContent from "./LimitHistoryContent";
import { OpenOrderItem } from "../hooks/useOpenOrderItems";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: OpenOrderItem;
  handleOpenCancelModal: (item: OpenOrderItem) => void;
  handleOpenClaimModal: (item: OpenOrderItem) => void;
}

function LimitHistorySheet({
  open,
  setOpen,
  data,
  handleOpenCancelModal,
  handleOpenClaimModal,
}: Props) {
  const openCancelModal = (item: OpenOrderItem) => {
    handleOpenCancelModal(item);
    setOpen(false);
  };
  const openClaimModal = (item: OpenOrderItem) => {
    handleOpenClaimModal(item);
    setOpen(false);
  };

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <AnimatePresence>
        {open && (
          <SheetContent forceMount side="bottom" onOpenChange={setOpen}>
            <div className="flex flex-col gap-6">
              <SheetHeader>
                <SheetTitle>
                  Order Details {data.status === "EXPIRED" && " - Expired"}
                </SheetTitle>
                <SheetCloseEsc />
              </SheetHeader>
              <LimitHistoryContent
                data={data}
                handleOpenCancelModal={openCancelModal}
                handleOpenClaimModal={openClaimModal}
                size="large"
              />
            </div>
          </SheetContent>
        )}
      </AnimatePresence>
    </Sheet>
  );
}

export default memo(LimitHistorySheet);
