import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion";
import {
  Dialog,
  DialogCloseEsc,
  DialogContentDefault,
  DialogHeader,
} from "@/components/UI/Dialog";
import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/UI/Sheet";
import InputCurrency from "@/components/Input/InputCurrency";
import { loExpiryAtom } from "@/atoms/limitOrder.atom";
import { LO_DCA_MAX_EXPIRE_TS } from "@/constants/limitDca";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

function CustomPeriodModal({ open, onOpenChange }: Props) {
  const isMobile = useAtomValue(isMobileAtom);
  const setExpiry = useSetAtom(loExpiryAtom);

  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");

  const handleSetPeriod = () => {
    const hoursInt = parseInt(hours) || 0;
    const minutesInt = parseInt(minutes) || 0;

    setExpiry(hoursInt * 60 * 60 * 1000 + minutesInt * 60 * 1000);
    onOpenChange(false);
  };

  const invalidPeriodError = useMemo(() => {
    const hoursInt = parseInt(hours) || 0;
    const minutesInt = parseInt(minutes) || 0;

    if (hoursInt + minutesInt / 60 <= 0) {
      return "Total time must be greater than 0";
    }

    const totalTs = hoursInt * 60 * 60 * 1000 + minutesInt * 60 * 1000;

    if (totalTs > LO_DCA_MAX_EXPIRE_TS) {
      return "Total time must not exceed 30 days";
    }

    return "";
  }, [hours, minutes]);

  const title = useMemo(() => {
    return "Custom Expiry Period";
  }, []);

  const content = (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-1 bg-black-100 rounded-xl px-4">
        <InputCurrency
          className="flex-1 h-12 outline-none bg-transparent text-base/none overflow-hidden grow"
          placeholder="0"
          value={hours}
          onChange={(e) => {
            setHours(e.target.value);
          }}
          decimals={0}
        />
        <span className="text-sm/none text-gray-100">hours</span>
      </div>
      <div className="flex items-center justify-between gap-1 bg-black-100 rounded-xl px-4">
        <InputCurrency
          className="flex-1 h-12 outline-none bg-transparent text-base/none overflow-hidden grow"
          placeholder="0"
          value={minutes}
          onChange={(e) => {
            setMinutes(e.target.value);
          }}
          decimals={0}
        />
        <span className="text-sm/none text-gray-100">minutes</span>
      </div>
      {(hours || minutes) && invalidPeriodError && (
        <span className="text-xs/none text-red-80">{invalidPeriodError}</span>
      )}
      <button
        className="w-full bg-[#383D4C] hover:bg-[#404862] disabled:cursor-not-allowed disabled:opacity-60 px-2 h-12 text-white rounded-[10px] border-none outline-none"
        onClick={handleSetPeriod}
        disabled={!!invalidPeriodError}
      >
        <span className="text-gradient text-sm">Set Period</span>
      </button>
    </div>
  );

  if (isMobile) {
    return (
      <Sheet open={open} onOpenChange={onOpenChange}>
        <AnimatePresence>
          {open && (
            <SheetContent
              forceMount
              side="bottom"
              onOpenChange={onOpenChange}
              animation={false}
              className="p-4 flex flex-col gap-4"
            >
              <SheetHeader>
                <SheetTitle className="font-bold text-base/none">
                  {title}
                </SheetTitle>
                <SheetCloseEsc />
              </SheetHeader>
              {content}
            </SheetContent>
          )}
        </AnimatePresence>
      </Sheet>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {open && (
          <DialogContentDefault
            forceMount
            className="flex flex-col gap-6 p-6 bg-black-80 shadow-soft-3 shadow-skin-alt dark:shadow-skin-alt/10 rounded-3xl max-w-[22rem]"
          >
            <DialogHeader className="flex items-center justify-between pb-4 border-b border-black-40">
              <h2 className="font-bold text-lg/none">{title}</h2>
              <DialogCloseEsc />
            </DialogHeader>
            {content}
          </DialogContentDefault>
        )}
      </AnimatePresence>
    </Dialog>
  );
}

export default CustomPeriodModal;
