import { fullTokenListAtom } from "@/atoms/token.atom";
import { TokenDetails } from "@/types/token";
import { normalizeTokenType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

interface Config {
  refetchInterval?: number;
}

const useFullTokenList = (config?: Config) => {
  const setFullTokenList = useSetAtom(fullTokenListAtom);

  const { data, ...rest } = useQuery<TokenDetails[]>({
    queryKey: ["getFullTokenList"],
    queryFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_TOKENS_API}/all`);
      if (!response.ok) {
        throw new Error("Failed to fetch tokens");
      }
      return response.json();
    },
    refetchInterval: config?.refetchInterval,
  });

  useEffect(() => {
    if (Array.isArray(data)) {
      setFullTokenList(
        data.map((token) => ({
          ...token,
          type: normalizeTokenType(token.type),
        })),
      );
    }
  }, [data, setFullTokenList]);

  return {
    data,
    ...rest,
  };
};

export default useFullTokenList;
