import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue } from "jotai";
import MInfoTabs from "./MInfoTabs";
import DInfoTabs from "./DInfoTabs";

function InfoTabs() {
  const isMobile = useAtomValue(isMobileAtom);

  return <>{isMobile ? <MInfoTabs /> : <DInfoTabs />}</>;
}

export default InfoTabs;
