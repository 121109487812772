import { memo, useState } from "react";
import { Skeleton } from "@/components/UI/Skeleton";
import DcaHistoryContent from "./DcaHistoryContent";
import DcaHistorySheet from "./DcaHistorySheet";
import { DcaOrderItem } from "../hooks/useDcaOrderItems";

type Props = {
  data: DcaOrderItem;
  handleOpenCancelModal: (item: DcaOrderItem) => void;
};

function DcaHistoryItem(props: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DcaHistoryContent {...props} onClick={() => setOpen(true)} />
      <DcaHistorySheet open={open} setOpen={setOpen} {...props} />
    </>
  );
}

export const DcaHistoryItemSkeleton = memo(function DcaHistoryItemSkeleton() {
  return (
    <div className="flex flex-1 flex-col rounded-2xl bg-black-60 gap-4 p-4">
      <div className="grid grid-cols-5 gap-2">
        <div className="col-span-2 flex items-center gap-1">
          <Skeleton className="w-4 h-4" />
          <Skeleton className="w-12 h-4" />
        </div>

        <div className="col-span-3 flex flex-col items-start gap-4">
          <span className="font-normal text-2xs/none text-gray-100">
            Price Range
          </span>
          <Skeleton className="w-12 h-5" />
        </div>
      </div>

      <div className="h-px bg-black-40" />

      <div className="grid grid-cols-5 gap-2">
        <div className="col-span-2 flex flex-col items-start gap-4">
          <span className="font-normal text-2xs/none text-gray-100">
            Total Spent
          </span>
          <Skeleton className="w-12 h-5" />
        </div>

        <div className="col-span-3 flex flex-col items-start gap-4">
          <span className="font-normal text-2xs/none text-gray-100">
            Bought/Total
          </span>
          <Skeleton className="w-12 h-5" />
        </div>
      </div>
    </div>
  );
});

export default memo(DcaHistoryItem);
