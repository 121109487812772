import { useMemo, useState } from "react";
import { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import { DcaExecutionItem } from "./useDcaExecutionItems";
import dayjs from "@/utils/dayjs";

interface Params {
  data: DcaExecutionItem;
}

const useDcaExecutionItem = ({ data }: Params) => {
  const [executedH, executedD] = useMemo(() => {
    const d = dayjs(data.executedTs);
    return [d.format("HH:mm"), d.format("DD/MM/YYYY")];
  }, [data.executedTs]);

  const [priceMode, setPriceMode] = useState<PriceMode>("out-in");
  const price = useMemo(() => {
    return priceMode === "in-out" ? data.priceInverted : data.price;
  }, [data.price, data.priceInverted, priceMode]);

  return {
    ...data,
    executedH,
    executedD,
    price,
    priceMode,
    setPriceMode,
  };
};

export default useDcaExecutionItem;
