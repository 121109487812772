import {
  currentAccountAddressAtom,
  currentSuiNSNameAtom,
} from "@/atoms/account.atom";
import { useSuiClientQuery } from "@mysten/dapp-kit";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";

const useCurrentSuiNSName = () => {
  const currentAccountAddress = useAtomValue(currentAccountAddressAtom);
  const setCurrentSuiNSName = useSetAtom(currentSuiNSNameAtom);

  const { data } = useSuiClientQuery(
    "resolveNameServiceNames",
    {
      address: currentAccountAddress,
    },
    {
      enabled: !!currentAccountAddress,
    },
  );

  const suiNSName = useMemo(() => {
    return data?.data?.[0] ?? "";
  }, [data]);

  useEffect(() => {
    setCurrentSuiNSName(suiNSName);
  }, [suiNSName, setCurrentSuiNSName]);
};

export default useCurrentSuiNSName;
