import { TokenDetails } from "@/types/token";

export const SUI_TOKEN: TokenDetails = {
  type: "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI",
  objectId:
    "0x9258181f5ceac8dbffb7030890243caed69a9599d2886d957a9cb7656af3bdb3",
  name: "Sui",
  denom: "SUI",
  decimals: 9,
  symbol: "SUI",
  iconUrl: "https://strapi-dev.scand.app/uploads/sui_c07df05f00.png",
  verified: true,
  bridgeProject: null,
};

export const NATIVE_USDC: TokenDetails = {
  objectId:
    "0x69b7a7c3c200439c1b5f3b19d7d495d5966d5f08de66c69276152f8db3992ec6",
  type: "0xdba34672e30cb065b1f93e3ab55318768fd6fef66c15942c9f7cb846e2f900e7::usdc::USDC",
  decimals: 6,
  name: "USDC Native",
  symbol: "USDC",
  iconUrl: "https://www.circle.com/hubfs/Brand/USDC/USDC_icon_32x32.png",
  verified: true,
  denom: "USDC",
};

export const USDC_TOKEN: TokenDetails = {
  type: "0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN",
  objectId:
    "0x4fbf84f3029bd0c0b77164b587963be957f853eccf834a67bb9ecba6ec80f189",
  name: "USDC (Portal from ETH)",
  symbol: "wUSDC",
  denom: "USDC",
  iconUrl:
    "https://klrhtty4kphjcsrez2eaeh6r27tbaayw3odjadigbrek544f3i2a.arweave.net/UuJ5zxxTzpFKJM6IAh_R1-YQAxbbhpANBgxIrvOF2jQ",
  verified: true,
  decimals: 6,
  bridgeProject: "Wormhole",
};

export const USDT_TOKEN: TokenDetails = {
  objectId:
    "0xfb0e3eb97dd158a5ae979dddfa24348063843c5b20eb8381dd5fa7c93699e45c",
  type: "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN",
  decimals: 6,
  symbol: "USDT",
  denom: "USDT",
  name: "Tether (Portal from ETH)",
  iconUrl:
    "https://kbjqugvqmnqhcwzoc4cxd5d57apkgpvn24nc5q4e6kvdmtlku6la.arweave.net/UFMKGrBjYHFbLhcFcfR9-B6jPq3XGi7DhPKqNk1qp5Y",
  verified: true,
  bridgeProject: "Wormhole",
};

export const BLUB_TOKEN: TokenDetails = {
  objectId:
    "0xac32b519790cae96c3317457d903d61d04f1bc8f7710096d80fcba72c7a53703",
  type: "0xfa7ac3951fdca92c5200d468d31a365eb03b2be9936fde615e69f0c1274ad3a0::BLUB::BLUB",
  decimals: 2,
  symbol: "BLUB",
  denom: "BLUB",
  name: "BLUB",
  iconUrl:
    "https://assets.coingecko.com/coins/images/39356/standard/Frame_38.png?1721888572",
  verified: true,
};

export const EMPTY_TOKEN: TokenDetails = {
  type: "",
  objectId: "",
  name: "",
  denom: "",
  decimals: 0,
  symbol: "",
  iconUrl: "",
  verified: false,
};

export const DEFAULT_SWAP_TOKENS = {
  IN: SUI_TOKEN,
  OUT: USDC_TOKEN,
};

export const SUI_TYPE = "0x2::sui::SUI";

export const SUI_FULL_TYPE = SUI_TOKEN.type;

export const POPULAR_TOKENS = [
  SUI_FULL_TYPE, // sui
  NATIVE_USDC.type, // native usdc
  USDC_TOKEN.type, // usdc
  "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN", // usdt
  "0xaf8cd5edc19c4512f4259f0bee101a40d41ebed738ade5874359610ef8eeced5::coin::COIN", // weth
  "0x027792d9fed7f9844eb4839566001bb6f6cb4804f66aa2da6fe1ee242d896881::coin::COIN", // wbtc
  "0xb848cce11ef3a8f62eccea6eb5b35a12c4c2b1ee1af7755d02d7bd6218e8226f::coin::COIN", // wbnb
  "0xb7844e289a8410e50fb3ca48d69eb9cf29e27d223ef90353fe1bd8e27ff8f3f8::coin::COIN", // wsol
  "0x06864a6f921804860930db6ddbe2e16acdf8504495ea7481637a1c8b9a8fe54b::cetus::CETUS", // cetus
  "0xa99b8952d4f7d947ea77fe0ecdcc9e5fc0bcab2841d6e2a5aa00c3044e5544b5::navx::NAVX", // navx
  "0x7016aae72cfc67f2fadf55769c0a7dd54291a583b63051a5ed71081cce836ac6::sca::SCA", // sca
];

export const SPECIAL_TOKENS = [
  SUI_FULL_TYPE, // sui
  NATIVE_USDC.type, // native usdc
  USDC_TOKEN.type, // usdc
  "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN", // usdt
];

export const SPECIAL_TOKENS_MAP = SPECIAL_TOKENS.reduce(
  (acc, type) => {
    acc[type] = true;
    return acc;
  },
  {} as Record<string, boolean>,
);

export const SUGGESTED_TOKENS_FULL = [
  SUI_TOKEN,
  NATIVE_USDC,
  USDC_TOKEN,
  USDT_TOKEN,
  BLUB_TOKEN,
];

export const SUGGESTED_TOKENS = SUGGESTED_TOKENS_FULL.map(
  (token) => token.type,
);

export const SUGGESTED_TOKENS_MAP = SUGGESTED_TOKENS.reduce(
  (acc, type) => {
    acc[type] = true;
    return acc;
  },
  {} as Record<string, boolean>,
);
