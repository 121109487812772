import { loOpenOrdersAtom } from "@/atoms/limitOrder.atom";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { LoDcaQueryConfig, LoDcaQueryParams } from "./types";
import useGetLimitOrders from "./useGetLimitOrders";
import { hideOtherPairsAtom } from "@/atoms/swapHistory.atom";
import { agTokenPairAtom } from "@/atoms/aggregator.atom";
import { currentAccountAddressAtom } from "@/atoms/account.atom";

const useGetOpenLimitOrders = (config?: LoDcaQueryConfig) => {
  const accountAddress = useAtomValue(currentAccountAddressAtom);
  const setLoOpenOrders = useSetAtom(loOpenOrdersAtom);
  const hideTokenPairs = useAtomValue(hideOtherPairsAtom);
  const tokenPair = useAtomValue(agTokenPairAtom);

  const params = useMemo(() => {
    const result: LoDcaQueryParams = {
      owner: accountAddress,
      statuses: ["ACTIVE", "EXPIRED"],
      offset: 0,
      limit: 50,
    };
    if (hideTokenPairs) {
      result.tokenPair = tokenPair;
    }
    return result;
  }, [accountAddress, hideTokenPairs, tokenPair]);

  const { data, ...rest } = useGetLimitOrders({
    key: "openLimitOrders",
    params,
    config,
    enabled: !!accountAddress,
  });

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      return b.createdTs - a.createdTs;
    });
  }, [data]);

  useEffect(() => {
    if (sortedData) {
      setLoOpenOrders(sortedData);
    }
  }, [sortedData, setLoOpenOrders]);

  useEffect(() => {
    if (!accountAddress) {
      setLoOpenOrders([]);
    }
  }, [accountAddress, setLoOpenOrders]);

  return { data: sortedData, ...rest };
};

export default useGetOpenLimitOrders;
