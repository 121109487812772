import { isMobileAtom } from "@/atoms/layout.atom";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import {
  Dialog,
  DialogCloseEsc,
  DialogContentDefault,
  DialogHeader,
} from "@/components/UI/Dialog";
import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/UI/Sheet";
import { OpenOrderItem } from "./hooks/useOpenOrderItems";
import TokenAvatar from "@/components/Avatar/TokenAvatar";
import TextAmt from "@/components/TextAmt";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedOrder: OpenOrderItem | null;
  onSubmit: () => void;
}

function ClaimOrderModal({
  open,
  onOpenChange,
  selectedOrder,
  onSubmit,
}: Props) {
  const isMobile = useAtomValue(isMobileAtom);

  const title = useMemo(() => {
    return "Claim back";
  }, []);

  const content = (
    <div className="flex flex-col gap-8">
      <p className="text-sm text-white">
        You are claiming back the unfinished amount
      </p>
      <div className="flex items-center gap-2">
        <TokenAvatar token={selectedOrder?.tokenIn} className="w-6 h-6" />
        <TextAmt
          number={selectedOrder?.amountIn}
          className="text-xs"
          decimalClassName="text-gray-100"
          suffix={` ${selectedOrder?.tokenIn.symbol}`}
        />
      </div>
      <div className="flex flex-col gap-1">
        <button
          className="flex items-center justify-center h-[4.25rem] rounded-2xl bg-yellow-90 hover:bg-[#EBAA2A]"
          onClick={onSubmit}
        >
          <span className="text-lg/none text-black-80">Claim now</span>
        </button>
        <button
          className="flex items-center justify-center h-[4.25rem] rounded-2xl bg-[#252734] hover:bg-[#383D4C]"
          onClick={() => onOpenChange(false)}
        >
          <span className="text-lg/none text-white">Go back</span>
        </button>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <Sheet open={open} onOpenChange={onOpenChange}>
        <AnimatePresence>
          {open && (
            <SheetContent
              forceMount
              side="bottom"
              onOpenChange={onOpenChange}
              animation={false}
              className="p-4 flex flex-col gap-4"
            >
              <SheetHeader>
                <SheetTitle className="font-bold text-base/none">
                  {title}
                </SheetTitle>
                <SheetCloseEsc />
              </SheetHeader>
              {content}
            </SheetContent>
          )}
        </AnimatePresence>
      </Sheet>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {open && (
          <DialogContentDefault
            forceMount
            className="flex flex-col gap-6 p-6 bg-black-80 shadow-soft-3 shadow-skin-alt dark:shadow-skin-alt/10 rounded-3xl max-w-[22rem]"
          >
            <DialogHeader className="flex items-center justify-between pb-4 border-b border-black-40">
              <h2 className="font-bold text-lg/none">{title}</h2>
              <DialogCloseEsc />
            </DialogHeader>
            {content}
          </DialogContentDefault>
        )}
      </AnimatePresence>
    </Dialog>
  );
}

export default ClaimOrderModal;
