import Avatar from "@/components/Avatar";
import { SPECIAL_TOKENS_MAP } from "@/constants/tokens";
import { TokenAmount } from "@/types/token";
import { getStaticTokenByType } from "@/utils/token";
import { useMemo } from "react";

type Props = {
  item: TokenAmount;
  onClick: (item: TokenAmount) => void;
};

function SuggestedTokenItem({ item, onClick }: Props) {
  const tokenUrl = useMemo(() => {
    return (
      item.token.iconUrl || getStaticTokenByType(item.token.type)?.iconUrl || ""
    );
  }, [item.token.iconUrl, item.token.type]);

  return (
    <button
      className="flex items-center gap-2 px-2 py-1.5 bg-black-50 hover:bg-[#404862] rounded-xl"
      onClick={() => onClick(item)}
    >
      <Avatar
        src={tokenUrl}
        alt={item.token.symbol}
        w={48}
        h={48}
        className="w-6 aspect-square"
      />
      {!SPECIAL_TOKENS_MAP[item.token.type] && (
        <span className="truncate text-sm/none">{item.token.symbol}</span>
      )}
    </button>
  );
}

export default SuggestedTokenItem;
