import { ICExport, ICStar } from "@/assets/icons";
import Avatar from "@/components/Avatar";
import ExplorerTokenLink from "@/components/ExplorerLink/ExplorerTokenLink";
import TextAmt from "@/components/TextAmt";
import { TokenAmount } from "@/types/token";
import { formatBalance } from "@/utils/number";
import { getStaticTokenByType } from "@/utils/token";
import { formatAddress } from "@mysten/sui/utils";
import ImgVerified from "@/assets/images/verified.png";
import { useMemo } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { pinTokenListAtom, pinTokenSetAtom } from "@/atoms/token.atom";
import tw from "@/utils/twmerge";

type Props = {
  item: TokenAmount;
  onClick: (item: TokenAmount) => void;
};

function TokenItem({ item, onClick }: Props) {
  const setPinTokenList = useSetAtom(pinTokenListAtom);
  const pinTokenSet = useAtomValue(pinTokenSetAtom);

  const isPinned = useMemo(() => {
    return pinTokenSet.has(item.token.type);
  }, [item.token.type, pinTokenSet]);

  const tokenUrl = useMemo(() => {
    return (
      item.token.iconUrl || getStaticTokenByType(item.token.type)?.iconUrl || ""
    );
  }, [item.token.iconUrl, item.token.type]);

  const handlePinToken = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isPinned) {
      setPinTokenList((prev) =>
        prev.filter((token) => token.type !== item.token.type),
      );
    } else {
      setPinTokenList((prev) => [item.token, ...prev]);
    }
  };

  return (
    <button
      className="h-12 w-full flex items-center justify-between gap-2 p-2 mb-1 bg-black-50 hover:bg-[#404862] rounded-lg"
      onClick={() => onClick(item)}
    >
      <div className="flex items-center gap-2">
        <a
          className="flex items-center justify-center rounded-lg w-8 aspect-square hover:bg-black-60"
          onClick={handlePinToken}
        >
          <ICStar
            className={tw(
              "transition-all w-5 h-auto",
              isPinned ? "text-green-80 fill-green-80" : "text-white",
            )}
          />
        </a>
        <div className="flex items-center gap-2">
          <Avatar
            src={tokenUrl}
            alt={item.token.symbol}
            w={48}
            h={48}
            className="w-6 aspect-square"
          />
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-1">
              <span className="truncate text-sm/none">{item.token.symbol}</span>
              {item.token.verified && (
                <img
                  src={ImgVerified}
                  alt="verified"
                  className="w-2.5 aspect-square rounded-[0.625rem]"
                />
              )}
            </div>
            <span className="truncate text-2xs/none font-normal text-gray-100">
              {item.token.name}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end gap-1 items-end">
        <TextAmt
          number={formatBalance(item.amount, item.token.decimals ?? 0)}
        />
        <ExplorerTokenLink
          tokenId={item.token.type}
          className="flex items-center gap-1 truncate text-2xs/none font-normal text-gray-100 hover:text-green-80"
          onClick={(e) => e.stopPropagation()}
        >
          <span>{formatAddress(item.token.type)}</span>
          <ICExport className="w-2.5 aspect-square" />
        </ExplorerTokenLink>
      </div>
    </button>
  );
}

export default TokenItem;
