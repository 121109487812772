import { LoDcaQueryConfig } from "./types";
import { useAtomValue } from "jotai";
import { currentAccountAddressAtom } from "@/atoms/account.atom";
import useGetOrderExecutions from "./useGetOrderExecutions";

const useGetOrderExecutionsByOrderId = (
  { orderId, expanded }: { orderId: string; expanded: boolean },
  config?: LoDcaQueryConfig,
) => {
  const accountAddress = useAtomValue(currentAccountAddressAtom);

  return useGetOrderExecutions({
    key: "orderExecutions",
    params: {
      owner: accountAddress,
      orderId,
      statuses: ["SUCCESS"],
      offset: 0,
      limit: 50,
    },
    enabled: !!orderId && !!accountAddress && expanded,
    config,
  });
};

export default useGetOrderExecutionsByOrderId;
