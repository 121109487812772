import { BIG_ZERO } from "@/constants/amount";
import { IncentivePoint } from "@/types/drop";
import { shortenAddress } from "@bicarus/utils";
import { CoinBalance } from "@mysten/sui/client";
import { atom } from "jotai";

export const accountBalanceListAtom = atom<CoinBalance[]>([]);

export const accountBalanceMapAtom = atom((get) => {
  const balances = get(accountBalanceListAtom);
  return balances.reduce(
    (acc, balance) => {
      acc[balance.coinType] = balance.totalBalance;
      return acc;
    },
    {} as Record<string, string>,
  );
});

export const accountIncentiveAtom = atom<IncentivePoint>({
  beta_point: BIG_ZERO,
  official_point: BIG_ZERO,
  total_point: BIG_ZERO,
});

export const accountPriceMapAtom = atom<Record<string, number>>({});

export const currentAccountAddressAtom = atom<string>("");

export const currentSuiNSNameAtom = atom<string>("");

export const currentFullWalletDisplayNameAtom = atom((get) => {
  const address = get(currentAccountAddressAtom);
  const suiNSName = get(currentSuiNSNameAtom);
  return suiNSName || shortenAddress(address);
});

export const currentShortenWalletDisplayNameAtom = atom((get) => {
  const address = get(currentAccountAddressAtom);
  const suiNSName = get(currentSuiNSNameAtom);
  return shortenAddress(suiNSName || address);
});
