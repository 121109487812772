import { useMemo, useState } from "react";
import { PriceMode } from "@/pages/trade/common/PriceModeBtn";
import { DcaOrderItem } from "./useDcaOrderItems";
import { convertTimestampToStr } from "@/utils/limitOrder";

interface Params {
  data: DcaOrderItem;
}

const useDcaOrderItem = ({ data }: Params) => {
  const [minPriceMode, setMinPriceMode] = useState<PriceMode>("out-in");
  const minPrice = useMemo(() => {
    return minPriceMode === "in-out" ? data.minPriceInverted : data.minPrice;
  }, [data.minPrice, data.minPriceInverted, minPriceMode]);

  const [maxPriceMode, setMaxPriceMode] = useState<PriceMode>("out-in");
  const maxPrice = useMemo(() => {
    return maxPriceMode === "in-out" ? data.maxPriceInverted : data.maxPrice;
  }, [data.maxPrice, data.maxPriceInverted, maxPriceMode]);

  const [priceRangeMode, setPriceRangeMode] = useState<PriceMode>("out-in");
  const priceRange = useMemo(() => {
    return priceRangeMode === "in-out"
      ? [data.minPriceInverted, data.maxPriceInverted]
      : [data.minPrice, data.maxPrice];
  }, [
    data.minPrice,
    data.minPriceInverted,
    data.maxPrice,
    data.maxPriceInverted,
    priceRangeMode,
  ]);

  const [intervalValue, intervalUnit] = useMemo(() => {
    return convertTimestampToStr(data.interval);
  }, [data.interval]);

  return {
    ...data,
    minPrice,
    minPriceMode,
    setMinPriceMode,
    maxPrice,
    maxPriceMode,
    setMaxPriceMode,
    priceRange,
    priceRangeMode,
    setPriceRangeMode,
    intervalValue,
    intervalUnit,
  };
};

export default useDcaOrderItem;
