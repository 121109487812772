import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { TokenAmount } from "@/types/token";
import { POPULAR_TOKENS } from "@/constants/tokens";
import { useAtomValue } from "jotai";
import { accountPriceMapAtom } from "@/atoms/account.atom";
import { pinTokenSetAtom } from "@/atoms/token.atom";

function useSortTokenBalances(
  balances: TokenAmount[],
  trendingTokens: string[],
): TokenAmount[] {
  const accountPriceMap = useAtomValue(accountPriceMapAtom);
  const pinTokenSet = useAtomValue(pinTokenSetAtom);

  const sortedBalances = useMemo(() => {
    return balances.slice().sort((a, b) => {
      // Sort by pinned tokens
      const aIsPinned = pinTokenSet.has(a.token.type);
      const bIsPinned = pinTokenSet.has(b.token.type);
      if (aIsPinned && !bIsPinned) {
        return -1;
      }
      if (!aIsPinned && bIsPinned) {
        return 1;
      }

      // Sort by usd balance
      const aUsd = new BigNumber(a.amount).multipliedBy(
        accountPriceMap[a.token.type] ?? 0,
      );
      const bUsd = new BigNumber(b.amount).multipliedBy(
        accountPriceMap[b.token.type] ?? 0,
      );
      if (aUsd.gt(0) && bUsd.gt(0)) {
        return bUsd.comparedTo(aUsd);
      }
      if (aUsd.gt(0)) {
        return -1;
      }
      if (bUsd.gt(0)) {
        return 1;
      }

      // Sort by verified tokens
      if (a.token.verified && !b.token.verified) {
        return -1;
      }
      if (!a.token.verified && b.token.verified) {
        return 1;
      }

      // Sort by popular tokens
      const aIndex2 = POPULAR_TOKENS.indexOf(a.token.type);
      const bIndex2 = POPULAR_TOKENS.indexOf(b.token.type);
      if (aIndex2 !== -1 && bIndex2 !== -1) {
        return aIndex2 - bIndex2;
      }
      if (aIndex2 !== -1) {
        return -1;
      }
      if (bIndex2 !== -1) {
        return 1;
      }

      // Sort by trending tokens
      const aIndex = trendingTokens.indexOf(a.token.type);
      const bIndex = trendingTokens.indexOf(b.token.type);
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      if (aIndex !== -1) {
        return -1;
      }
      if (bIndex !== -1) {
        return 1;
      }

      // Sort by symbol
      return a.token.symbol?.localeCompare(b.token.symbol ?? "");
    });
  }, [balances, trendingTokens, accountPriceMap, pinTokenSet]);

  return sortedBalances;
}

export default useSortTokenBalances;
