import { memo, useState } from "react";
import { Skeleton } from "@/components/UI/Skeleton";
import LimitHistoryContent from "./LimitHistoryContent";
import LimitHistorySheet from "./LimitHistorySheet";
import { OpenOrderItem } from "../hooks/useOpenOrderItems";

type Props = {
  data: OpenOrderItem;
  handleOpenCancelModal: (item: OpenOrderItem) => void;
  handleOpenClaimModal: (item: OpenOrderItem) => void;
};

function LimitHistoryItem(props: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <LimitHistoryContent
        {...props}
        onClick={() => setOpen(true)}
        className="p-4"
      />
      <LimitHistorySheet open={open} setOpen={setOpen} {...props} />
    </>
  );
}

export const LimitHistoryItemSkeleton = memo(
  function LimitHistoryItemSkeleton() {
    return (
      <div className="flex flex-1 flex-col rounded-2xl bg-black-60 gap-4 p-4">
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center gap-1">
            <Skeleton className="w-4 h-4" />
            <Skeleton className="w-12 h-4" />
          </div>
          <Skeleton className="w-12 h-4" />
        </div>

        <div className="h-px bg-black-40" />

        <div className="grid grid-cols-5 gap-2">
          <div className="col-span-2 flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Price
            </span>
            <span className="text-xs/none">
              <Skeleton className="w-12 h-5" />
            </span>
          </div>

          <div className="col-span-2 flex flex-col items-start gap-4">
            <span className="font-normal text-2xs/none text-gray-100">
              Total value
            </span>
            <span className="text-xs/none">
              <Skeleton className="w-12 h-5" />
            </span>
          </div>

          <div>
            <Skeleton className="w-10 h-10" />
          </div>
        </div>
      </div>
    );
  },
);

export default memo(LimitHistoryItem);
