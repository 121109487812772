import { agTokenPairAtom } from "@/atoms/aggregator.atom";
import { hideOtherPairsAtom } from "@/atoms/swapHistory.atom";
import { fullTokenMapAtom } from "@/atoms/token.atom";
import { BIG_ZERO } from "@/constants/amount";
import { LoDcaOrderExecution, LimitOrder } from "@/types/limitDca";
import { TokenDetails } from "@/types/token";
import { formatBalance } from "@/utils/number";
import { getStaticTokenByType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { useAtomValue } from "jotai";
import isEmpty from "lodash/isEmpty";
import useGetLimitOrders from "../limitDca/useGetLimitOrders";
import { useCallback, useMemo } from "react";
import { LoDcaQueryParams } from "../limitDca/types";
import { currentAccountAddressAtom } from "@/atoms/account.atom";
import useGetOrderExecutions from "../limitDca/useGetOrderExecutions";

interface TradingHistoryRawItem {
  digest: string;
  timestamp: string;
  coin_in: string;
  coin_out: string;
  amount_in: string;
  amount_out: string;
  volume: string | null;
}

export interface TradingHistoryRawResponse {
  count: number;
  history: TradingHistoryRawItem[];
}

export interface TradingHistoryItem {
  digest: string;
  timestamp: number;
  tokenIn?: TokenDetails;
  tokenOut?: TokenDetails;
  amountIn: BigNumber;
  amountOut: BigNumber;
  volume: BigNumber;
  price: BigNumber;
  priceInverted: BigNumber;
}

export interface TradingHistoryResponse {
  count: number;
  history: TradingHistoryItem[];
}

export function mapLimitOrderToRawItem(
  order: LimitOrder,
): TradingHistoryRawItem {
  return {
    digest: order.digest || order.orderId,
    timestamp: order.lastExecutedTs
      ? (order.lastExecutedTs || 0).toString()
      : new Date(order.updatedAt).getTime().toString(),
    coin_in: order.payCoinName,
    coin_out: order.targetCoinName,
    amount_in: order.paidAmount,
    amount_out: order.obtainedAmount,
    volume: (order.volume ?? 0).toString(),
  };
}

export function mapDcaOrderToRawItem(
  order: LoDcaOrderExecution,
): TradingHistoryRawItem {
  return {
    digest: order.digest,
    timestamp: (order.executedTs || 0).toString(),
    coin_in: order.payCoinName,
    coin_out: order.targetCoinName,
    amount_in: order.payAmount,
    amount_out: order.obtainedAmount,
    volume: (order.volume ?? 0).toString(),
  };
}

export function mapTradingHistoryItem(
  rawItem: TradingHistoryRawItem,
): TradingHistoryItem {
  const digest = rawItem.digest;
  const timestamp = Number(rawItem.timestamp);
  const tokenIn = getStaticTokenByType(rawItem.coin_in);
  const tokenOut = getStaticTokenByType(rawItem.coin_out);
  const amountIn = formatBalance(rawItem.amount_in, tokenIn?.decimals ?? 0);
  const amountOut = formatBalance(rawItem.amount_out, tokenOut?.decimals ?? 0);
  const volume = new BigNumber(rawItem.volume ?? 0);
  const price = amountIn.dividedBy(amountOut);
  const priceInverted = amountOut.dividedBy(amountIn);
  return {
    digest,
    timestamp,
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    volume,
    price: price.isNaN() ? BIG_ZERO : price,
    priceInverted: priceInverted.isNaN() ? BIG_ZERO : priceInverted,
  };
}

const useTradingHistory = () => {
  const accountAddress = useAtomValue(currentAccountAddressAtom);
  const fullTokenMap = useAtomValue(fullTokenMapAtom);
  const hideTokenPairs = useAtomValue(hideOtherPairsAtom);
  const tokenPair = useAtomValue(agTokenPairAtom);

  // swap
  const {
    data: dataSwap,
    isLoading: isLoadingSwap,
    isPending: isPendingSwap,
    isRefetching: isRefetchingSwap,
    refetch: refetchSwap,
  } = useQuery<TradingHistoryResponse>({
    queryKey: [
      "getTradingHistory",
      accountAddress,
      fullTokenMap,
      hideTokenPairs,
      tokenPair,
    ],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_STATISTIC_API}/trading-history?addr=${accountAddress}&offset=0&limit=50${hideTokenPairs ? `&token_pair=${tokenPair}` : ""}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch transactions");
      }
      const res: TradingHistoryRawResponse = await response.json();
      return {
        ...res,
        history: res.history.map(mapTradingHistoryItem),
      };
    },
    enabled: !!accountAddress && !isEmpty(fullTokenMap),
  });

  // limit
  const limitParams = useMemo(() => {
    const result: LoDcaQueryParams = {
      owner: accountAddress,
      statuses: ["CLOSED"],
      offset: 0,
      limit: 50,
    };
    if (hideTokenPairs) {
      result.tokenPair = tokenPair;
    }
    return result;
  }, [accountAddress, hideTokenPairs, tokenPair]);
  const {
    data: dataLimitRaw,
    isLoading: isLoadingLimit,
    isPending: isPendingLimit,
    isRefetching: isRefetchingLimit,
    refetch: refetchLimit,
  } = useGetLimitOrders({
    key: "getLimitOrders",
    params: limitParams,
    enabled: !!accountAddress && !isEmpty(fullTokenMap),
  });

  const dataLimit = useMemo(() => {
    if (!dataLimitRaw) {
      return [];
    }
    return dataLimitRaw.map(mapLimitOrderToRawItem).map(mapTradingHistoryItem);
  }, [dataLimitRaw]);

  // dca
  const dcaParams = useMemo(() => {
    const result: LoDcaQueryParams = {
      owner: accountAddress,
      statuses: ["SUCCESS"],
      offset: 0,
      limit: 50,
      orderType: "DCA",
    };
    if (hideTokenPairs) {
      result.tokenPair = tokenPair;
    }
    return result;
  }, [accountAddress, hideTokenPairs, tokenPair]);
  const {
    data: dataDcaRaw,
    isLoading: isLoadingDca,
    isPending: isPendingDca,
    isRefetching: isRefetchingDca,
    refetch: refetchDca,
  } = useGetOrderExecutions({
    key: "getClosedDcaOrders",
    params: dcaParams,
    enabled: !!accountAddress && !isEmpty(fullTokenMap),
  });

  const dataDca = useMemo(() => {
    if (!dataDcaRaw) {
      return [];
    }
    return dataDcaRaw.map(mapDcaOrderToRawItem).map(mapTradingHistoryItem);
  }, [dataDcaRaw]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchSwap(), refetchLimit(), refetchDca()]);
  }, [refetchSwap, refetchLimit, refetchDca]);

  return {
    data: dataSwap?.history.concat(dataLimit).concat(dataDca),
    isLoading: isLoadingSwap || isLoadingLimit || isLoadingDca,
    isPending: isPendingSwap || isPendingLimit || isPendingDca,
    isRefetching: isRefetchingSwap || isRefetchingLimit || isRefetchingDca,
    refetch,
  };
};

export default useTradingHistory;
