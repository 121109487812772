export function formatExpiry(expiry: number): string {
  const days = Math.floor(expiry / (24 * 60 * 60 * 1000));
  const hours = Math.floor((expiry % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((expiry % (60 * 60 * 1000)) / (60 * 1000));
  let result = "";
  if (days > 0) {
    result += `${days}d`;
  }
  if (hours > 0) {
    result += ` ${hours}h`;
  }
  if (minutes > 0) {
    result += ` ${minutes}m`;
  }
  return result;
}

export function convertTimestampToStr(timestamp: number): [number, string] {
  const MS_IN_MINUTE = 60000;
  const MS_IN_HOUR = 60 * MS_IN_MINUTE;
  const MS_IN_DAY = 24 * MS_IN_HOUR;
  const MS_IN_WEEK = 7 * MS_IN_DAY;
  const MS_IN_MONTH = 30 * MS_IN_DAY;

  if (timestamp >= MS_IN_MONTH) {
    return [Math.floor(timestamp / MS_IN_MONTH), "Months"];
  } else if (timestamp >= MS_IN_WEEK) {
    return [Math.floor(timestamp / MS_IN_WEEK), "Weeks"];
  } else if (timestamp >= MS_IN_DAY) {
    return [Math.floor(timestamp / MS_IN_DAY), "Days"];
  } else if (timestamp >= MS_IN_HOUR) {
    return [Math.floor(timestamp / MS_IN_HOUR), "Hours"];
  } else if (timestamp >= MS_IN_MINUTE) {
    return [Math.floor(timestamp / MS_IN_MINUTE), "Minutes"];
  } else {
    return [0, "Minutes"];
  }
}
