import ImgLogo from "@/assets/images/logo.png";
import { tvPairTypesAtom, tvSymbolAtom } from "@/atoms/tradingView.atom";
import { useAtomValue } from "jotai";
import { memo, useEffect, useRef, useState } from "react";
import { datafeed } from "./datafeed";
import useTvPairPriceChange from "@/hooks/prices/useTvPairPriceChange";
import { currentAccountAddressAtom } from "@/atoms/account.atom";

const DEFAULT_INTERVAL = "60";

function createWidget() {
  if (document.getElementById("tradingview_9d937") && "TradingView" in window) {
    const Widget = (window.TradingView as any).widget;
    const widget = new Widget({
      library_path: "/trading_view/charting_library/",
      autosize: true,
      symbol: "SUI/USDC",
      interval: DEFAULT_INTERVAL,
      timezone: "Etc/UTC",
      theme: "dark",
      locale: "en",
      toolbar_bg: "transparent",
      loading_screen: { backgroundColor: "transparent" },
      // enable_publishing: false,
      // allow_symbol_change: false,
      disabled_features: [
        "volume_force_overlay",
        "show_logo_on_all_charts",
        "caption_buttons_text_if_possible",
        "create_volume_indicator_by_default",
        "header_compare",
        "compare_symbol",
        "display_market_status",
        "header_interval_dialog_button",
        "show_interval_dialog_on_key_press",
        "header_symbol_search",
        "popup_hints",
        "header_in_fullscreen_mode",
        "use_localstorage_for_settings",
        "right_bar_stays_on_scroll",
        "symbol_info",
      ] as any,
      enabled_features: [
        "side_toolbar_in_fullscreen_mode",
        "header_in_fullscreen_mode",
        "hide_resolution_in_legend",
        "items_favoriting",
        "hide_left_toolbar_by_default",
        "iframe_loading_compatibility_mode",
      ],
      container: "tradingview_9d937",
      custom_css_url: "/trading_view/themed.css",
      overrides: {
        "mainSeriesProperties.showCountdown": false,
        "paneProperties.background": "#1C1E2C",
        "paneProperties.backgroundType": "solid",
      },
      // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
      //   "https://demo-feed-data.tradingview.com",
      // ),
      datafeed,
    });
    return widget;
  }
  return null;
}

function TradingViewWidget() {
  const [loadedChart, setLoadedChart] = useState(false);
  const [loadedBundle, setLoadedBundle] = useState(false);
  const [ready, setReady] = useState(false);
  const widgetRef = useRef(null);

  const tvSymbol = useAtomValue(tvSymbolAtom);
  const pairTypes = useAtomValue(tvPairTypesAtom);
  const { refetch: refetchPairPrice } = useTvPairPriceChange(pairTypes);
  const accountAddress = useAtomValue(currentAccountAddressAtom);

  useEffect(() => {
    const currentWidget = widgetRef.current as any;

    try {
      if (!accountAddress) {
        currentWidget?.activeChart?.()?.clearMarks();
      } else {
        currentWidget?.activeChart?.()?.refreshMarks();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [accountAddress]);

  useEffect(() => {
    if (
      ((loadedChart && loadedBundle) ||
        (window as any).scriptLoadMap?.tradingview) &&
      !widgetRef.current
    ) {
      const scriptLoadMap = (window as any).scriptLoadMap || {};
      (window as any).scriptLoadMap = { ...scriptLoadMap, tradingview: true };
      widgetRef.current = createWidget();
      (widgetRef.current as any)?.onChartReady(() => {
        setTimeout(() => {
          setReady(true);
        }, 0);
      });
    }
  }, [loadedBundle, loadedChart]);

  useEffect(() => {
    if (!ready) return;

    const currentWidget = widgetRef.current as any;
    currentWidget?.onChartReady(() => {
      const callback = () => {};
      setTimeout(() => {
        const resolution =
          currentWidget?.activeChart()?.resolution() || DEFAULT_INTERVAL;
        currentWidget?.setSymbol(tvSymbol, resolution, callback);
      }, 0);
    });
  }, [ready, tvSymbol]);

  useEffect(() => {
    if (!ready) return;

    const currentWidget = widgetRef.current as any;
    currentWidget
      ?.activeChart()
      ?.onIntervalChanged()
      ?.subscribe(null, () => {
        refetchPairPrice();
      });
  }, [ready, refetchPairPrice]);

  useEffect(() => {
    const loadScript = (
      src: string,
      onLoad: ((this: GlobalEventHandlers, ev: Event) => any) | null,
    ) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = onLoad;
      document.body.appendChild(script);
    };

    loadScript(
      "/trading_view/charting_library/charting_library.standalone.js",
      () => setLoadedChart(true),
    );
    loadScript("/trading_view/datafeeds/udf/dist/bundle.js", () =>
      setLoadedBundle(true),
    );
  }, []);

  return (
    <div className="flex flex-col flex-1 w-full h-full relative">
      <div
        id="tradingview_9d937"
        className="flex flex-col flex-1 w-full h-full"
        hidden={!ready}
      />
      {!ready && (
        <div className="absolute inset-0 flex items-center justify-center bg-black-80">
          <div className="relative w-16 h-16 flex items-center justify-center">
            <img src={ImgLogo} alt="Logo" className="w-8 h-auto" />
            <div className="absolute inset-0 border-[4px] border-t-transparent border-iris-100 animate-spin rounded-full" />
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(TradingViewWidget);
