import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const useCountdown = (timestamp: number) => {
  const [remainArr, setRemainArr] = useState<number[][]>(
    new Array(4).fill([0, 0]),
  );

  useEffect(() => {
    const func = () => {
      const duration = dayjs.duration(dayjs.unix(timestamp).diff(dayjs()));
      if (duration.milliseconds() > 0) {
        const d = Math.floor(duration.asDays());
        const h = duration.hours();
        const m = duration.minutes();
        const s = duration.seconds();
        setRemainArr(
          [d, h, m, s].map((val) => [Math.floor(val / 10), val % 10]),
        );
      } else {
        setRemainArr(new Array(4).fill([0, 0]));
      }
    };
    func();
    const interval = setInterval(() => {
      func();
    }, 1000);
    return () => clearInterval(interval);
  }, [timestamp]);

  return remainArr;
};

function Countdown({ timestamp }: { timestamp: number }) {
  const remainArr = useCountdown(timestamp);

  return (
    <>
      {remainArr.reduce((acc, cur) => {
        return acc === ""
          ? `${cur[0]}${cur[1]}`
          : `${acc} : ${cur[0]}${cur[1]}`;
      }, "")}
    </>
  );
}

export default Countdown;
