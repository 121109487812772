import useTradingHistory from "@/hooks/accounts/useTradingHistory";
import RefreshHistoryButton from "../../common/RefreshHistoryButton";
import { useSetAtom } from "jotai";
import { agIsRefetchingHistoryAtom } from "@/atoms/aggregator.atom";

function RefreshSwapHistoryButton() {
  const { refetch, isPending } = useTradingHistory();
  const setIsRefetching = useSetAtom(agIsRefetchingHistoryAtom);

  const handleRefetch = async () => {
    try {
      setIsRefetching(true);
      await refetch();
      setIsRefetching(false);
    } catch (e) {
      setIsRefetching(false);
    }
  };

  return <RefreshHistoryButton onClick={handleRefetch} disabled={isPending} />;
}

export default RefreshSwapHistoryButton;
