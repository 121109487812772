import { agInfoTabAtom, priceChartLoadedAtom } from "@/atoms/aggregator.atom";
import { useAtom, useSetAtom } from "jotai";
import { INFO_TABS } from "./constants";
import tw from "@/utils/twmerge";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";
import { useEffect, useMemo } from "react";
import ReactGa from "@/utils/ga";
import useTradeType from "../../../hooks/useTradeType";

function MInfoTabs() {
  const [selectedTab, setSelectedTab] = useAtom(agInfoTabAtom);
  const setPriceChartLoaded = useSetAtom(priceChartLoadedAtom);

  const { isSwap } = useTradeType();

  const infoTabs = useMemo(() => {
    return isSwap
      ? INFO_TABS
      : INFO_TABS.filter((tab) => tab.key === "price-chart");
  }, [isSwap]);

  useEffect(() => {
    if (isSwap) {
      setSelectedTab("routes");
    } else {
      setSelectedTab("price-chart");
      setPriceChartLoaded(true);
    }
  }, [isSwap, setSelectedTab, setPriceChartLoaded]);

  return (
    <div
      className={tw(
        "grid bg-skin-alt p-0.5 rounded-xl",
        isSwap ? "grid-cols-2 w-full" : "grid-cols-1 w-1/2",
      )}
    >
      {infoTabs.map(({ key, shortLabel, tooltip }) => (
        <button
          key={key}
          className={tw(
            "flex items-center justify-center gap-1 rounded-xl p-3",
            selectedTab === key
              ? "bg-black-60 text-white"
              : "bg-transparent text-gray-100",
          )}
          onClick={() => {
            setSelectedTab(key);
            if (key === "price-chart") {
              setPriceChartLoaded(true);
              ReactGa.event("click_price_chart_tab");
            }
          }}
        >
          <span className="text-xs/none">{shortLabel}</span>
          {tooltip && (
            <div onClick={(e) => e.stopPropagation()}>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ICInfoCircle className="w-4 aspect-square" />
                  </TooltipTrigger>
                  <TooltipContentGlow className="w-[16rem]">
                    <span>{tooltip}</span>
                  </TooltipContentGlow>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </button>
      ))}
    </div>
  );
}

export default MInfoTabs;
