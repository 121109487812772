import {
  Sheet,
  SheetCloseEsc,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/UI/Sheet";
import { AnimatePresence } from "framer-motion";
import { ReactNode, useState } from "react";
import ImgX from "@/assets/images/socials/x.png";
import ImgDiscord from "@/assets/images/socials/discord.png";
import ImgDocs from "@/assets/images/socials/docs.png";
import { ICExport, ICFlag, ICRanking } from "@/assets/icons";
import { Link } from "react-router-dom";

interface Props {
  trigger: ReactNode;
}

function MenuSheet({ trigger }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <AnimatePresence>
        {open && (
          <SheetContent
            forceMount
            side="bottom"
            onOpenChange={setOpen}
            className="bg-black-60 p-6 gap-6"
          >
            <div className="flex flex-col gap-6">
              <SheetHeader className="flex items-center justify-between gap-2">
                <SheetTitle className="font-semibold text-base/none">
                  Menu
                </SheetTitle>
                <SheetCloseEsc />
              </SheetHeader>

              <div className="flex flex-col gap-1 p-1 bg-black-80 rounded-[1.25rem]">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/7k_ag_"
                  title="X"
                  className="flex items-center justify-between gap-2 p-4 rounded-2xl bg-black-40"
                >
                  <span className="flex items-center gap-2">
                    <img src={ImgX} alt="X" className="w-4 aspect-square" />
                    <span className="text-base/none">(Twitter) X</span>
                  </span>
                  <ICExport className="w-4 h-auto" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.gg/vNhgKEawXn"
                  title="Discord"
                  className="flex items-center justify-between gap-2 p-4 rounded-2xl bg-black-40"
                >
                  <span className="flex items-center gap-2">
                    <img
                      src={ImgDiscord}
                      alt="Discord"
                      className="w-4 aspect-square"
                    />
                    <span className="text-base/none">Discord</span>
                  </span>
                  <ICExport className="w-4 h-auto" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.7k.ag"
                  title="Docs"
                  className="flex items-center justify-between gap-2 p-4 rounded-2xl bg-black-40"
                >
                  <span className="flex items-center gap-2">
                    <img
                      src={ImgDocs}
                      alt="Docs"
                      className="w-4 aspect-square"
                    />
                    <span className="text-base/none">Documentation</span>
                  </span>
                  <ICExport className="w-4 h-auto" />
                </a>
              </div>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://7k.ag/support"
                title="Feedback"
                className="flex items-center justify-between gap-2 p-4 rounded-2xl bg-black-40"
              >
                <span className="flex items-center gap-2">
                  <ICFlag className="w-4 h-auto" />
                  <span className="text-base/none">Feedback</span>
                </span>
                <ICExport className="w-4 h-auto" />
              </a>

              <Link
                to="/leaderboard"
                className="flex items-center justify-between gap-2 p-4 rounded-xl bg-green-inverted-80 hover:bg-[#BAFFCA] text-black-80"
                onClick={() => setOpen(false)}
              >
                <span className="flex items-center gap-2">
                  <ICRanking className="w-4 h-auto" />
                  <span className="text-base/none">Leaderboard</span>
                </span>
              </Link>
            </div>
          </SheetContent>
        )}
      </AnimatePresence>
    </Sheet>
  );
}

export default MenuSheet;
